import React, { FC } from 'react'
import { Extension as FHIRExtension } from '../../../../types/FHIRTypes/Extension'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'

interface ExtensionProps {
  nestedExtension: CodeableConcept | CodeableConcept[] | null | undefined
}

const Extension: FC<ExtensionProps> = ({ nestedExtension }) => {
  if (!nestedExtension) {
    return null
  }

  const extractDisplay = (value: any, label = ''): string => {
    if (Array.isArray(value)) {
      return value.map(item => extractDisplay(item, label)).join(', ')
    } else if (typeof value === 'object' && value !== null) {
      return Object.entries(value)
        .map(([key, val]) => `${capitalizeFirstLetter(key)}: ${extractDisplay(val, key)}`)
        .join(', ')
    } else {
      return capitalizeFirstLetter(value.toString())
    }
  }

  const displayValue = (extension: FHIRExtension): string => {
    for (const key in extension) {
      if (extension.hasOwnProperty(key) && key.startsWith('value')) {
        const value = extension[key as keyof FHIRExtension]
        if (value !== undefined) {
          return typeof value === 'object' || Array.isArray(value)
            ? extractDisplay(value, key)
            : capitalizeFirstLetter(value.toString())
        }
      }
    }
    return ''
  }

  const displayText = Array.isArray(nestedExtension)
    ? nestedExtension.map(ext => ext.extension ? ext.extension.map(displayValue).join(', ') : '').join(', ')
    : nestedExtension.extension ? nestedExtension.extension.map(displayValue).join(', ') : ''

    return <span>{displayText ? `Data absent reason: ${displayText}` : ''}</span>
}

export default Extension