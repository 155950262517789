import React, { FC } from 'react'
import { ContactPoint as FhirContactPoint } from '../../../../types/FHIRTypes/ContactPoint'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import Extension from '../../DataTypes/Extension'

interface ContactPointProps {
    data?: FhirContactPoint | FhirContactPoint[];
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const ContactPoint: FC<ContactPointProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }
    
    const getTelecom = (contactPoint: FhirContactPoint) => {
        const useType = contactPoint.use ? `${capitalizeFirstLetter(contactPoint.use)}: ` : ''
        const value = contactPoint.value ? contactPoint.value : ''
        const returnValue = `${capitalizeFirstLetter(contactPoint.system)}: ${useType} ${value}`
        return returnValue
    }

    const telecoms = Array.isArray(data) ? data : [data]

    return (
        <div>
            {telecoms.map((telecom, index) => (
                <div key={index}>
                    {telecom ? getTelecom(telecom) :  <Extension nestedExtension={telecom} />}
                </div>
            ))}
        </div>
    )
}

export default ContactPoint