import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import { Time as FHIRTime } from '../../../../types/FHIRTypes/PrimitiveFhirTypes'

interface TimeProps {
    data?: FHIRTime
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const TimeDisplay: FC<TimeProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
      return null
    }
  
    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }
    
    if (data) {
        return <div>{data}</div>
    }

    return null
}
export default TimeDisplay