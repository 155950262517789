import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { CodeableConcept as CodeableConceptFhirType } from '../../../../types/FHIRTypes/CodeableConcept'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import { Extension } from '../../../../types/FHIRTypes/Extension'

interface DeliveryExtensionProps {
    deliveryMethod?: Extension[];
}

const DeliveryMethod: React.FC<DeliveryExtensionProps> = ({ deliveryMethod }) => {
    if (!deliveryMethod) return <div>No information available</div>


    const deliveryConcept = deliveryMethod.find((innerExt: Extension) => innerExt.url === 'type')
        ?.valueCodeableConcept || null
    return (
        <>
            {deliveryConcept && (
                <CodeableConcept data={deliveryConcept} />
            )}
        </>
    )
}

export default DeliveryMethod