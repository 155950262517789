import React from 'react'
import { Button, Form, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import useEnvironment from '../../../../../hooks/location/useEnviroment'
import { Organization } from '../../../../../types/FHIRTypes/Organization'
import StringDisplay from '../../../DataTypes/String'
import { ProviderResponse } from '../../../../../types/FHIRTypes/ProviderResponse'

interface OrganizationListProps {
    organizations: ProviderResponse<Organization>
    clickViewDataLink: (organization: Organization) => void
    isFetching: boolean
    isLoading: boolean
    isSuccess: boolean,
    searchProviderResource: { searchTerms: string, resource: string }
    clearForm: () => void
    newSearch: () => void
    updateSearchTerms: (e: React.ChangeEvent<HTMLInputElement>) => void
    totalPages: number;
    currentPage: number;
    handleChangePage: (page: number | string) => void;
}

const OrganizationList: React.FC<OrganizationListProps> = ({ organizations, clickViewDataLink, isFetching, isLoading, isSuccess, searchProviderResource, clearForm, newSearch, updateSearchTerms, totalPages, currentPage, handleChangePage }) => {

    const handleNewSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        newSearch()
    }

    const { data: environment } = useEnvironment()


    return (
        <>
            <h3>Organizations</h3>
            <Form noValidate className='form-inline' onSubmit={handleNewSearch}>
                <Form.Group>
                    <Form.Label style={{ margin: '0 1em 0 0' }}>Search by Organization Name</Form.Label>
                    <Form.Control
                        style={{ margin: '0 1em 0 0' }}
                        type='text'
                        name='keyword'
                        placeholder="Enter Search Terms"
                        value={searchProviderResource.searchTerms}
                        onChange={updateSearchTerms}
                    />
                </Form.Group>
                <Button className='searchBtn mr-2' type='submit'>
                    Search
                </Button>
                <Button className='outline-searchBtn' onClick={clearForm}>
                    Clear
                </Button>
            </Form>
            <hr />
            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                {
                    organizations?.data.length > 0 && (
                        <div className='mb-3'>
                            <PaginationButtons
                                totalPages={totalPages}
                                currentPage={currentPage}
                                handleChangePage={handleChangePage}
                            />
                        </div>
                    )
                }
                {organizations && organizations.total > 0 && environment?.includeTotalInFHIRRequest && (
                    <span className='d-md-block'>
                        {(isLoading || isFetching)
                            ? <Spinner animation="border" size="sm" />
                            : `${organizations.total.toLocaleString()} record(s) returned`
                        }
                    </span>
                )}
            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='name-column'>Name</th>
                        <th className='detail-column'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading || isFetching ? (
                            <tr>
                                <td colSpan={2} className="text-center">Loading...</td>
                            </tr>
                        ) : !organizations?.data?.length ? (
                            <tr>
                                <td colSpan={2} className="text-center">No Organizations Found.</td>
                            </tr>
                        )
                            :
                            organizations && isSuccess ? (
                                organizations.data.map((organization: Organization) => (
                                    <tr key={organization.id}>
                                        <td><Link to='#' onClick={() => clickViewDataLink(organization)}><StringDisplay data={organization.name} dataExtension={organization._name} /></Link></td>
                                        <td>
                                            <Link
                                                to='#'
                                                onClick={() => clickViewDataLink(organization)}
                                            >
                                                Detail
                                            </Link>
                                        </td>
                                    </tr>
                                ))) : null
                    }
                </tbody>
                <tfoot>
                    {
                        organizations && organizations?.data.length > 0 && (
                            <tr className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                                <td scope="row" colSpan={2}>
                                    <PaginationButtons
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        handleChangePage={handleChangePage}
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tfoot>
            </Table>
        </>
    )
}

export default OrganizationList