import React, { FC } from 'react'
import { HumanName as FHIRName } from '../../../../types/FHIRTypes/HumanName'
import { getHumanName } from '../../../../utils/fhirUtils/getHumanName'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import Extension from '../../DataTypes/Extension'

interface HumanNameProps {
    data?: FHIRName | FHIRName[]
    dataExtension?: CodeableConcept | CodeableConcept[] | null
    showUse?: boolean
}

const HumanName: FC<HumanNameProps> = ({ data, dataExtension, showUse }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    const names = Array.isArray(data) ? data : [data]

    return (
        <div>
            {names.map((name, index) => {
                const humanName = getHumanName(name, showUse)
                return (
                    <div key={index}>
                        {humanName !== '' ? humanName : <Extension nestedExtension={name} />}
                    </div>
                )
            })}
        </div>
    )
}

export default HumanName