import { capitalizeFirstLetter, handleReference } from '../helpers'
import { CareTeamBundle } from '../../types/FHIRTypes/Bundle'
import { Period } from '../../types/FHIRTypes/Period'

export const formatCareTeamDataForTable = (careTeams: CareTeamBundle): { careTeamId: string; period: Period | undefined; status: string | undefined; }[] => {
  return careTeams?.entry?.filter(({ resource }) => resource.resourceType === 'CareTeam')
    .map(({ resource }) => ({
      careTeamId: resource.id || '',
      period: resource.period,
      status: resource.status,
    })) || []
}