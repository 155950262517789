import React, { FC } from 'react'
import { Dosage as FHIRDosage } from '../../../../types/FHIRTypes/MedicationRequest'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import Extension from '../../DataTypes/Extension'

interface DosageProps {
    data?: FHIRDosage | FHIRDosage[]
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Dosage: FC<DosageProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    const dosages = Array.isArray(data) ? data : [data]

    return (
        <div>
            {dosages.map((dosage, index) => (
                <div key={index}>
                    {dosage?.text ? dosage.text : <Extension nestedExtension={dosage} />}
                </div>
            ))}
        </div>
    )
}

export default Dosage