import React, { FC, useState } from 'react'
import { Accordion, Button, Col, Container, Row, Form } from 'react-bootstrap'

const DataConsentLive: FC = () => {
    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked(!checked)
    }

    return (
        <div>
            <h6 style={{ fontWeight: 'bolder', marginBottom: '14px' }}>CONSENT FOR DATA ACCESS</h6>
            <Row className="align-items-center" style={{ marginBottom: '5px' }}>
                <Col className="d-flex align-items-center">
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '10px' }}>Consent for External Apps I Have Authorized</h6>
                    <Form.Check
                        type="switch"
                        id="external-apps-switch"
                        label=""
                        checked={false}
                        readOnly
                        disabled
                        style={{ pointerEvents: 'none' }}
                    />
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '10px' }}>N/A</h6>
                </Col>
            </Row>
            <p style={{ marginTop: '5px' }}>If you have authorized any of the Available Apps to access your data using your credentials from this site, you should go to that app directly and remove access. You can also change your password here to prevent further connections from that app, but it may not immediately revoke access to connections that currently exist.</p>

            <Row className="align-items-center" style={{ marginBottom: '14px' }}>
                <Col className="d-flex align-items-center">
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '177.8px' }}>Consent for Provider Access</h6>
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', color: 'grey' }}>FUTURE FUNCTIONALITY</h6>
                </Col>
            </Row>

            <Row className="align-items-center" style={{ marginBottom: '14px' }}>
                <Col className="d-flex align-items-center">
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '130px' }}>Consent for Payer to Payer Access</h6>
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', color: 'grey' }}>FUTURE FUNCTIONALITY</h6>
                </Col>
            </Row>

        </div>
    )
}

export default DataConsentLive