import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ProcedureBundle } from '../../../../../types/FHIRTypes/Bundle'
import { ProcedureEntry } from '../../../../../types/FHIRTypes/Entry'
import { Procedure } from '../../../../../types/FHIRTypes/Procedure'
import PaginationButtons from '../../../../PaginationButtons'
import Code from '../../../DataTypes/Code'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import PeriodOrDateTime from '../../../DataTypes/PeriodOrDateTime'

interface ProcedureListProps {
    procedureData: ProcedureBundle;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
}

const ProcedureList: React.FC<ProcedureListProps> = ({ procedureData, isFetching, handleChangePage, totalPages, currentPage }) => {
    const procedures: Procedure[] = procedureData && procedureData.entry ? procedureData.entry.map((procedure: ProcedureEntry) => procedure.resource) : []

    return (
        <>
            {procedures.length > 0 && (
                <div className="mb-2">
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {`${procedureData.total.toLocaleString()} record(s) found`}
                    </div>
                    <PaginationButtons
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                    />
                </div >
            )}

            <Table size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'> Procedure Code</th>
                        <th className='d-none d-lg-table-cell'>Status</th>
                        <th className='d-none d-lg-table-cell'>When Performed</th>
                        <th className='d-none d-lg-table-cell'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='d-flex'>
                                    Loading Member Procedures...
                                </td>
                            </tr>
                        ) : procedures?.length === 0 ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='text-center'>
                                    No Procedures found for this member.
                                </td>
                            </tr>
                        ) : (
                            <>
                                {procedures.map((procedure) => (
                                    <tr key={procedure.id}>
                                        <td className='d-table-cell'><Link to={`/patient/Procedure/${procedure.id}`}> <CodeableConcept data={procedure?.code} dataExtension={procedure._code} /></Link></td>
                                        <td className='d-none d-lg-table-cell'><Code data={procedure.status} dataExtension={procedure._status} /></td>
                                        <td className='d-none d-lg-table-cell'><PeriodOrDateTime data={procedure.performedPeriod || procedure.performedDateTime} dataExtension={procedure._performedPeriod || procedure._performedDateTime} /></td>
                                        <td className='d-none d-lg-table-cell'><Link to={`/patient/Procedure/${procedure.id}`}>Detail</Link></td>
                                    </tr>
                                ))}
                            </>
                        )
                    }
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {procedures.length > 0 &&
                            <tr>
                                <td scope="row" colSpan={5}>
                                    <PaginationButtons
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        handleChangePage={handleChangePage}
                                    />
                                </td>
                            </tr>
                        }
                    </div>
                </tfoot>
            </Table>
        </>
    )
}

export default ProcedureList