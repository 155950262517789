import React, { FC } from 'react'
import { Address as FhirAddress } from '../../../../types/FHIRTypes/Address'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import Extension from '../../DataTypes/Extension'

interface AddressProps {
    data?: FhirAddress | FhirAddress[];
    dataExtension?: CodeableConcept | CodeableConcept[] | null
    showUse?: boolean;
}

const Address: FC<AddressProps> = ({ data, dataExtension, showUse }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    const getAddress = (address: FhirAddress) => {
        const fullAddress = address.text || `${address.line?.join(' ') || ''} ${address.city || ''}, ${address.state || ''} ${address.postalCode || ''}`.trim()
        const useType = (showUse && (address.type || address.use)) || ''
        const formattedType = useType.charAt(0).toUpperCase() + useType.slice(1)
        const returnValue = showUse ? `${formattedType}: ${fullAddress} ` : `${fullAddress}`
        return returnValue
    }

    const addresses = Array.isArray(data) ? data : [data]

    return (
        <div>
            {addresses.map((address, index) => (
                <div key={index}>
                    {address ? getAddress(address) : <Extension nestedExtension={address} />}
                </div>
            ))}
        </div>
    )
}

export default Address