import { useEffect, useState } from 'react'
import useFhirResource from './useFhirResource'
import { getUrlParameter } from '../../utils/helpers'

const usePaginatedFhirResource = (fhirId: any, resourceType: any, pageSize:number) => {
  const [pageState, setPageState] = useState({
    getPages: '',
    getPagesOffset: '',
    pageId: ''
  })

  const { data, refetch, isStale, isFetching } = useFhirResource(
    fhirId,
    resourceType,
    pageSize,
    pageState.getPages,
    pageState.getPagesOffset,
    pageState.pageId
  )

  const totalPages = data?.total ? Math.ceil(data.total / pageSize) : 1
  const currentPage = pageState.getPagesOffset ? Math.floor(Number(pageState.getPagesOffset) / pageSize) + 1 : 1
  const pageLinks = data?.link
    ? data.link
      .filter((l: any) => l.relation.match(/next|previous|self|first|last/))
      .map((l: any) => {
        const offset = Number(new URL(l.url).searchParams.get('_getpagesoffset')) || 0
        return {
          page: Math.floor(offset / pageSize) + 1,
          url: l.url,
        }
      })
      .sort((a: any, b: any) => a.page - b.page) : []

  useEffect(() => {
    if (pageState.getPagesOffset) {
      refetch()
    }
  }, [pageState.getPagesOffset])

  const handleChangePage = (pageOrUrl: number | string) => {
    let newPages = pageState.getPages
    let newOffset = ''
    let newPageId = ''

    if (typeof pageOrUrl === 'string') {
      newPages = getUrlParameter('_getpages', pageOrUrl) || newPages
      newOffset = getUrlParameter('_getpagesoffset', pageOrUrl) || '0'
      newPageId = getUrlParameter('_pageId', pageOrUrl) || ''
    } else {
      const nextLink = data?.link?.find((l: any) => l.relation === 'next')?.url
      if (nextLink) {
        newPages = getUrlParameter('_getpages', nextLink) || newPages
        newOffset = ((pageOrUrl - 1) * pageSize).toString()
      } else {
        newPages = ''
        newOffset = ((pageOrUrl - 1) * pageSize).toString()
      }
      newPageId = ''
    }

    setPageState({
      getPages: newPages,
      getPagesOffset: newOffset,
      pageId: newPageId
    })


  }

  return { data, refetch, isStale, isFetching, handleChangePage, totalPages, currentPage, pageLinks }
}

export default usePaginatedFhirResource