import React, { FC } from 'react'
import { Spinner, Table, Form, Button, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import StringDisplay from '../../../DataTypes/String'
import { ProviderResponse } from '../../../../../types/FHIRTypes/ProviderResponse'
import { Location } from '../../../../../types/FHIRTypes/Location'
import useEnvironment from '../../../../../hooks/location/useEnviroment'

interface LocationListProps {
    locations: ProviderResponse<Location>
    clickViewDataLink: (location: Location) => void
    isFetching: boolean
    isLoading: boolean
    isSuccess: boolean
    searchProviderResource: { searchTerms: string, resource: string }
    clearForm: () => void
    newSearch: () => void
    updateSearchTerms: (e: React.ChangeEvent<HTMLInputElement>) => void
    totalPages: number;
    currentPage: number;
    handleChangePage: (page: number | string) => void;
}

const LocationList: FC<LocationListProps> = ({ locations, clickViewDataLink, isFetching, isLoading, isSuccess, searchProviderResource, clearForm, newSearch, updateSearchTerms, totalPages, currentPage, handleChangePage }) => {

    const handleNewSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        newSearch()
    }

    const { data: environment } = useEnvironment()


    return (
        <>
            <h3>Locations</h3>
            <Form noValidate className='form-inline' onSubmit={handleNewSearch}>
                <Form.Group>
                    <Form.Label style={{ margin: '0 1em 0 0' }}>Search by Location Name</Form.Label>
                    <Form.Control
                        style={{ margin: '0 1em 0 0' }}
                        type='text'
                        name='keyword'
                        placeholder="Enter Search Terms"
                        value={searchProviderResource.searchTerms}
                        onChange={updateSearchTerms}
                    />
                </Form.Group>
                <Button className='searchBtn mr-2' type='submit'>
                    search
                </Button>
                <Button className='outline-searchBtn' onClick={clearForm}>
                    Clear
                </Button>
            </Form>
            <hr />

            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                {
                    locations?.data.length > 0 && (
                        <div className='mb-3'>
                            <PaginationButtons
                                totalPages={totalPages}
                                currentPage={currentPage}
                                handleChangePage={handleChangePage}
                            />
                        </div>
                    )
                }
                {locations && locations.total > 0 && environment?.includeTotalInFHIRRequest && (
                        isLoading || isFetching
                            ? <Spinner animation='border' size='sm' />
                            : `${locations.total.toLocaleString()} record(s) returned`
                )}
            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'>Location Name</th>
                        <th className='d-none d-lg-table-cell'>View Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={3} className='text-center'>Loading...</td>
                            </tr>
                        ) : !locations?.data?.length ? (
                            <tr>
                                <td colSpan={6} rowSpan={15} className='text-center'>
                                    No Locations Found
                                </td>
                            </tr>
                        ) : (
                            <>
                                {
                                    locations && isSuccess ? (
                                        locations.data.map((location: Location) => (
                                            <tr key={location.id}>
                                                <td><Link to='#' onClick={() => clickViewDataLink(location)}><StringDisplay data={location.name} /></Link></td>
                                                <td><Link to='#' onClick={() => clickViewDataLink(location)}>Detail</Link></td>
                                            </tr>
                                        ))) : null
                                }
                            </>
                        )
                    }
                </tbody>
                <tfoot>

                    {
                        locations && locations?.data.length > 0 && (
                            <tr className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                                <td scope="row" colSpan={3}>
                                    <PaginationButtons
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        handleChangePage={handleChangePage}
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tfoot>
            </Table>
        </>
    )
}

export default LocationList