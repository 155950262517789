import React, { FC } from 'react'
import { Position as FhirPosition } from '../../../../types/FHIRTypes/Position'
import Decimal from '../../DataTypes/Decimal'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'
import Extension from '../../DataTypes/Extension'

interface PositionProps {
    data?: FhirPosition
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Position: FC<PositionProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    if (!data?.latitude && !data?._longitude) {
        return <Extension nestedExtension={data} />
    }

    return (
        <div><Decimal data={data.latitude} dataExtension={data._longitude} /> <Decimal data={data.longitude} dataExtension={data._longitude} /></div>
    )
}

export default Position