import React from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Boolean from '../../../DataTypes/Boolean'
import { Organization } from '../../../../../types/FHIRTypes/Organization'
import useEnvironment from '../../../../../hooks/location/useEnviroment'
import StringDisplay from '../../../DataTypes/String'

interface HealthcareServiceListProps {
    services: any
    isFetching: boolean
    isLoading: boolean
    isSuccess: boolean
    clickViewDataLink: (organization: Organization) => void
    totalPages: number;
    currentPage: number;
    handleChangePage: (page: number | string) => void
}

const HealthcareServiceList: React.FC<HealthcareServiceListProps> = ({ services, clickViewDataLink, isFetching, isLoading, isSuccess, totalPages, currentPage, handleChangePage }) => {
    const { data: environment } = useEnvironment()

    return (
        <section>
            
            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                <h3>Healthcare Services</h3>
                {
                    services && services?.data.length > 0 && (
                        <div className="d-flex justify-content mt-3">
                        <PaginationButtons
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handleChangePage={handleChangePage}
                        />
                </div>
            )
                }
                {services && services.total > 0 && environment?.includeTotalInFHIRRequest && (
                    <span className='d-md-block'>
                        {(isLoading || isFetching)
                            ? <Spinner animation="border" size="sm" />
                            : `${services.total.toLocaleString()} record(s) returned`
                        }
                    </span>
                )}


            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='name-column'>Service Name</th>
                        <th className='type-column'>Category</th>
                        <th className='active-column'>Active</th>
                        <th className='active-column'>Detail</th>

                    </tr>
                </thead>
                <tbody>
                    {isLoading || isFetching ? (
                        <tr>
                            <td colSpan={4} className="text-center">Loading...</td>
                        </tr>
                    ) : services && isSuccess && services.data?.length === 0 ? (
                        <tr>
                            <td colSpan={4} className="text-center">No Healthcare Services Found.</td>
                        </tr>
                    ) : services && isSuccess ? (
                        services.data
                        .sort((a: any, b: any) => {
                            if (!a.name) return -1
                            if (!b.name) return 1
                    

                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                        })
                    
                            .map((service: any) => (
                                <tr key={service.id}>
                                    <td className="d-none d-lg-table-cell">
                                        <Link to='#' onClick={() => clickViewDataLink(service)}>
                                            <StringDisplay data={service.name} dataExtension={service._name} />
                                        </Link>
                                    </td>
                                    <td>
                                        <CodeableConcept
                                            data={service?.category}
                                            dataExtension={service?._category}
                                        />
                                    </td>
                                    <td className="d-none d-lg-table-cell">
                                        <Boolean data={service?.active} dataExtension={service?._active} />
                                    </td>
                                    <td className='d-none d-lg-table-cell'>
                                        <Link to='#' onClick={() => clickViewDataLink(service)}>Detail</Link>
                                    </td>
                                </tr>
                            ))) : null}
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                        {
                            services && services?.data.length > 0 && (
                                <tr>
                                    <td scope="row" colSpan={4}>
                                        <PaginationButtons
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            handleChangePage={handleChangePage}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    </div>
                </tfoot>
            </Table>
        </section>
    )
}

export default HealthcareServiceList
