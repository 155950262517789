import React, { FC } from 'react'
import { Decimal as FHIRDecimal } from '../../../../types/FHIRTypes/PrimitiveFhirTypes'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'

interface DecimalProps {
    data?: FHIRDecimal
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Decimal: FC<DecimalProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    if (data) {
        return <div>{data}</div>
    }
    return null
}
export default Decimal