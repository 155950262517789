import React, { FC } from 'react'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import HumanName from '../../DataTypes/HumanName'
import ContactPoint from '../../DataTypes/ContactPoint'
import Address from '../../DataTypes/Address'
import DataAbsentReason from '../DataAbsentReason'

interface OrganizationContactProps {
    data?: any[];
    dataExtension?: any;
}

const OrganizationContact: FC<OrganizationContactProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
      return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    return (
        <>
            {data?.map((contact, index) => (
                <div key={`contact-${index}`}>
                    {contact.purpose && (
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: '5px' }}>Purpose:</span>
                            <CodeableConcept data={contact.purpose} dataExtension={contact._purpose} />
                        </div>
                    )}
                    {contact.name && (
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: '5px' }}>Name:</span>
                            <HumanName data={contact.name} dataExtension={contact._name} />
                        </div>
                    )}
                    {contact.telecom && (
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: '5px' }}>Telecom:</span>
                            <ContactPoint data={contact.telecom} dataExtension={contact._telecom} />
                        </div>
                    )}
                    {contact.address && (
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: '5px' }}>Address:</span>
                            <Address data={contact.address} dataExtension={contact._address} />
                        </div>
                    )}
                </div>
            ))}
        </>
    )
}

export default OrganizationContact