import React, { createContext } from 'react'
import { FieldConfig } from '../../../types/FieldConfig'

interface Props {
  config: FieldConfig
  resource?: any
  children?: React.ReactNode
}

interface ResourceConfigContextType {
  config: FieldConfig
  resource?: any
}

const ResourceConfigContext = createContext<ResourceConfigContextType | null>(null)

const ResourceConfigProvider: React.FC<Props> = ({ config, resource, children }) => {
  const contextValue = { config, resource }

  return (
    <ResourceConfigContext.Provider value={contextValue}>
      {children}
    </ResourceConfigContext.Provider>
  )
}

export default ResourceConfigProvider
export { ResourceConfigContext }