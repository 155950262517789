import React, { FC } from 'react'
import { Row, Col } from 'react-bootstrap'
import Narrative from '../../../DataTypes/Narrative'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceType from '../../../DisplayComponents/ResourceType'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import ContactPoint from '../../../DataTypes/ContactPoint'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'
import Address from '../../../DataTypes/Address'
import Position from '../../../DisplayComponents/Position'
import Reference from '../../../DataTypes/Reference'
import Boolean from '../../../DataTypes/Boolean'
import Period from '../../../DataTypes/Period'
import Simple from '../../../DisplayComponents/Simple'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'
import LocationReference from '../../../DisplayComponents/LocationReference'
import Identifier from '../../../DataTypes/Identifier'



import { OrganizationAffiliation } from '../../../../../types/FHIRTypes/OrganizationAffiliation'
import NetworkReference from '../../../DisplayComponents/NetworkReference'

interface Props {
    resource: OrganizationAffiliation | null
}

const config: FieldConfig = {
    active: { label: 'Active', visibility: 'always' },
    period: { label: 'Period', visibility: 'conditional' },
    organization: { label: 'Organization', visibility: 'conditional' },
    participatingOrganization: { label: 'Participating Organization', visibility: 'conditional' },
    network: { label: 'Network', visibility: 'conditional' },
    code: { label: 'Code', visibility: 'conditional' },
    specialty: { label: 'Specialty', visibility: 'conditional' },
    location: { label: 'Location', visibility: 'conditional' },
    healthCareService: { label: 'Services Provided', visibility: 'conditional' },
    telecom: { label: 'Contact Information', visibility: 'conditional' },
    endpoint: { label: 'Endpoint', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    //Metadata  
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const OrganizationAffiliationDetail: FC<Props> = ({ resource }: Props) => {
    return (
        <section>
            <dl className='dataContainer'>
                <ResourceConfigProvider config={config} resource={resource}>
                    {resource ? (
                        <div>
                            <Row>
                                <ShowField field='active'>
                                    <Col sm={3}>
                                        <dt>{config.active.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><Boolean data={resource?.active} dataExtension={resource?._active} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='period'>
                                    <Col sm={3}>
                                        <dt>{config.period.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><Period data={resource?.period} dataExtension={resource?._period} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='organization'>
                                    <Col sm={3}>
                                        <dt>{config.organization.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><OrganizationReference organization={resource?.organization} apiType='Provider Directory' /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='participatingOrganization'>
                                    <Col sm={3}>
                                        <dt>{config.participatingOrganization.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><OrganizationReference organization={resource?.participatingOrganization} apiType='Provider Directory' /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='network'>
                                    <Col sm={3}>
                                        <dt>{config.network.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{resource?.network?.map((network: any, index: any) =>
                                            <div key={`network-${index}`}>
                                                <NetworkReference network={network} apiType='Provider Directory' />
                                            </div>
                                        )}
                                        </dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='code'>
                                    <Col sm={3}>
                                        <dt>{config.code.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{resource?.code?.map((code: any, index: any) =>
                                            <div key={`code-${index}`}>
                                                <CodeableConcept data={code} dataExtension={resource?._code} />
                                            </div>
                                        )}
                                        </dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='specialty'>
                                    <Col sm={3}>
                                        <dt>{config.specialty.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{resource?.specialty?.map((specialty: any, index: any) =>
                                            <div key={`specialty-${index}`}>
                                                <CodeableConcept data={specialty} dataExtension={resource?._specialty} />
                                            </div>
                                        )}
                                        </dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='location'>
                                    <Col sm={3}>
                                        <dt>{config.location.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {resource.location?.map((location, index) => (
                                                <div key={`location-${index}`}>
                                                    <LocationReference location={location} apiType='Provider Directory' />
                                                </div>
                                            ))}
                                        </dd>
                                    </Col>
                                </ShowField>



                                <ShowField field='healthCareService'>
                                    <Col sm={3}>
                                        <dt>{config.healthCareService.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {resource.healthCareService?.map((healthCareService, index) => (
                                                <div key={`healthCareService-${index}`}>
                                                    <Reference data={healthCareService} dataExtension={resource?._healthCareService} />
                                                </div>
                                            ))}
                                        </dd>
                                    </Col>
                                </ShowField>


                                <ShowField field='telecom'>
                                    <Col sm={3}>
                                        <dt>{config.telecom.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {resource.telecom?.map((telecom, index) => (
                                                <div key={`telecom-${index}`}>
                                                    <ContactPoint data={telecom} dataExtension={resource._telecom} />
                                                </div>
                                            ))}
                                        </dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='endpoint'>
                                    <Col sm={3}>
                                        <dt>{config.endpoint.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {resource.endpoint?.map((endpoint, index) => (
                                                <div key={`endpoint-${index}`}>
                                                    <Reference data={endpoint} dataExtension={resource?._endpoint} />
                                                </div>
                                            ))}
                                        </dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='text'>
                                    <Col sm={3}>
                                        <dt>{config.text.label}</dt>
                                    </Col>
                                    <Col sm={3}>
                                        <dd><Narrative data={resource?.text} dataExtension={resource?._telecom} /></dd>
                                    </Col>
                                </ShowField>
                            </Row>

                            <div className='footer'>
                                <hr />
                                <h6>FHIR Resource Metadata</h6>
                                <Row>
                                    <ShowField field='resourceType'>
                                        <Col sm={4}>
                                            <dt>{config.resourceType.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd>{<ResourceType resourceType={resource?.resourceType} />}</dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='id'>
                                        <Col sm={4}>
                                            <dt>{config.id.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><StringDisplay data={resource?.id} dataExtension={resource?._id} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='meta'>
                                        <Col sm={4}>
                                            <dt>{config.meta.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><MetaLastUpdated data={resource?.meta} dataExtension={resource?._meta} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='profile'>
                                        <Col sm={4}>
                                            <dt>{config.profile.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd>{<Profile profile={resource?.meta?.profile} />}</dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='language'>
                                        <Col sm={4}>
                                            <dt>{config.language.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Code
                                                data={resource?.language} dataExtension={resource?._language}
                                            /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='implicitRules'>
                                        <Col sm={4}>
                                            <dt>{config.implicitRules.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><URI data={resource?.implicitRules} dataExtension={resource?._implicitRules} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='identifier'>
                                        <Col sm={4}>
                                            <dt>{config.identifier.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd>
                                                {
                                                    resource.identifier?.map((identifier, index) => (
                                                        <div key={`identifier-${index}`}>
                                                            <Identifier data={resource.identifier} dataExtension={resource._identifier} />
                                                        </div>
                                                    ))
                                                }
                                            </dd>
                                        </Col>
                                    </ShowField>
                                </Row>
                            </div>
                        </div>

                    ) : (
                        <div className='text-center'>
                            No Organization Affiliations Found
                        </div>
                    )}
                </ResourceConfigProvider>
            </dl>
        </section>
    )
}

export default OrganizationAffiliationDetail

