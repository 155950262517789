import React, { FC } from 'react'
import { Uri as FHIRUri } from '../../../../types/FHIRTypes/PrimitiveFhirTypes'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'

interface URIProps {
  data?: FHIRUri | undefined
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const URI: FC<URIProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (!data && dataExtension) {
      return <DataAbsentReason dataExtension={dataExtension} />
  }
  const getURIDisplay = (): string => {        
      return data || ''
  }
    
  return (
    <div>{getURIDisplay()}</div>
  )
}

export default URI