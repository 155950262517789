import React, { FC } from 'react'
import CarePlanList from './CarePlanList'
import CarePlanDetail from './CarePlanDetail'
import { CarePlanBundle } from '../../../../types/FHIRTypes/Bundle'
import LakelandCarePlanList from './LakelandCarePlans/LakelandCarePlanList'
import LakelandCarePlanDetail from './LakelandCarePlans/LakelandCarePlanDetail'

interface Props {
  patientData: any;
  clientCarePlanType: string | undefined;
  carePlanData: CarePlanBundle;
  carePlanDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (page: number | string) => void
  totalPages: number;
  currentPage: number;
}

const CarePlans: FC<Props> = ({ patientData, clientCarePlanType, carePlanData, carePlanDetailId, page, isFetching, handleChangePage, totalPages, currentPage }) => {



  return (

    <>
      {clientCarePlanType === 'STANDARD' ? (
        <div>
          {
            carePlanDetailId && page === 'CarePlan'
              ? (
                <CarePlanDetail
                  carePlanDetailId={carePlanDetailId}
                  carePlanData={carePlanData}
                  isFetching={isFetching}
                />
              )
              : (
                <CarePlanList
                  carePlanData={carePlanData}
                  handleChangePage={handleChangePage}
                  isFetching={isFetching}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              )
          }
        </div>
      ) : (
        <div>
          {
            carePlanDetailId && page === 'CarePlan'
              ? (
                <LakelandCarePlanDetail
                  lakelandCarePlanDetailId={carePlanDetailId}
                  lakelandCarePlanData={carePlanData}
                  isFetching={isFetching}
                />
              )
              : (
                <LakelandCarePlanList
                  lakelandCarePlanData={carePlanData}
                  handleChangePage={handleChangePage}
                  isFetching={isFetching}
                  patientData={patientData}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              )
          }
        </div>
      )}
    </>
  )
}
export default CarePlans