import React, { FC } from 'react'
import { Container } from 'react-bootstrap'
import { IntlProvider } from 'react-intl'
import useEnvironment from '../../hooks/location/useEnviroment'

import {
  companyMessage,
  preferedName,
} from '../../assets/i18n/i18n'

import '../../App.scss'


const Developer: FC = () => {
  const { data: environment } = useEnvironment()

  return (
    <main className='content'>
      <IntlProvider locale='en' messages={companyMessage}>

        <Container>
          <h2 className='headerText'>{preferedName} API</h2>

          <p>The {preferedName} API is a standards-based <a href="https://www.hl7.org/fhir/http.html" target="_blank" rel="noreferrer">RESTful API</a> that enables third party application vendors to connect their applications for access to {preferedName} data.</p>

          <p>The {preferedName} API utilizes Smile<sup>&trade;</sup> Digital Health as the back-end FHIR server and authorization provider, and conforms to following standards and implementation guides:</p>

          <h3 className='font-weight-bold'>FHIR v4.0.1</h3>
          <ul>

            <li><a href="http://hl7.org/fhir/R4/" target="_blank" rel="noreferrer">FHIR version R4 (v4.0.1)</a></li>
            <li><a href="https://hl7.org/fhir/us/core/STU3.1.1/" target="_blank" rel="noreferrer">US Core Implementation Guide v3.1.1</a></li>

          </ul>

          <h3 className='font-weight-bold'>OAuth 2.0 and OpenID Connect</h3>

          <ul>
            <li><a href="https://oauth.net/2/" target="_blank" rel="noreferrer">oAuth 2.0</a></li>
            <li><a href="https://openid.net/specs/openid-connect-core-1_0.html" target="_blank" rel="noreferrer">OpenID Connect</a></li>
          </ul>

          <h3 className='font-weight-bold'>API Syntax</h3>

          <h4 className='font-weight-bold'>Patient Access API</h4>

          <ul>
            <li><a href='https://build.fhir.org/ig/HL7/carin-bb/index.html' target="_blank" rel="noreferrer">CARIN IG for Blue Button</a><sup>&reg;</sup></li>
            <li><a href={`${environment?.smileURL}/swagger-ui/`} target="_blank" rel="noreferrer">API Syntax documentation using Swagger</a></li>
            <li><a href={`${environment?.smileURL}/fhir/metadata`} target="_blank" rel="noreferrer">API Capability Statement</a></li>
          </ul>

          <h4 className='font-weight-bold'>Provider Directory API</h4>

          <ul>
            <li><a href="https://hl7.org/fhir/us/davinci-pdex-plan-net/index.html" target="_blank" rel="noreferrer">HL7 FHIR Da Vinci Payer Data Exchange (PDEX) Plan Net IG STU 1.1.0</a></li>
            <li><a href={`${environment?.smileProviderURL}/swagger-ui/`} target="_blank" rel="noreferrer">API Syntax documentation using Swagger</a></li>
            <li><a href={`${environment?.smileProviderURL}/fhir/metadata`} target="_blank" rel="noreferrer">API Capability Statement</a></li>
          </ul>

          <h3 className='font-weight-bold'>Process for Requesting Access</h3>

          <p>The process for a third party developer to request access is as follows:</p>

          <ol>
            <li><a href="/app-register">Register your application</a></li>
            <li>You will be contacted by a member of our team to coordinate access to our DEMO portal for testing and verification.</li>
            <li>If approved for production access, a member of our team will work with you to get the necessary production keys and access.</li>
          </ol>

          <h2 className='font-weight-bold'><a href="/app-register">App Developer Registration</a></h2>
        </Container>
      </IntlProvider>
    </main>


  )
}

export default Developer