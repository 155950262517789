import React, { useEffect, useState } from 'react'
import { DiagnosticReport } from '../../../../../types/FHIRTypes/DiagnosticReport'
import { Col, Row, Spinner } from 'react-bootstrap'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import Reference from '../../../DataTypes/Reference'
import Code from '../../../DataTypes/Code'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import DateTime from '../../../DataTypes/DateTime'
import Period from '../../../DataTypes/Period'
import Instant from '../../../DataTypes/Instant'
import StringDisplay from '../../../DataTypes/String'
import Narrative from '../../../DataTypes/Narrative'
import ResourceType from '../../../DisplayComponents/ResourceType'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import Profile from '../../../DisplayComponents/Profile'
import URI from '../../../DataTypes/URI'
import Identifier from '../../../DataTypes/Identifier'
import ProvenanceDetail from '../../Provenance'
import PatientReference from '../../../DisplayComponents/PatientReference'

interface DiagnosticReportDetailProps {
    diagnosticReports?: DiagnosticReport[];
    diagnosticReportDetailId: string | undefined;
    isFetching: boolean;
}

const config: FieldConfig = {
    basedOn: { label: 'Based On', visibility: 'conditional' },
    status: { label: 'Status', visibility: 'always' },
    category: { label: 'Category', visibility: 'always' },
    code: { label: 'Code', visibility: 'always' },
    subject: { label: 'Patient', visibility: 'always' },
    encounter: { label: 'Encounter', visibility: 'conditional' },
    effectiveDateTime: { label: 'Effective', visibility: 'conditional' },
    effectivePeriod: { label: 'Effective', visibility: 'conditional' },
    issued: { label: 'Issued', visibility: 'conditional' },
    performer: { label: 'Performer', visibility: 'always' },
    resultsInterpreter: { label: 'Results Interpreter', visibility: 'conditional' },
    result: { label: 'Result', visibility: 'conditional' },
    conclusion: { label: 'Conclusion', visibility: 'conditional' },
    conclusionCode: { label: 'Conclusion Code', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },

    //Meta Fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'conditional' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const DiagnosticReportDetail: React.FC<DiagnosticReportDetailProps> = ({ diagnosticReportDetailId, diagnosticReports, isFetching }) => {
    const [resource, setResource] = useState<DiagnosticReport | null>(null)

    useEffect(() => {
        if (diagnosticReports) {
            const resource = diagnosticReports.find((report) => report.id === diagnosticReportDetailId)
            setResource(resource || null)
        }

    }, [diagnosticReports])

    if (isFetching) {
        return (
            <>
                <Spinner
                    animation="border"
                    role="status"
                    as='span'
                    aria-hidden="true"
                />
                Loading Diagnostic Reports...
            </>
        )
    }

    return (
        <dl className='dataContainer'>
            {
                resource ? (
                    <ResourceConfigProvider config={config} resource={resource}>
                        <div>
                            <Row>
                                <ShowField field='basedOn'>
                                    <Col sm={4}>
                                        <dt>{config.basedOn.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Reference data={resource.basedOn} dataExtension={resource._basedOn} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='status'>
                                    <Col sm={4}>
                                        <dt>{config.status.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='category'>
                                    <Col sm={4}>
                                        <dt>{config.category.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource.category} dataExtension={resource._category} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='code'>
                                    <Col sm={4}>
                                        <dt>{config.code.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource.code} dataExtension={resource._code} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='subject'>
                                    <Col sm={4}>
                                        <dt>{config.subject.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><PatientReference patient={resource.subject} apiType='Patient Access' /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='encounter'>
                                    <Col sm={4}>
                                        <dt>{config.encounter.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Reference data={resource.encounter} dataExtension={resource._encounter} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='effectiveDateTime'>
                                    <Col sm={4}>
                                        <dt>{config.effectiveDateTime.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><DateTime data={resource.effectiveDateTime} dataExtension={resource._effectiveDateTime} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='effectivePeriod'>
                                    <Col sm={4}>
                                        <dt>{config.effectivePeriod.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Period data={resource.effectivePeriod} dataExtension={resource._effectivePeriod} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='issued'>
                                    <Col sm={4}>
                                        <dt>{config.issued.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Instant data={resource?.issued} dataExtension={resource?._issued} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='performer'>
                                    <Col sm={4}>
                                        <dt>{config.performer.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Reference data={resource.performer} dataExtension={resource._performer} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='resultsInterpreter'>
                                    <Col sm={4}>
                                        <dt>{config.resultsInterpreter.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Reference data={resource.resultsInterpreter} dataExtension={resource._resultsInterpreter} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='result'>
                                    <Col sm={4}>
                                        <dt>{config.result.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Reference data={resource.result} dataExtension={resource._result} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='conclusion'>
                                    <Col sm={4}>
                                        <dt>{config.conclusion.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><StringDisplay data={resource.conclusion} dataExtension={resource._conclusion} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='conclusionCode'>
                                    <Col sm={4}>
                                        <dt>{config.conclusionCode.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource.conclusionCode} dataExtension={resource._conclusionCode} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='text'>
                                    <Col sm={4}>
                                        <dt>{config.text.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                                    </Col>
                                </ShowField>
                            </Row>
                            <div className='footer'>
                                <hr />
                                <h6>FHIR Resource Metadata</h6>
                                <Row>
                                    <ShowField field='resourceType'>
                                        <Col sm={4}>
                                            <dt>{config.resourceType.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><ResourceType resourceType={resource.resourceType} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='id'>
                                        <Col sm={4}>
                                            <dt>{config.id.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='meta'>
                                        <Col sm={4}>
                                            <dt>{config.meta.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><MetaLastUpdated data={resource.meta} dataExtension={resource._meta} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='meta'>
                                        <Col sm={4}>
                                            <dt>{config.profile.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Profile profile={resource.meta?.profile} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='language'>
                                        <Col sm={4}>
                                            <dt>{config.language.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='implicitRules'>
                                        <Col sm={4}>
                                            <dt>{config.implicitRules.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='identifier'>
                                        <Col sm={4}>
                                            <dt>{config.identifier.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Identifier data={resource.identifier} dataExtension={resource._identifier} /></dd>
                                        </Col>
                                    </ShowField>

                                </Row>
                            </div>
                            <Row>
                                <Col sm={12}>
                                    <ProvenanceDetail resourceName='DiagnosticReport' resourceId={diagnosticReportDetailId} />
                                </Col>
                            </Row>
                        </div>
                    </ResourceConfigProvider>
                ) : (
                    <div className='text-center'>
                        Diagnostic Report Not Found
                    </div>
                )
            }
        </dl>
    )
}

export default DiagnosticReportDetail