import React, { FC } from 'react'
import { Practitioner } from '../../../../types/FHIRTypes/Practitioner'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Reference from '../../DataTypes/Reference'
import { QualificationStatusCodeEnum } from '../../../../types/QualificationStatusCodeEnum'
import { UspsStateCodeEnum } from '../../../../types/UspsStateCodeEnum'
import { Extension } from '../../../../types/FHIRTypes/Extension'

interface PractitionerQualificationsProps {
  data?: Practitioner['qualification'] | null | undefined
}

const getStatus = (extensions: Extension[] | undefined) => {
  const statusExtension = extensions?.find(ext => ext.url === 'status')
  return statusExtension ? QualificationStatusCodeEnum[statusExtension.valueCode as keyof typeof QualificationStatusCodeEnum] : null
}

const getWhereValid = (extensions: Extension[] | undefined) => {
  const whereValidExtension = extensions?.find(ext => ext.url === 'whereValid')
  return whereValidExtension?.valueCodeableConcept?.coding
    ?.map(coding => UspsStateCodeEnum[coding.code as keyof typeof UspsStateCodeEnum])
    .join(', ')
}

const PractitionerQualifications: FC<PractitionerQualificationsProps> = ({ data }) => {
  if (!data) return null

  return (
    <div>
      {data?.map((qualification, index) => {
        const status = getStatus(qualification.extension)
        const whereValid = getWhereValid(qualification.extension)

        const qualificationCode = qualification.code?.coding?.[0]?.code

        return (
          <div key={index}>
            <div>
              {qualification.code && (<div> <CodeableConcept data={qualification.code} /></div>
              )}
            </div>
            {/* <div>
              {qualification.code && (<div> <CodeableConcept data={qualification.code} />{qualificationCode && ` (${qualificationCode})`}</div>
              )}
              {qualification.issuer && <span>Issuer: <Reference data={qualification.issuer} dataExtension={qualification._issuer} />  </span>}
              {status && <span>Status: {status}  </span>}
              {whereValid && <span>Where Valid: {whereValid}  </span>}
            </div> */}
          </div>
        )
      })}
    </div>
  )
}

export default PractitionerQualifications