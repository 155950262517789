import React, { FC } from 'react'
import { Adjudication } from '../../../../types/FHIRTypes/ExplanationOfBenefit'
import { AdjudicationCodeEnum } from '../../../../types/AdjudicationCodeEnum'
import humanizeString from 'humanize-string'
import Money from '../../DataTypes/Money'
import { Row, Col } from 'react-bootstrap'

interface AdjudicationAmountProps {
  adjudications: Adjudication[]
}

const AdjudicationAmount: FC<AdjudicationAmountProps> = ({ adjudications }) => {
  if (!adjudications || adjudications.length === 0) return null

  const sortLineItems = (lineItems: Adjudication[]) => {
    return lineItems.sort((a, b) => {
      const aCode = a.category?.coding?.[0]?.code
      const bCode = b.category?.coding?.[0]?.code

      return aCode && bCode ?
      aCode > bCode ? 1 : bCode > aCode ? -1 : 0
      : aCode ? 1 : bCode ? -1 : 0
    })
  }

  const displayLineItemLabel = (code: string) => {
    const foundCode = AdjudicationCodeEnum[code as keyof typeof AdjudicationCodeEnum]
    return foundCode || humanizeString(code)
  }

  return (
    <Row>
      {sortLineItems(adjudications).map((adjudication, index) => (
        <Col key={`adjudication-${index}`} xs={6}>
          <Row>
            <Col>
              {displayLineItemLabel(adjudication.category?.coding?.[0]?.code ?? '')}:
            </Col>
            <Col className='text-right'>
              <Money data={adjudication.amount} />
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  )
}

export default AdjudicationAmount