import React, { FC, useEffect } from 'react'
import { Breadcrumb, Col, Container, Row, Card, Spinner } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import useQuery from '../../../hooks/location/useQuery'
import useProviders from '../../../hooks/admin/useProviders'
import { buildProviderPhone, buildPractitionerName, buildProviderId, buildProviderGender } from '../../../utils/fhirUtils/practitioner'
import isAuthenticated from '../../../routes/PrivateRoute/'
import { buildPatientAddress } from '../../../utils/fhirUtils/patient'

import '../../../App.scss'

interface RouteMatchParams {
  providerId: string;
}

const Provider: FC = () => {
  const { providerId } = useParams<RouteMatchParams>()
  const query = useQuery()
  const name = query.get('name')
  const id = query.get('id')
  const { isLoading, data: providers, isPreviousData, refetch, isFetching } = useProviders(name || '', id || '')
  useEffect(() => {
    refetch()
  }, [])
  const provider = providers && providers.find((provider: any) => provider.id === providerId)

  const providerAddress = provider ? provider.address : []




  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10
      }}
    >
      {/* <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
          Admin Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/providers' }}>
          Provider Directory
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{provider && provider.name ? buildPractitionerName(provider.name) : ''}</Breadcrumb.Item>
      </Breadcrumb> */}
      <Row>
        <Col sm={6}>
          <h3>Provider Profile: {provider && provider.name ? buildPractitionerName(provider.name) : ''}</h3>
        </Col>
        <Col sm={12}>
          <section className='dataContainer'>
            {
              isLoading || isFetching ? (
                <div className='d-flex dataContainer'>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ marginLeft: '10px' }}>Loading Provider Profile...</span>
                </div>
              ) : provider && Object.keys(provider).length > 0 ? (
                <dl>
                  <Row>
                    <Col sm={3}>
                      <dt>Povider Name</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>
                        {provider.name ? buildPractitionerName(provider.name) : 'N/A'}
                      </dd>
                    </Col>
                    <Col sm={3}>
                      <dt>Provider ID</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>
                        {provider.id ? buildProviderId(provider) : 'N/A'}
                      </dd>
                    </Col>
                    <Col sm={3}>
                      <dt>Gender</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>
                        {provider.gender ? buildProviderGender(provider) : 'N/A'}
                      </dd>
                    </Col>
                    <Col sm={3}>
                      <dt>Address</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>

                      </dd>
                    </Col>


                    {/* {
                      provider && provider.practitionerRoles && provider.practitionerRoles.map((practitionerRole: any, index: number) => (
                        <Col key={`practitionerRole${index}`} xs={6}>
                          <Card style={{ width: '100%' }} className='mt-3'>
                            <Card.Header>
                              {practitionerRole.resource.location && practitionerRole.resource.location[0].name ? practitionerRole.resource.location[0].name : 'N/A'}
                              {practitionerRole.resource.location && practitionerRole.resource.location[0].id ? ` - ${practitionerRole.resource.location[0].id}` : 'N/A'}
                            </Card.Header>
                            <Card.Header>{practitionerRole.resource.code && practitionerRole.resource.code[0].text ? practitionerRole.resource.code[0].text : 'N/A'}</Card.Header>
                            <Card.Body>
                              {practitionerRole.resource.location[0] &&
                                <Col>
                                  {practitionerRole.resource.location[0].address.line ? practitionerRole.resource.location[0].address.line : 'N/A'} <br></br>
                                  {practitionerRole.resource.location[0].address.city ? practitionerRole.resource.location[0].address.city : 'N/A'},
                                  {practitionerRole.resource.location[0].address.state ? practitionerRole.resource.location[0].address.state : 'N/A'}
                                  {practitionerRole.resource.location[0].address.postalCode ? practitionerRole.resource.location[0].address.postalCode : 'N/A'}
                                </Col>
                              }
                              <Col>
                                Phone: {practitionerRole.resource.location[0].telecom[0].value ? buildProviderPhone(practitionerRole.resource.location[0].telecom[0].value) : 'N/A'}
                              </Col>
                              <Col>
                                Resource URL: {practitionerRole.fullUrl ? practitionerRole.fullUrl : 'N/A'}
                              </Col>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    } */}
                  </Row>
                </dl>
              ) :
                <tr>
                  <td colSpan={6} rowSpan={15} className='text-center'>
                    No Profile found for this provider.
                  </td>
                </tr>
            }
          </section>
        </Col>
      </Row>
    </Container>
  )
}

export default isAuthenticated(Provider)
