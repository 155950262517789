

import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatEobDataForTable } from '../../../../../utils/fhirUtils/eob'
import PaginationButtons from '../../../../PaginationButtons'
import { ExplanationOfBenefitBundle } from '../../../../../types/FHIRTypes/Bundle'
import Period from '../../../DataTypes/Period'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Identifier from '../../../DataTypes/Identifier'

interface Props {
  eobData: ExplanationOfBenefitBundle;
  isFetching: boolean;
  handleChangePage: (page: number | string) => void
  totalPages: number;
  currentPage: number;
}

const EobList: FC<Props> = ({ eobData, handleChangePage, isFetching, totalPages, currentPage }: Props) => {
  const patientEobs = formatEobDataForTable(eobData)

  return (
    <>
      <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
        {patientEobs.length > 0 &&
          <div className='mb-2'>
            <PaginationButtons
              totalPages={totalPages}
              currentPage={currentPage}
              handleChangePage={handleChangePage}
            />
          </div>
        }
        {eobData?.total > 0 ? `${eobData.total.toLocaleString()} record(s) found` : ''}
      </div>
      <Table size='sm' hover>
        <thead>
          <tr>
            {/* <th scope="col">Member ID</th> */}
            <th className='d-table-cell'> EOB Identifier</th>
            {/* <th scope="col">Payor</th> */}
            <th className='d-table-cell'>EOB Type</th>
            <th className='d-table-cell'> Billable Period</th>
            <th className='d-none d-lg-table-cell'>Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            isFetching ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ marginLeft: '10px' }}>Loading Member EOBs...</span>
                </td>
              </tr>
            ) : patientEobs.length === 0 ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='text-center'>
                  No Explanation Of Benefits found for this member.
                </td>
              </tr>
            ) : (
              <>
                {
                  patientEobs.map((eob) => (
                    <tr key={eob.eobId}>
                      {/* <th scope="row">{eob.memberId}</th> */}
                      <td className="d-table-cell">
                        <Link to={`/patient/ExplanationOfBenefit/${eob.eobId}`}>
                          <Identifier data={eob.identifier} />
                        </Link>
                      </td>
                      <td className="d-table-cell"><CodeableConcept data={eob.type} /></td>
                      <td className="d-table-cell">
                        <Period data={eob.billablePeriod} />
                      </td>
                      <td className="d-none d-lg-table-cell">
                        <Link to={`/patient/ExplanationOfBenefit/${eob.eobId}`}>Detail</Link>
                      </td>
                      {/* <td scope='col'>{eob.payor}</td> */}


                      {/* <td className='d-table-cell'>{<Period period={eob.billablePeriod}/>}</td>
                     <td className='d-none d-lg-table-cell'>{eob.billableEnd ? formatDateInTimeZone(eob.billableEnd, 'MM/dd/yyyy', 'UTC') : ''}</td> */}


                    </tr>
                  ))
                }
              </>
            )
          }
        </tbody>
        <tfoot>
          <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
            {patientEobs.length > 0 &&
              <tr>
                <td scope="row" colSpan={5}>
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handleChangePage={handleChangePage}
                  />
                </td>
              </tr>
            }
          </div>
        </tfoot>
      </Table>
    </>
  )
}


export default EobList
