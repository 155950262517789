//! Edited

import React, { FC } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FormattedMessage, IntlProvider } from 'react-intl'

import {
  companyMessage,
  companyNameFull,
  companyDataDescription,
  dataPortalTitle,
  preferedName,
  contactLink,
  contactEmail,
  contactPhone,
  contactHours
} from '../../assets/i18n/i18n'

import '../../App.scss'

const Help: FC = () => {
  return (
    <main className='content'>

      <IntlProvider locale='en' messages={companyMessage}>
        <Container>

          <h2 className='headerText'>Help</h2>

          <div className="calloutContainer">
            <div>
              <h3 id="contactUs">Contact {preferedName}</h3>

              <h4><a href={companyMessage.ContactLink}>{companyMessage.ContactLink}</a></h4>

              <h4>{companyMessage.ContactPhone}</h4>

            </div>
          </div>

          <h3>What is the {preferedName} {dataPortalTitle}?</h3>
          <p>The {preferedName} {dataPortalTitle} is a tool for members to access their {companyDataDescription} online and in a format that can be used in many ways. Members can log into the {preferedName} {dataPortalTitle} directly and view data, or members can use their {preferedName} {dataPortalTitle} account with approved external apps.</p>
          <p>The {preferedName} {dataPortalTitle} uses standards defined for healthcare including FHIR for data, and other technical standards and best practices. The Member Portal was created to meet the requirements of the Centers for Medicare & Medicaid Services Interoperability and Patient Access final rule (CMS-9115-F). This rule is meant to give patients better access to their health information, leading to better care and improved outcomes.</p>
          <p>For more information on Interoperability and Patient Access, visit: <a href="https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet" target="_blank" rel="noreferrer">https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet</a></p>

          <h3>Is the {preferedName} {dataPortalTitle} Secure? What About External Apps?</h3>
          <p>The {preferedName} {dataPortalTitle} is a HIPAA-compliant secure application and data exchange.</p>
          <p>External apps have been approved for use with the {preferedName} {dataPortalTitle}, and the current list can be found on the <a href="/available-apps">Available Apps</a> page. Once a member begins to use an external authorized app however, that external app has its own level of security and may not be HIPAA-compliant. Each external app has its own level of security.</p>
          <p>It is very important to review the terms and conditions and privacy policy of any external app before using and granting access, and only grant access to trusted apps. Limit the number of apps in use to reduce potential exposure.</p>
          <p>The FTC provides general information about online data safety at: <a href="https://consumer.ftc.gov/identity-theft-and-online-security/online-privacy-and-security" target="_blank" rel="noreferrer">https://consumer.ftc.gov/identity-theft-and-online-security/online-privacy-and-security</a></p>

          <h3>What are a Member&apos;s rights under the Healthcare Insurance Portability Act (HIPAA) and who must follow HIPAA?</h3>
          <p>The U.S. Department of Health and Human Services (HHS) Office for Civil Rights (OCR) enforces the HIPAA Privacy, Security, and Breach Notification Rules, and the Member Safety Act and Rule.
            More information about member rights under HIPAA and who is obligated to follow HIPAA can be found here: <a href="https://www.hhs.gov/hipaa/for-individuals/faq/index.html" target="_blank" rel="noreferrer">https://www.hhs.gov/hipaa/for-individuals/faq/index.html</a></p>

          <h3>Are apps that a Member chooses to use with the {preferedName} {dataPortalTitle} data covered by HIPAA?</h3>
          <p>Most external apps will not be covered by HIPAA. Most external apps will instead fall under the jurisdiction of the Federal Trade Commission (FTC) and the protections provided by the FTC Act.
            The FTC Act, among other things, protects against deceptive acts (e.g., if an app shares personal data without permission, despite having a privacy policy that says it will not do so).
            The FTC provides general information about online data safety at: <a href="https://consumer.ftc.gov/identity-theft-and-online-security/online-privacy-and-security" target="_blank" rel="noreferrer">https://consumer.ftc.gov/identity-theft-and-online-security/online-privacy-and-security</a></p>

          <h3>What should a Member do if they think their data has been breached or an app has used their data inappropriately?</h3>
          <p></p>
          <ul>
            <li>A Member can file a complaint with OCR under HIPAA: <a href="https://www.hhs.gov/hipaa/filing-a-complaint/index.html" target="_blank" rel="noreferrer">https://www.hhs.gov/hipaa/filing-a-complaint/index.html</a></li>
            <li>A Member can file a complaint with OCR using the OCR complaint portal: <a href="https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf" target="_blank" rel="noreferrer">https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf</a></li>
            <li>A Member can file a complaint with the FTC using the FTC complaint assistant: <a href="https://reportfraud.ftc.gov/" target="_blank" rel="noreferrer">https://reportfraud.ftc.gov/</a></li>
          </ul>

          <h3>Which External Apps Have Been Approved So Far?</h3>
          <p>The list of currently approved and available apps can be found on the <a href="/available-apps">Available Apps</a> page. This list will continue to grow as more apps are approved. If a Member would like to use an external app not shown on the list, please <a href="#contactUs">Contact Us</a>.</p>

          <h3>What is ConnectID?</h3>

          <p><a href="https://www.teschglobal.com/connectid" target="_blank" rel="noreferrer">ConnectID</a> is a FHIR-Based member portal product from TESCHGlobal.</p>

          <h3>What is HealthLX?</h3>

          <p><a href="https://www.healthlx.com" target="_blank" rel="noreferrer">HealthLX</a> (Healthcare Language Exchange) is a health tech company focused on solving interoperability and data exchange for payers, providers, and HIT vendors.</p>

          <h3>Does the {preferedName} {dataPortalTitle} have a User Guide?</h3>

          <p>Yes. The {preferedName} {dataPortalTitle} User Guide can be <a href="/help/downloads/MemberPortalUserGuide.pdf" target="_blank" rel="noopener noreferrer">downloaded here</a>. The user guide explains how to use key features and is based on a sample health system.  Specific features or available data and may differ from health system to health system.</p>

        </Container >
      </IntlProvider>
    </main>
  )
}

export default Help
