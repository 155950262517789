import React, { FC, useRef, useEffect } from 'react'
import { Row, Col, Spinner, Card, Table } from 'react-bootstrap'
import { FieldConfig } from '../../../../../../types/FieldConfig'
import { de } from 'date-fns/locale'
import { create } from 'domain'
import { replace } from 'formik'
import ResourceType from '../../../../DisplayComponents/ResourceType'
import { profile } from 'console'
// import {formatDateInTimeZone, formatCarePlanName} from ''
// import useFhirPractitioners from ''
// import { handleCodeableConcept, getLastUpdatedDate } from '../../../../../../utils/helpers'
import ResourceConfigProvider from '../../../../FhirResourceConfigProvider'
import ShowField from '../../../../DisplayComponents/ShowField'
import Narrative from '../../../../DataTypes/Narrative'
import NarrativeStatus from '../../../../DisplayComponents/NarrativeStatus'
import PatientReference from '../../../../DisplayComponents/PatientReference'
import CodeableConcept from '../../../../DataTypes/CodeableConcept'
import StringDisplay from '../../../../DataTypes/String'
import Code from '../../../../DataTypes/Code'
import Reference from '../../../../DataTypes/Reference'
import CarePlanActivityDetail from '../../CarePlanActivityDetail'
import CarePlanGoal from '../../CarePlanGoal'
import ProvenanceDetail from '../../../Provenance'
import MetaLastUpdated from '../../../../DisplayComponents/MetaLastUpdated'
import Profile from '../../../../DisplayComponents/Profile'
import URI from '../../../../DataTypes/URI'
import Identifier from '../../../../DataTypes/Identifier'
import { CarePlanBundle } from '../../../../../../types/FHIRTypes/Bundle'


interface Props {
    lakelandCarePlanDetailId: string;
    lakelandCarePlanData: CarePlanBundle;
    isFetching: boolean;
}


const config: FieldConfig = {
    title: { label: 'Title', visibility: 'conditional' },
    status: { label: 'Status', visibility: 'always' },
    //ID
    intent: { label: 'Intent', visibility: 'always' },
    category: { label: 'Category', visibility: 'always' },
    author: { label: 'Care Manager', visibility: 'conditional' },
    supportingInfo: { label: 'Member Strengths', visibility: 'conditional' },
    activity: { label: 'Focus Areas', visibility: 'conditional' },
    goal: { label: 'Outcome', visibility: 'conditional' },


    //Meta LastUpdated
    //PractitionerName as CareManager
    //Member Strengths -> supportingInfo
    //Focus Areas -> carePlan.code.text
    text: { label: 'Care Plan Summary', visibility: 'always' },
    subject: { label: 'Subject', visibility: 'always' },
    description: { label: 'Description', visibility: 'conditional' },
    encounter: { label: 'Encounter', visibility: 'conditional' },
    period: { label: 'Period', visibility: 'conditional' },
    created: { label: 'Created', visibility: 'conditional' },
    careTeam: { label: 'Care Team', visibility: 'conditional' },
    addresses: { label: 'Addresses', visibility: 'conditional' },
    basedOn: { label: 'Based On', visibility: 'conditional' },
    replaces: { label: 'Replace', visibility: 'conditional' },
    partOf: { label: 'Part Of', visibility: 'conditional' },
    note: { label: 'Note', visibility: 'conditional' },
    //Footer
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Meta', visibility: 'always' },
    profile: { label: 'Profile', visibility: 'conditional' },
    language: { label: 'Language', visibility: 'conditional' },
    implicitRules: { label: 'Implicit Rules', visibility: 'conditional' },
    identifier: { label: 'Identifier', visibility: 'conditional' },
}

const LakelandCarePlanDetail: FC<Props> = ({ lakelandCarePlanDetailId, lakelandCarePlanData, isFetching }: Props) => {
    const carePlanEntry = lakelandCarePlanData?.entry?.find((carePlan: any) => carePlan.resource.id === lakelandCarePlanDetailId)
    const resource = carePlanEntry?.resource


    return (
        <>
            {
                isFetching && (
                    <div className='d-flex dataContainer'>
                        <Spinner
                            as='span'
                            animation='border'
                            role='status'
                            aria-hidden='true'
                        />
                        <span style={{ marginLeft: '10px' }}>Loading Care Plan Data...</span>
                    </div>
                )
            }
            <dl className='dataContainer'>
                {resource ? (
                    <ResourceConfigProvider config={config} resource={resource}>
                        <div>
                            <Row>
                                <ShowField field='title'>
                                    <Col sm={4}>
                                        <dt>{config.title.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><StringDisplay data={resource.title} dataExtension={resource._title} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='status'>
                                    <Col sm={4}>
                                        <dt>{config.status.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='intent'>
                                    <Col sm={4}>
                                        <dt>{config.intent.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.intent} dataExtension={resource._intent} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='category'>
                                    <Col sm={4}>
                                        <dt>{config.category.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource.category} dataExtension={resource._category} /></dd>
                                    </Col>
                                </ShowField>


                                <ShowField field='author'>
                                    <Col sm={4}>
                                        <dt>{config.author.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Reference data={resource.author} dataExtension={resource._author} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='supportingInfo'>
                                    <Col sm={4}>
                                        <dt>{config.supportingInfo.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Reference data={resource.supportingInfo} dataExtension={resource._supportingInfo} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='goal'>
                                    <Col sm={4}>
                                        <dt>{config.goal.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CarePlanGoal patientCarePlan={resource} dataExtension={resource._supportingInfo} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='activity'>
                                    <Col sm={4}>
                                        <dt>{config.activity.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CarePlanActivityDetail activity={resource} dataExtension={resource._activity} /></dd>
                                    </Col>
                                </ShowField>
                            </Row>
                            <div className='footer'>
                                <hr />

                                <h6>FHIR Resource Metadata</h6>
                                <Row>
                                    <ShowField field='resourceType'>
                                        <Col sm={4}>
                                            <dt>{config.resourceType.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='id'>
                                        <Col sm={4}>
                                            <dt>{config.id.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='meta'>
                                        <Col sm={4}>
                                            <dt>{config.meta.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><MetaLastUpdated data={resource.meta} dataExtension={resource._meta} /></dd>
                                        </Col >
                                    </ShowField >
                                    <ShowField field='profile' subResource={resource.meta}>
                                        <Col sm={4}>
                                            <dt>{config.profile.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Profile profile={resource.meta?.profile} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='language'>
                                        <Col sm={4}>
                                            <dt>{config.language.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='implicitRules'>
                                        <Col sm={4}>
                                            <dt>{config.implicitRules.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='identifier'>
                                        <Col sm={4}>
                                            <dt>{config.identifier.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Identifier data={resource.identifier} dataExtension={resource._identifier} /></dd>
                                        </Col >
                                    </ShowField >
                                </Row >
                            </div >
                        </div>
                        <Row>
                            <Col sm={12}>
                                <ProvenanceDetail resourceName='CarePlan' resourceId={lakelandCarePlanDetailId} />
                            </Col>
                        </Row>
                    </ResourceConfigProvider>
                ) : (
                    <div className='text-center'>
                        <span>No Care Plan Data Found</span>
                    </div>
                )
                }
            </dl>
        </>
    )
}
export default LakelandCarePlanDetail
