import React from 'react'
import { InsContact } from '../../../../types/FHIRTypes/InsurancePlan'
import { CodeableConcept as FHIRCodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'
import HumanName from '../../DataTypes/HumanName'
import ContactPoint from '../../DataTypes/ContactPoint'

interface PlanContactProps {
  data?: InsContact | InsContact[] | null
  dataExtension?: FHIRCodeableConcept | FHIRCodeableConcept[] | null
}

const InsPlanContact: React.FC<PlanContactProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (!data && dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

  const contacts = Array.isArray(data) ? data : [data]

  return (
    <div>
      {contacts?.map((contact, index) => (
        <div key={index}>
          <HumanName data={contact?.name} dataExtension={contact?._name} />
          <ContactPoint data={contact?.telecom} dataExtension={contact?._telecom} />
        </div>
      ))}
    </div>
  )
}

export default InsPlanContact