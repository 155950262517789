import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import Narrative from '../../../DataTypes/Narrative'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import Identifier from '../../../DataTypes/Identifier'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import ResourceType from '../../../DisplayComponents/ResourceType'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'
import AdverseReaction from '../../../DisplayComponents/AdverseReaction'

interface Props {
    allergyDetailId: any;
    allergyData: any;
    isFetching: boolean;
}
const config: FieldConfig = {
    patient: { label: 'Patient', visibility: 'always' },
    clinicalStatus: { label: 'Clinical Status', visibility: 'always' },
    verificationStatus: { label: 'Verification Status', visibility: 'always' },
    type: { label: 'Type', visibility: 'conditional' },
    category: { label: 'Category', visibility: 'conditional' },
    criticality: { label: 'Criticality', visibility: 'conditional' },
    code: { label: 'Code', visibility: 'always' },
    reaction: { label: 'Adverse Reaction', visibility: 'always' },
    text: { label: 'Summary', visibility: 'conditional' },

    // footer fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const AllergyDetail: FC<Props> = ({ allergyDetailId, allergyData, isFetching }: Props) => {
    const [resource, setResource] = useState<any>(null)
    const history = useHistory()

    useEffect(() => {
        if (!allergyData) return

        const foundAllergy = allergyData.entry.find((a: any) => a.resource.id === allergyDetailId)
        if (foundAllergy) {
            setResource(foundAllergy.resource)
        } else {
            history.push('/404')
        }
    }, [allergyData, allergyDetailId, history])

    return (
        <>
            {isFetching && (
                <div className='d-flex dataContainer'>
                    <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                    />
                    <span style={{ marginLeft: '10px' }}>Loading Member Allergies...</span>
                </div>
            )}
            <dl className='dataContainer'>
                {resource ? (
                    <ResourceConfigProvider config={config} resource={resource}>
                        <div>
                            <Row>
                                <ShowField field='patient'>
                                    <Col sm={4}>
                                        <dt>{config.patient.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <PatientReference patient={resource.patient} apiType='Patient Access' />
                                    </Col>
                                </ShowField>
                                <ShowField field='clinicalStatus'>
                                    <Col sm={4}>
                                        <dt>{config.clinicalStatus.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource?.clinicalStatus} dataExtension={resource?._clinicalStatus} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='verificationStatus'>
                                    <Col sm={4}>
                                        <dt>{config.verificationStatus.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource?.verificationStatus} dataExtension={resource?._verificationStatus} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='type'>
                                    <Col sm={4}>
                                        <dt>{config.type.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.type} dataExtension={resource._type} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='category'>
                                    <Col sm={4}>
                                        <dt>{config.category.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.category} dataExtension={resource._category} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='criticality'>
                                    <Col sm={4}>
                                        <dt>{config.criticality.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.criticality} dataExtension={resource._criticality} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='code'>
                                    <Col sm={4}>
                                        <dt>{config.code.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource?.code} dataExtension={resource?._code} /></dd>
                                    </Col>
                                </ShowField>
                            </Row>

                            <Row>
                                <ShowField field='reaction'>
                                    <Col sm={4}>
                                        <dt>{config.reaction.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <AdverseReaction reactions={resource?.reaction} />
                                    </Col>
                                </ShowField>
                                <ShowField field='text'>
                                    <Col sm={4}>
                                        <dt>{config.text.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                                    </Col>
                                </ShowField>
                            </Row>
                            <div className='footer'>
                                <hr />
                                <h6>FHIR Resource Metadata</h6>
                                <Row>
                                    <ShowField field='resourceType'>
                                        <Col sm={4}>
                                            <dt>{config.resourceType.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd>{<ResourceType resourceType={resource?.resourceType} />}</dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='id'>
                                        <Col sm={4}>
                                            <dt>{config.id.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='meta'>
                                        <Col sm={4}>
                                            <dt>{config.meta.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><MetaLastUpdated data={resource.meta} dataExtension={resource._meta} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='profile' subResource={resource.meta}>
                                        <Col sm={4}>
                                            <dt>{config.profile.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Profile profile={resource.meta?.profile} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='language'>
                                        <Col sm={4}>
                                            <dt>{config.language.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                        </Col >
                                    </ShowField >
                                    <ShowField field='implicitRules'>
                                        <Col sm={4}>
                                            <dt>{config.implicitRules.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                        </Col>
                                    </ShowField>

                                    <ShowField field='identifier'>
                                        <Col sm={4}>
                                            <dt>{config.identifier.label}</dt>
                                        </Col>
                                        <Col sm={8}>
                                            <dd><Identifier data={resource.identifier} dataExtension={resource._identifier} /></dd>
                                        </Col>
                                    </ShowField>
                                </Row >
                            </div >
                            <Row>
                                <Col sm={12}>
                                    <ProvenanceDetail resourceName='AllergyIntolerance' resourceId={allergyDetailId} />
                                </Col>
                            </Row>
                        </div >
                    </ResourceConfigProvider>
                ) : (
                    <div className='text-center'>
                        No Allergy Details found for this member.
                    </div>
                )}
            </dl >
        </>
    )
}
export default AllergyDetail