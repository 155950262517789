import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { buildPatientBirthday, buildPatientMemberId } from '../../../../utils/fhirUtils/patient'
import useEnvironment from '../../../../hooks/location/useEnviroment'
import { findExtensionByURI } from '../../../../utils/helpers'
import { Patient } from '../../../../types/FHIRTypes/Patient'
import HumanName from '../../DataTypes/HumanName'
import Address from '../../DataTypes/Address'
import ContactPoint from '../../DataTypes/ContactPoint'
import ProvenanceDetail from '../Provenance'
import PatientContact from '../../DisplayComponents/PatientContact'
import Narrative from '../../DataTypes/Narrative'
import Identifier from '../../DataTypes/Identifier'
import { FieldConfig } from '../../../../types/FieldConfig'
import ShowField from '../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../FhirResourceConfigProvider'
import CapitalizedText from '../../DisplayComponents/CapitalizedText'
import Reference from '../../DataTypes/Reference'
import MetaLastUpdated from '../../DisplayComponents/MetaLastUpdated'
import OrganizationReference from '../../DisplayComponents/OrganizationReference'
import Simple from '../../DisplayComponents/Simple'
import ResourceType from '../../DisplayComponents/ResourceType'
import URL from '../../DataTypes/URL'
import Date from '../../DataTypes/Date'
import StringDisplay from '../../DataTypes/String'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Code from '../../DataTypes/Code'
import Boolean from '../../DataTypes/Boolean'
import URI from '../../DataTypes/URI'

interface Props {
  patientData: Patient | undefined;
  loading: boolean;
  coverageData: any
}

const config: FieldConfig = {
  mostRecentMemberId: { label: 'Most Recent Member ID', visibility: 'always' },
  identifier: { label: 'Patient Identifier', visibility: 'always' },
  name: { label: 'Name', visibility: 'always' },
  gender: { label: 'Gender', visibility: 'always' },
  birthDate: { label: 'Birth Date', visibility: 'always' },
  address: { label: 'Address', visibility: 'always' },
  communication: { label: 'Communication Language', visibility: 'always' },
  telecom: { label: 'Contact Information', visibility: 'always' },
  race: { label: 'Race', visibility: 'always' },
  ethnicity: { label: 'Ethnicity', visibility: 'always' },
  birthSex: { label: 'Birthsex', visibility: 'always' },
  maritalStatus: { label: 'Marital Status', visibility: 'conditional' },
  multipleBirth: { label: 'Multiple Birth', visibility: 'conditional' },
  generalPractitioner: { label: 'General Practitioner', visibility: 'conditional' },
  managingOrganization: { label: 'Managing Organization', visibility: 'conditional' },
  contact: { label: 'Patient Contacts', visibility: 'conditional' },
  deceased: { label: 'Deceased', visibility: 'conditional' },
  text: { label: 'Patient Summary', visibility: 'conditional' },
  contained: { label: 'Contained', visibility: 'never' },
  extension: { label: 'Extension', visibility: 'never' },
  modifierExtension: { label: 'Modifier Extension', visibility: 'never' },
  link: { label: 'Link', visibility: 'never' },
  photo: { label: 'Photo', visibility: 'never' },

  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  resourceId: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  implicitRules: { label: 'Implicit Rules', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  active: { label: 'Active Resource', visibility: 'conditional' }
}

const MemberProfile: FC<Props> = ({ patientData, loading, coverageData }: Props) => {
  const { data: environmentData } = useEnvironment()

  const patientRace = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race')
  const patientEthnicity = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity')
  const patientBirthSex = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex')

  return (
    <div>
      <section>
        <dl className={`dataContainer ${loading ? 'd-flex' : ''}`}>
          {
            loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  role='status'
                  aria-hidden='true'
                />
                <span style={{ marginLeft: '10px' }}>Loading Member Profile</span>
              </>
            ) : (
            <ResourceConfigProvider config={config} resource={patientData}>
              <Row>
                <ShowField field='mostRecentMemberId'>
                  <Col sm={4}>
                    <dt>{config.mostRecentMemberId.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        buildPatientMemberId(patientData, coverageData, environmentData?.isSingleTenantServer)
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='identifier'>
                  <Col sm={4}>
                    <dt>{config.identifier.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Identifier data={patientData?.identifier} dataExtension={patientData?._identifier} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='name'>
                  <Col sm={4}>
                    <dt>{config.name.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><HumanName data={patientData?.name} dataExtension={patientData?._name} showUse={(patientData?.name?.length ?? 0) > 1} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='gender'>
                  <Col sm={4}>
                    <dt>{config.gender.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <dd><Code data={patientData?.gender} dataExtension={patientData?._gender} /></dd>
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='birthDate'>
                  <Col sm={4}>
                    <dt>{config.birthDate.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Date data={buildPatientBirthday(patientData)} dataExtension={patientData?._birthDate} /></dd>                
                  </Col>
                </ShowField>
                <ShowField field='address'>
                  <Col sm={4}>
                    <dt>{config.address.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Address data={patientData?.address} showUse={(patientData?.address?.length ?? 0) > 1} dataExtension={patientData?._address} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='communication'>
                  <Col sm={4}>
                    <dt>{config.communication.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientData?.communication?.map((comm, index) => (
                        <CodeableConcept key={`communication-${index}`} data={comm.language} dataExtension={comm._language} />
                      ))}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='telecom'>
                  <Col sm={4}>
                    <dt>{config.telecom.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><ContactPoint data={patientData?.telecom} dataExtension={patientData?._telecom} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='race'>
                  <Col sm={4}>
                    <dt>{config.race.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientRace?.extension?.[1].valueString}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='ethnicity'>
                  <Col sm={4}>
                    <dt>{config.ethnicity.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientEthnicity?.extension?.[1].valueString}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='birthSex'>
                  <Col sm={4}>
                    <dt>{config.birthSex.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientBirthSex?.extension?.[1].valueString}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='maritalStatus'>
                  <Col sm={4}>
                    <dt>{config.maritalStatus.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <CodeableConcept data={patientData?.maritalStatus} dataExtension={patientData?._maritalStatus} />
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='multipleBirth'>
                  <Col sm={4}>
                    <dt>{config.multipleBirth.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        patientData?.multipleBirthBoolean !== undefined
                          ? <Simple simple={patientData.multipleBirthBoolean} />
                          : <Simple simple={patientData?.multipleBirthInteger || ''} />
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='generalPractitioner'>
                  <Col sm={4}>
                    <dt>{config.generalPractitioner.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Reference data={patientData?.generalPractitioner} dataExtension={patientData?._generalPractitioner} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='managingOrganization'>
                  <Col sm={4}>
                    <dt>{config.managingOrganization.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <OrganizationReference organization={patientData?.managingOrganization} apiType='Patient Access'/>
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='contact'>
                  <Col sm={4}>
                    <dt>{config.contact.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientData?.contact?.map((contact, index) => (
                        <PatientContact key={`contact-${index}`} contact={contact} />
                      ))}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='deceased'>
                  <Col sm={4}>
                    <dt>{config.deceased.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        patientData?.deceasedBoolean !== undefined
                          ? <CapitalizedText text={String(patientData.deceasedBoolean)} />
                          : patientData?.deceasedDateTime || ''
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='text'>
                  <Col sm={4}>
                    <dt>{config.text.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <Narrative data={patientData?.text} dataExtension={patientData?._text} />
                    </dd>
                  </Col>
                </ShowField>
                <Col sm={12} className='footer'>
                  <hr />
                  <h6>FHIR Resource Metadata</h6>
                  <Row>
                    <ShowField field='resourceType'>
                      <Col sm={4}>
                        <dt>{config.resourceType.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>{<ResourceType resourceType={patientData?.resourceType} />}</dd>
                      </Col>
                    </ShowField>
                    <ShowField field='resourceId'>
                      <Col sm={4}>
                        <dt>{config.resourceId.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><StringDisplay data={patientData?.id} dataExtension={patientData?._id} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='meta'>
                      <Col sm={4}>
                        <dt>{config.meta.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><MetaLastUpdated data={patientData?.meta} dataExtension={patientData?._meta} /> </dd>
                      </Col>
                    </ShowField>
                    <ShowField field='profile' subResource={patientData?.meta}>
                      <Col sm={4}>
                        <dt>{config.profile.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>
                          {
                            patientData?.meta?.profile?.map((profile, index) => (
                              <div key={`resource-profile-${index}`}>{profile}</div>
                            ))
                          }
                        </dd>
                      </Col>
                    </ShowField>
                    <ShowField field='language'>
                      <Col sm={4}>
                        <dt>{config.language.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Code data={patientData?.language} dataExtension={patientData?._language} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='implicitRules'>
                      <Col sm={4}>
                        <dt>{config.implicitRules.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><URI data={patientData?.implicitRules} dataExtension={patientData?._implicitRules} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='active'>
                      <Col sm={4}>
                        <dt>{config.active.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Boolean data={patientData?.active} dataExtension={patientData?._active} /></dd>
                      </Col>
                    </ShowField>
                  </Row>
                </Col>

                <Col sm={12}>
                  <ProvenanceDetail resourceName='Patient' resourceId={patientData?.id} />
                </Col>
              </Row>
            </ResourceConfigProvider>
            )
          }
        </dl>
      </section>

    </div>
  )
}

export default MemberProfile