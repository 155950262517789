import React from 'react'
import { Pagination } from 'react-bootstrap'
import '../../App.scss'

interface PaginationButtonsProps {
    totalPages: number;
    currentPage: number;
    handleChangePage: (page: number | string) => void;
}

const PaginationButtons: React.FC<PaginationButtonsProps> = ({
    totalPages,
    currentPage,
    handleChangePage
}) => {

    const backDisabled = currentPage === 1
    const nextDisabled = currentPage === totalPages

    const getVisiblePages = () => {
        const pages: (number | '1')[] = []
        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i)
            }
        } else {
            pages.push(1)

            if (currentPage > 4) pages.push('1')

            const start = Math.max(2, currentPage - 2)
            const end = Math.min(totalPages - 1, currentPage + 2)

            for (let i = start; i <= end; i++) {
                if (!pages.includes(i)) pages.push(i)
            }

            if (currentPage < totalPages - 3) pages.push('1')

            pages.push(totalPages)
        }

        return pages.sort((a, b) => (a === '1' || b === '1' ? 0 : (a as number) - (b as number)))
    }

    return (
        <Pagination className="justify-content-left">
            <Pagination.First
                disabled={backDisabled}
                onClick={() => handleChangePage(1)}
            >
                {'<<'}
            </Pagination.First>
            <Pagination.Prev
                disabled={backDisabled}
                onClick={() => handleChangePage(currentPage - 1)}
            >
                {'<'}
            </Pagination.Prev>
            {getVisiblePages().map((page, index) =>
                page === '1' ? (
                    <Pagination.Ellipsis key={`ellipsis-${index}`} disabled />
                ) : (
                    <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => handleChangePage(page)}
                    >
                        {page}
                    </Pagination.Item>
                )
            )}
            <Pagination.Next
                disabled={nextDisabled}
                onClick={() => handleChangePage(currentPage + 1)}
            >
                {'>'}
            </Pagination.Next>
            <Pagination.Last
                disabled={nextDisabled}
                onClick={() => handleChangePage(totalPages)}
            >
                {'>>'}
            </Pagination.Last>
        </Pagination>
    )
}

export default PaginationButtons