import React, { FC } from 'react'
import { DateTime as FHIRDateTime } from '../../../../types/FHIRTypes/PrimitiveFhirTypes'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import DateTime from '../DateTime'
import StringDisplay from '../String'
import { isDateTime } from '../../../../typeGuards/fhirTypes'

interface DateTimeOrStringProps {
    data?: FHIRDateTime | string
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const DateTimeOrString: FC<DateTimeOrStringProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    if (isDateTime(data)) {
        return <DateTime data={data} />
    }
    
    return <StringDisplay data={data} />
}

export default DateTimeOrString