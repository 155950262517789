import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import { Location } from '../../../../types/FHIRTypes/Location'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import Reference from '../../DataTypes/Reference'
import ReferencePopover from '../../../ReferencePopover'
import Address from '../../DataTypes/Address'
import ShowField from '../ShowField'
import ResourceConfigProvider from '../../FhirResourceConfigProvider'
import { FieldConfig } from '../../../../types/FieldConfig'
import ContactPoint from '../../DataTypes/ContactPoint'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import { APIType } from '../../../../types/APIType'
import StringDisplay from '../../DataTypes/String'

interface LocationReferenceProps {
    location?: FHIRReference;
    apiType: APIType;
}

const config: FieldConfig = {
    address: { label: 'Address', visibility: 'always' },
    telecom: { label: 'Contact Information', visibility: 'always' },
    type: { label: 'Location Type', visibility: 'conditional' },
    physicalType: { label: 'Physical Type', visibility: 'conditional' },
    value: { label: 'Value', visibility: 'always' },
    display: { label: 'Display', visibility: 'conditional' }
}

const LocationReference: React.FC<LocationReferenceProps> = ({ location, apiType }) => {
    if (!location) return null

    const reference = location?.reference?.split('/')
    const locationId = reference?.[1] || ''

    const {
        data: locationData,
        refetch: getLocation,
        isFetching: fetchingLocation,
        isLoading: loadingLocation
    }: UseQueryResult<Location, Error> = useFhirResourceById(apiType, locationId, 'Location')

    useEffect(() => {
        if (location) {
            getLocation()
        }
    }, [location])

    if (fetchingLocation || loadingLocation) {
        return <Spinner animation="border" role="status" size='sm' />
    }
    return (
        <ResourceConfigProvider config={config} resource={location}>
            <div className="d-flex">
                {locationData?.name ? (
                    <div className="d-flex align-items-center">
                        <StringDisplay data={locationData.name} />
                        <ReferencePopover title="Reference">
                            <ShowField field="value">
                                <Reference data={location} />
                            </ShowField>
                            <ShowField field="display">
                                <StringDisplay data={location.display} />
                            </ShowField>
                        </ReferencePopover>
                    </div>
                ) : (
                    <span>
                        {location?.reference && location?.display
                            ? `${location.reference} (${location.display})`
                            : <Reference data={location} />}
                    </span>
                )}
            </div>
        </ResourceConfigProvider>
    )
}

export default LocationReference