import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Practitioner } from '../../../../../types/FHIRTypes/Practitioner'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Address from '../../../DataTypes/Address'
import Identifier from '../../../DataTypes/Identifier'
import Code from '../../../DataTypes/Code'
import Boolean from '../../../DataTypes/Boolean'
import HumanName from '../../../DataTypes/HumanName'
import ContactPoint from '../../../DataTypes/ContactPoint'
import Date from '../../../DataTypes/Date'
import Narrative from '../../../DataTypes/Narrative'
import ResourceType from '../../../DisplayComponents/ResourceType'
import StringDisplay from '../../../DataTypes/String'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import Profile from '../../../DisplayComponents/Profile'
import URI from '../../../DataTypes/URI'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import PractitionerQualifications from '../../../DisplayComponents/PractitionerQualifications'
import Period from '../../../DataTypes/Period'
import { PractitionerRole } from '../../../../../types/FHIRTypes/PractitionerRole'
import PractitionerReference from '../../../DisplayComponents/PractitionerReference'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'
import LocationReference from '../../../DisplayComponents/LocationReference'
import Reference from '../../../DataTypes/Reference'
import TimeDisplay from '../../../DataTypes/Time'
import ServiceNotAvailable from '../../../DisplayComponents/NotAvailable'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import NetworkReference from '../../../DisplayComponents/NetworkReference'
import { findExtensionByURI } from '../../../../../utils/helpers'
import AcceptingPatients from '../../../DisplayComponents/AcceptingPatients'
import HealthcareServiceReference from '../../../DisplayComponents/HealthcareServiceReference'

interface PractitionerRoleDetailProps {
    resource: PractitionerRole | null
}

const config: FieldConfig = {
    identifier: { label: 'Identifier', visibility: 'conditional' },
    active: { label: 'Active', visibility: 'always' },
    period: { label: 'Period', visibility: 'conditional' },
    practitioner: { label: 'Practitioner', visibility: 'conditional' },
    organization: { label: 'Organization', visibility: 'conditional' },
    code: { label: 'Practitioner Role', visibility: 'conditional' },
    specialty: { label: 'Specialty', visibility: 'conditional' },
    location: { label: 'Locations', visibility: 'conditional' },
    healthcareService: { label: 'Healthcare Services', visibility: 'conditional' },
    telecom: { label: 'Contact Information', visibility: 'conditional' },
    acceptingpatients: { label: 'Accepting New Patients', visibility: 'conditional' },
    networkReference: { label: 'Network Reference', visibility: 'conditional' },
    daysOfWeek: { label: 'Available Time - Days of Week', visibility: 'conditional' },
    allDay: { label: 'Available Time - All Day', visibility: 'conditional' },
    startTime: { label: 'Available Time - Start Time', visibility: 'conditional' },
    endTime: { label: 'Available Time - End Time', visibility: 'conditional' },
    notAvailable: { label: 'Not Available', visibility: 'conditional' },
    endpoint: { label: 'Endpoint', visibility: 'conditional' },

    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
}

const PractitionerRoleDetail: React.FC<PractitionerRoleDetailProps> = ({ resource }) => {
    if (!resource) {
        return (
            <div className='text-center'>
                <dl className='dataContainer'>
                    No Practitioner Roles Found
                </dl>
            </div>
        )
    }
    const networkReferences = resource?.extension?.filter(
        (ext) => ext.url === 'http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/network-reference'
    )
     const acceptingPatientsExtension = findExtensionByURI(
        resource?.extension,
            'http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/newpatients'
        )?.extension
        
    const networks = {
        ...resource,
        networkReference: networkReferences,
        acceptingpatients: acceptingPatientsExtension
    }
    return (
        <section>
            <h3>Practitioner Role</h3>
            <dl className='dataContainer'>
                <ResourceConfigProvider config={config} resource={networks}>
                    <Row>
                        <ShowField field='identifier'>
                            <Col sm={4}>
                                <dt>{config.identifier.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Identifier data={networks?.identifier} dataExtension={resource?._identifier} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='active'>
                            <Col sm={4}>
                                <dt>{config.active.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <Boolean data={networks?.active} dataExtension={resource?._active} />
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='period'>
                            <Col sm={4}>
                                <dt>{config.period.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <Period data={networks?.period} dataExtension={resource?._period} />
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='practitioner'>
                            <Col sm={4}>
                                <dt>{config.practitioner.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <PractitionerReference practitioner={networks.practitioner} apiType='Provider Directory' />
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='organization'>
                            <Col sm={4}>
                                <dt>{config.organization.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <OrganizationReference organization={networks.organization} apiType='Provider Directory' />
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='code'>
                            <Col sm={4}>
                                <dt>{config.code.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <CodeableConcept data={networks?.code} dataExtension={resource?._code} />
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='specialty'>
                            <Col sm={4}>
                                <dt>{config.specialty.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <CodeableConcept data={networks?.specialty} dataExtension={resource?._specialty} />
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='location'>
                            <Col sm={4}>
                                <dt>{config.location.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    {networks?.location?.map((loc, index) => (
                                        <LocationReference
                                            key={`location-${index}`}
                                            location={loc}
                                            apiType='Provider Directory'
                                        />
                                    ))}
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='healthcareService'>
                            <Col sm={4}>
                                <dt>{config.healthcareService.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                {networks?.healthcareService?.map((service, index) => (

                                    <HealthcareServiceReference  key={`healthcareService-${index}`} healthCareService={service}  apiType='Provider Directory'/>
                                ))}
                                    </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='telecom'>
                            <Col sm={4}>
                                <dt>{config.telecom.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><ContactPoint data={networks?.telecom} dataExtension={resource?._telecom} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='acceptingpatients'>
                            <Col sm={4}>
                                <dt>{config.acceptingpatients.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><AcceptingPatients acceptingPatients={networks.acceptingpatients} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field="networkReference">
                            <Col sm={4}>
                                <dt>{config.networkReference.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    {networks?.networkReference?.map((ext, index) => {
                                        return ext.valueReference && (
                                            <NetworkReference key={index} network={ext.valueReference} apiType={'Provider Directory'} />
                                        )
                                    })}
                                </dd>
                            </Col>
                        </ShowField>


                        <ShowField field='daysOfWeek'>
                            <Col sm={4}>
                                <dt>{config.daysOfWeek.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Code data={networks?.daysOfWeek} dataExtension={resource?._daysOfWeek} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='allDay'>
                            <Col sm={4}>
                                <dt>{config.allDay.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Boolean data={networks?.allDay} dataExtension={resource?._allDay} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='startTime'>
                            <Col sm={4}>
                                <dt>{config.startTime.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><TimeDisplay data={networks?.startTime} dataExtension={resource?._startTime} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='endTime'>
                            <Col sm={4}>
                                <dt>{config.endTime.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><TimeDisplay data={networks?.endTime} dataExtension={resource?._endTime} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='notAvailable'>
                            <Col sm={4}>
                                <dt>{config.notAvailable.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><ServiceNotAvailable serviceNotAvailable={networks?.notAvailable} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='endpoint'>
                            <Col sm={4}>
                                <dt>{config.endpoint.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Reference data={networks?.endpoint} dataExtension={resource?._endpoint} /></dd>
                            </Col>
                        </ShowField>


                        <Col sm={12} className='footer'>
                            <hr />

                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType'>
                                    <Col sm={4}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><ResourceType resourceType={networks.resourceType} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id'>
                                    <Col sm={4}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><StringDisplay data={networks.id} dataExtension={resource._id} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta'>
                                    <Col sm={4}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><MetaLastUpdated data={networks.meta} dataExtension={resource._meta} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile'>
                                    <Col sm={4}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Profile profile={networks.meta?.profile} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language'>
                                    <Col sm={4}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={networks.language} dataExtension={resource._language} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules'>
                                    <Col sm={4}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><URI data={networks.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                    </Col>
                                </ShowField>
                            </Row>
                        </Col>
                    </Row>
                </ResourceConfigProvider>
            </dl>
        </section>
    )
}

export default PractitionerRoleDetail