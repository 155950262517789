import React, { FC } from 'react'
import { Period as FHIRPeriod } from '../../../../types/FHIRTypes/Period'
import { DateTime as FHIRDateTime } from '../../../../types/FHIRTypes/PrimitiveFhirTypes'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import Period from '../../DataTypes/Period'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import { isDateTime } from '../../../../typeGuards/fhirTypes'
import DateTime from '../DateTime'

interface PeriodOrDateTimeProps {
    data?: FHIRPeriod | FHIRDateTime
    dataExtension?: CodeableConcept | CodeableConcept[] | null    
}

const PeriodOrDateTime: FC<PeriodOrDateTimeProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }
    
    if (isDateTime(data)) {
        return <DateTime data={data} />
    }

    return <Period data={data} dataExtension={dataExtension} />
}

export default PeriodOrDateTime