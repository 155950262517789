import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ObservationBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Observation } from '../../../../../types/FHIRTypes/Observation'
import PaginationButtons from '../../../../PaginationButtons'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Instant from '../../../DataTypes/Instant'

interface Props {
  observationData: ObservationBundle;
  isFetching: boolean;
  handleChangePage: (page: number | string) => void
  totalPages: number;
  currentPage: number;
}

const ObservationList: FC<Props> = ({ observationData, isFetching, handleChangePage, totalPages, currentPage, }: Props) => {
  const observations = observationData && observationData.entry ? observationData.entry.map((entry) => entry.resource) : []

  return (
    <>
      {observationData?.total > 0 && (
        <div className="mb-2">
          <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
            {`${observationData.total.toLocaleString()} record(s) found`}
          </div>
          <PaginationButtons
            totalPages={totalPages}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </div>
      )}
      <Table size='sm' hover>
        <thead>
          <tr>
            <th className='d-table-cell'>Category</th>
            <th className='d-none d-lg-table-cell'>Type</th>
            <th className='d-none d-lg-table-cell'>Issued</th>
            <th className='d-none d-lg-table-cell'>Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            isFetching ? (
              <tr>
                <td colSpan={4} className='d-flex'>
                  <Spinner
                    as='span'
                    animation='border'
                    role='status'
                    aria-hidden='true'
                  />
                  <span style={{ marginLeft: '10px' }}>Loading Member Observations...</span>
                </td>
              </tr>
            ) : observations.length === 0 ? (
              <tr>
                <td colSpan={4} rowSpan={15} className='text-center'>
                  No Observations found for this member.
                </td>
              </tr>
            ) : (
              <>
                {
                  observations.map((observation: Observation) => (
                    <tr key={observation.id}>
                      <td className='d-table-cell'><Link to={`/patient/Observation/${observation.id}`}><CodeableConcept data={observation?.category} dataExtension={observation._category} /></Link></td>
                      <td className='d-none d-lg-table-cell'><CodeableConcept data={observation?.code} dataExtension={observation._code} /></td>
                      <td className='d-none d-lg-table-cell'><Instant data={observation.issued} dataExtension={observation._issued} convertToLocaleDateString={true} /></td>
                      <td className='d-none d-lg-table-cell'><Link to={`/patient/Observation/${observation.id}`}>Detail</Link></td>
                    </tr>
                  ))
                }
              </>
            )
          }
        </tbody>
        <tfoot>
          <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
            {observations.length > 0 &&
              <tr>
                <td scope="row" colSpan={5}>
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handleChangePage={handleChangePage}
                  />
                </td>
              </tr>
            }
          </div>
        </tfoot>
      </Table>
    </>
  )
}

export default ObservationList