import React from 'react'
import { Col, Row } from 'react-bootstrap'
import OrganizationDescription from '../../../DisplayComponents/OrganizationDescription'
import { Organization } from '../../../../../types/FHIRTypes/Organization'
import Identifier from '../../../DataTypes/Identifier'
import Boolean from '../../../DataTypes/Boolean'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import StringDisplay from '../../../DataTypes/String'
import ContactPoint from '../../../DataTypes/ContactPoint'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'
import Reference from '../../../DataTypes/Reference'
import Narrative from '../../../DataTypes/Narrative'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import ResourceType from '../../../DisplayComponents/ResourceType'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import Profile from '../../../DisplayComponents/Profile'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'
import Address from '../../../DataTypes/Address'
import OrganizationContact from '../../../DisplayComponents/OrganizationContact'

interface OrganizationDetailProps {
    organization: Organization | null
}

const config: FieldConfig = {
    identifier: { label: 'Organization Identifier', visibility: 'always' },
    active: { label: 'Active', visibility: 'always' },
    type: { label: 'Type', visibility: 'always' },
    name: { label: 'Name', visibility: 'always' },
    alias: { label: 'Alias', visibility: 'conditional' },
    telecom: { label: 'Contact Information', visibility: 'always' },
    address: { label: 'Address', visibility: 'always' },
    partOf: { label: 'Part Of', visibility: 'conditional' },
    contact: { label: 'Organization Contacts', visibility: 'conditional' },
    endpoint: { label: 'Endpoint', visibility: 'always' },
    extension: { label: 'Organization Description', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
}

const OrganizationDetail: React.FC<OrganizationDetailProps> = ({ organization }) => {

    if (!organization) {
        return (
            <div className='text-center'>
                <dl className='dataContainer'>
                    No Organizations Found
                </dl>
            </div>
        )
    }

    return (
        <ResourceConfigProvider config={config} resource={organization}>
            <section>
                <h3>Organizations</h3>
                <dl className='dataContainer'>
                    <Row>
                        <ShowField field='identifier'>
                            <Col sm={4}>
                                <dt>{config.identifier.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Identifier data={organization?.identifier} dataExtension={organization?._identifier} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='active'>
                            <Col sm={4}>
                                <dt>{config.active.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Boolean data={organization?.active} dataExtension={organization?._active} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='type'>
                            <Col sm={4}>
                                <dt>{config.type.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    {organization?.type && organization.type.length > 0 &&
                                        organization.type.map((type, index) => (
                                            <td key={`type-${index}`}>
                                                <CodeableConcept data={type} dataExtension={organization?._type} />
                                            </td>
                                        ))
                                    }
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field="name">
                            <Col sm={4}>
                                <dt>{config.name.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><StringDisplay data={organization?.name} dataExtension={organization?._name} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='alias'>
                            <Col sm={4}>
                                <dt>{config.alias.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><StringDisplay data={organization?.alias} dataExtension={organization?._alias} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='telecom'>
                            <Col sm={4}>
                                <dt>{config.telecom.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><ContactPoint data={organization?.telecom} dataExtension={organization?._telecom} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='address'>
                            <Col sm={4}>
                                <dt>{config.address.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Address data={organization?.address} dataExtension={organization?._address} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='partOf'>
                            <Col sm={4}>
                                <dt>{config.partOf.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <OrganizationReference organization={organization?.partOf} apiType='Provider Directory'></OrganizationReference>
                            </Col>
                        </ShowField>
                        <ShowField field='contact'>
                            <Col sm={4}>
                                <dt>{config.contact.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <OrganizationContact data={organization?.contact} dataExtension={organization?._contact} />
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='endpoint'>
                            <Col sm={4}>
                                <dt>{config.endpoint.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Reference data={organization?.endpoint} dataExtension={organization?._endpoint} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='extension'>
                            <Col sm={4}>
                                <dt>{config.extension.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><OrganizationDescription extension={organization?.extension} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='text'>
                            <Col sm={4}>
                                <dt>{config.text.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Narrative data={organization?.text} dataExtension={organization?._text} /></dd>
                            </Col>
                        </ShowField>
                        <Col sm={12} className='footer'>
                            <hr />

                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType'>
                                    <Col sm={4}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><ResourceType resourceType={organization?.resourceType} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id'>
                                    <Col sm={4}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><StringDisplay data={organization?.id} dataExtension={organization?._id} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta'>
                                    <Col sm={4}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><MetaLastUpdated data={organization?.meta} dataExtension={organization?._meta} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' subResource={organization?.meta}>
                                    <Col sm={4}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Profile profile={organization?.meta?.profile} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language'>
                                    <Col sm={4}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code
                                            data={organization?.language} dataExtension={organization?._language}
                                        /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules'>
                                    <Col sm={4}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><URI data={organization?.implicitRules} dataExtension={organization?._implicitRules} /></dd>
                                    </Col>
                                </ShowField>
                            </Row>
                        </Col>
                    </Row>
                </dl>
            </section>
        </ResourceConfigProvider>
    )
}

export default OrganizationDetail