import React from 'react'
import { DiagnosticReportBundle } from '../../../../types/FHIRTypes/Bundle'
import DiagnosticReportList from './DiagnosticReportList'
import DiagnosticReportDetail from './DiagnosticReportDetail'

interface DiagnosticReportProps {
    page: string | undefined;
    diagnosticReportDetailId: string | undefined;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    diagnosticReportData?: DiagnosticReportBundle;
    totalPages: number;
    currentPage: number;
}

const DiagnosticReport: React.FC<DiagnosticReportProps> = ({ page, diagnosticReportDetailId, isFetching, handleChangePage, diagnosticReportData, totalPages, currentPage }) => {
    const diagnosticReports = diagnosticReportData?.entry?.map((entry) => entry.resource) || []

    return (
        <>
            {
                diagnosticReportDetailId && page === 'DiagnosticReport'
                    ? (
                        <DiagnosticReportDetail
                            diagnosticReports={diagnosticReports}
                            diagnosticReportDetailId={diagnosticReportDetailId}
                            isFetching={isFetching}
                        />
                    )
                    : (
                        <DiagnosticReportList
                            data={diagnosticReportData}
                            diagnosticReports={diagnosticReports}
                            isFetching={isFetching}
                            handleChangePage={handleChangePage}
                            totalPages={totalPages}
                            currentPage={currentPage}
                        />
                    )
            }
        </>
    )
}

export default DiagnosticReport