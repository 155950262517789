
export const providerResources = [{
    fhirResource: 'Organization',
    label: 'Organizations',
    filter: 'Name'
},
{
    fhirResource: 'OrganizationAffiliation',
    label: 'Organization Affiliation',
    filter: 'Name'
},

{
    fhirResource: 'Practitioner',
    label: 'Practitioners',
    filter: 'Name'
},
{
    fhirResource: 'PractitionerRole',
    label: 'Practitioner Role',
    filter: 'Name'
},
{
    fhirResource: 'HealthcareService',
    label: 'Healthcare Services'
},
{
    fhirResource: 'InsurancePlan',
    label: 'Insurance Plans',
    // filter: 'Name'
}, {
    fhirResource: 'Location',
    label: 'Locations',
    filter: 'Name'
},
{
    fhirResource: 'Network',
    label: 'Networks'
},
]