import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { CarePlan } from '../../../../../types/FHIRTypes/CarePlan'
import { Goal } from '../../../../../types/FHIRTypes/Goal'
import { CodeableConcept as CodeableConceptType } from '../../../../../types/FHIRTypes/CodeableConcept'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Reference from '../../../DataTypes/Reference'
import Code from '../../../DataTypes/Code'
import Date from '../../../DataTypes/Date'
import Quantity from '../../../DataTypes/Quantity'
import StringDisplay from '../../../DataTypes/String'
import DataAbsentReason from '../../../DisplayComponents/DataAbsentReason'

interface Props {
    patientCarePlan: CarePlan;
    dataExtension?: CodeableConceptType | CodeableConceptType[] | null;
}

const CarePlanGoals: FC<Props> = ({ patientCarePlan, dataExtension }) => {
    const goals: Goal[] = (patientCarePlan.goal as Goal[]) || []

    if (!patientCarePlan && !dataExtension) return null

    if (!patientCarePlan && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    return (
        <>
            {goals.map((goal, index) => (
                <Card key={`care-plan-goal-${index}`} className='mt-3 w-100'>
                    <Card.Header>
                        <div>
                            <p>Goal {index + 1}</p>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={4}>
                                <dt>ID</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><StringDisplay data={goal.id} dataExtension={goal._id} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Lifecycle Status</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Code data={goal.lifecycleStatus} dataExtension={goal._lifecycleStatus} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Achievement Status</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={goal.achievementStatus} dataExtension={goal._achievementStatus} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Category</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={goal.category} dataExtension={goal._category} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Priority</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={goal.priority} dataExtension={goal._priority} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Description</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={goal.description} dataExtension={goal._description} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Subject</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Reference data={goal.subject} dataExtension={goal._subject} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Start Date</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Date data={goal.startDate} dataExtension={goal._startDate} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Start Codeable Concept</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={goal.startCodeableConcept} dataExtension={goal._startCodeableConcept} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Target</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    {goal.target?.map((target, idx) => (
                                        <div key={`goal-target-${idx}`}>
                                            <p>Measure: <CodeableConcept data={target.measure} dataExtension={target._measure} /></p>
                                            <p>Detail Quantity: <Quantity data={target.detailQuantity} dataExtension={target._detailQuantity} /></p>
                                            <p>Detail Range: {target.detailRange?.low?.value || ''} - {target.detailRange?.high?.value || ''}</p>
                                            <p>Detail Codeable Concept: <CodeableConcept data={target.detailCodeableConcept} dataExtension={target._detailCodeableConcept} /></p>
                                            <p>Due Date: <Date data={target.dueDate} dataExtension={target._dueDate} /></p>
                                            <p>Due Duration: {target.dueDuration?.value || ''} {target.dueDuration?.unit || ''}</p>
                                        </div>
                                    )) || ''}
                                </dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Status Date</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Date data={goal.statusDate} dataExtension={goal._statusDate} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Status Reason</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><StringDisplay data={goal.statusReason} dataExtension={goal._statusReason} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Expressed By</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Reference data={goal.expressedBy} dataExtension={goal._expressedBy} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Addresses</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Reference data={goal.addresses} dataExtension={goal._addresses} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Note</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>{goal.note?.map((note) => note.text).join(', ') || ''}</dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Outcome Codeable Concept</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={goal.outcomeCode} dataExtension={goal._outcomeCode} /></dd>
                            </Col>
                            <Col sm={4}>
                                <dt>Outcome Reference</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Reference data={goal.outcomeReference} dataExtension={goal._outcomeReference} /></dd>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))}
        </>
    )
}

export default CarePlanGoals