import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import { Practitioner } from '../../../../types/FHIRTypes/Practitioner'
import HumanName from '../../DataTypes/HumanName'
import Reference from '../../DataTypes/Reference'
import ReferencePopover from '../../../ReferencePopover'
import { getHumanName } from '../../../../utils/fhirUtils/getHumanName'
import ShowField from '../ShowField'
import ResourceConfigProvider from '../../FhirResourceConfigProvider'
import { FieldConfig } from '../../../../types/FieldConfig'
import { isReference } from '../../../../typeGuards/fhirTypes'
import Extension from '../../DataTypes/Extension'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { APIType } from '../../../../types/APIType'
import StringDisplay from '../../DataTypes/String'

interface PractitionerReferenceProps {
    practitioner?: FHIRReference
    apiType: APIType;
}

const config: FieldConfig = {
    value: { label: 'Value', visibility: 'always' },
    display: { label: 'Display', visibility: 'conditional' }
}

const PractitionerReference: React.FC<PractitionerReferenceProps> = ({ practitioner, apiType }) => {
    if (!practitioner) return null

    if (!isReference(practitioner)) {
        return <Extension nestedExtension={practitioner as CodeableConcept} />
    }

    const reference = practitioner?.reference?.split('/')
    const practitionerId = reference?.[1] || ''

    const {
        data: practitionerData,
        refetch: getPractitioner,
        isFetching: fetchingPractitioner,
        isLoading: loadingPractitioner
    }: UseQueryResult<Practitioner, Error> = useFhirResourceById(apiType, practitionerId, 'Practitioner')

    useEffect(() => {
        if (practitioner) {
            getPractitioner()
        }
    }, [practitioner])

    if (fetchingPractitioner || loadingPractitioner) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    const name = practitionerData?.name?.find(name => name.use === 'usual') || practitionerData?.name?.[0]

    return (
        <ResourceConfigProvider config={config} resource={practitioner}>
            <div className="d-flex">
                {practitionerData?.name ? (
                    <div className="d-flex align-items-center">
                        <HumanName data={practitionerData.name} dataExtension={practitionerData._name} />
                        <ReferencePopover title="Reference">
                            <ShowField field="value">
                                <Reference data={practitioner} dataExtension={practitionerData?.extension} />
                            </ShowField>
                            <ShowField field="display">
                                <StringDisplay data={practitioner.display} />
                            </ShowField>
                        </ReferencePopover>
                    </div>
                ) : (
                    <span>
                    {practitioner?.reference && practitioner?.display
                        ? `${practitioner.reference} (${practitioner.display})`
                        : <Reference data={practitioner} />}
                    </span>
                )}
            </div>
        </ResourceConfigProvider>
    )
}

export default PractitionerReference
