import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { CodeableConcept as CodeableConceptFhirType } from '../../../../types/FHIRTypes/CodeableConcept'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import { Extension } from '../../../../types/FHIRTypes/Extension'

interface AcceptingPatientsProps {
    acceptingPatients?:  Extension[];
}

const AcceptingPatients: React.FC<AcceptingPatientsProps> = ({ acceptingPatients = []}) => {
    if (!acceptingPatients) return <div>No information available</div>

    const acceptingConcept = acceptingPatients.find((ext) => ext.url === 'acceptingPatients')?.valueCodeableConcept || null

    return (
        <>
            {acceptingConcept && (
                <CodeableConcept data={acceptingConcept} />
            )}
        </>
    )
}

export default AcceptingPatients
