import React, { FC } from 'react'
import Period from '../../DataTypes/Period'
import { NotAvailableEntry } from '../../../../types/FHIRTypes/NotAvailableEntry'



interface ServiceNotAvailableProps {
    serviceNotAvailable?: NotAvailableEntry[];
}

const ServiceNotAvailable: FC<ServiceNotAvailableProps> = ({ serviceNotAvailable = [] }) => {
    if (!serviceNotAvailable.length) return null
    return (
        <div className='not-available'>
            {serviceNotAvailable.map((entry, index) => (
                <div key={index} className='not-available-entry'>
                    {entry.description}{''}
                    {entry.during && <Period data={entry.during} />}
                </div>
            ))}
        </div>
    )
}

export default ServiceNotAvailable
