import React from 'react'
import { Button, Form, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import useEnvironment from '../../../../../hooks/location/useEnviroment'
import HumanName from '../../../DataTypes/HumanName'
import { Practitioner } from '../../../../../types/FHIRTypes/Practitioner'
import { ProviderResponse } from '../../../../../types/FHIRTypes/ProviderResponse'

interface PractitionerListProps {
    practitioners: ProviderResponse<Practitioner>;
    clickViewDataLink: (organization: Practitioner) => void
    isFetching: boolean
    isLoading: boolean
    isSuccess: boolean
    searchProviderResource: { searchTerms: string, resource: string }
    clearForm: () => void
    newSearch: () => void
    updateSearchTerms: (e: React.ChangeEvent<HTMLInputElement>) => void
    totalPages: number;
    currentPage: number;
    handleChangePage: (page: number | string) => void;
}

const PractitionerList: React.FC<PractitionerListProps> = ({
    practitioners,
    clickViewDataLink,
    isFetching,
    isLoading,
    isSuccess,
    searchProviderResource,
    clearForm,
    newSearch,
    updateSearchTerms,
    totalPages,
    currentPage,
    handleChangePage
}) => {
    const { data: environment } = useEnvironment()

    const handleNewSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        newSearch()
    }


    return (
        <>
            <h3>Practitioners</h3>
            <Form noValidate className='form-inline' onSubmit={handleNewSearch}>
                <Form.Group>
                    <Form.Label style={{ margin: '0 1em 0 0' }}>Search by Practitioner Name</Form.Label>
                    <Form.Control
                        style={{ margin: '0 1em 0 0' }}
                        type='text'
                        name='keyword'
                        placeholder="Enter Search Terms"
                        value={searchProviderResource.searchTerms}
                        onChange={updateSearchTerms}
                    />
                </Form.Group>
                <Button className='searchBtn mr-2' type='submit'>
                    Search
                </Button>
                <Button className='outline-searchBtn' onClick={clearForm}>
                    Clear
                </Button>
            </Form>
            <hr />
            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                {
                    practitioners && practitioners?.data.length > 0 && (
                        <div className='mb-3'>
                            <PaginationButtons
                                totalPages={totalPages}
                                currentPage={currentPage}
                                handleChangePage={handleChangePage}
                            />
                        </div>
                    )
                }
                {
                    practitioners && practitioners.total > 0 && environment?.includeTotalInFHIRRequest && (
                        isLoading || isFetching
                            ? <Spinner animation="border" size="sm" />
                            : `${practitioners.total.toLocaleString()} record(s) returned`
                    )
                }
            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='name-column'>Name</th>
                        <th className='detail-column'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading || isFetching ? (
                        <tr>
                            <td colSpan={4} className="text-center">Loading...</td>
                        </tr>
                    ) : !practitioners?.data?.length ? (
                        <tr>
                            <td colSpan={4} className="text-center">No Practitioners Found.</td>
                        </tr>
                    ) : practitioners && isSuccess ? (
                        practitioners.data
                            .sort((a, b) => {
                                if (!a.name || !a.name[0]?.family) return -1
                                if (!b.name || !b.name[0]?.family) return 1
                                return a.name[0].family.localeCompare(b.name[0].family)
                            })
                            .map((practitioner) => (
                                <tr key={practitioner.id}>
                                    <td><Link to='#' onClick={() => clickViewDataLink(practitioner)}><HumanName data={practitioner?.name.find(name => name.use === 'usual') || practitioner.name[0]} dataExtension={practitioner._name} /></Link></td>

                                    <td><Link to='#' onClick={() => clickViewDataLink(practitioner)}>Detail</Link>
                                    </td>
                                </tr>
                            ))) : null}
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                        {
                            practitioners && practitioners?.data.length > 0 && (
                                <tr>
                                    <td scope="row" colSpan={3}>
                                        <PaginationButtons
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            handleChangePage={handleChangePage}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    </div>
                </tfoot>
            </Table>
        </>
    )
}

export default PractitionerList