import React from 'react'
import { Narrative as FhirNarrative } from '../../../../types/FHIRTypes/Narrative'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import Extension from '../Extension'

interface NarrativeProps {
    data?: FhirNarrative
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Narrative: React.FC<NarrativeProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) return null

    if (!data && dataExtension) {
      return <DataAbsentReason dataExtension={dataExtension} />
    }
    if (data) {
        return (
            <div dangerouslySetInnerHTML={{ __html: data.div }} />
        )
    } else {
        return <Extension nestedExtension={data} />
    }

}

export default Narrative