import React, { FC } from 'react'
import { Observation, ObservationComponent } from '../../../../types/FHIRTypes/Observation'
import Quantity from '../../DataTypes/Quantity'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Period from '../../DataTypes/Period'
import StringDisplay from '../../DataTypes/String'
import TimeDisplay from '../../DataTypes/Time'
import Boolean from '../../DataTypes/Boolean'

interface ObservationValueProps {
    observation: Observation | ObservationComponent | undefined
}

const ObservationValue: FC<ObservationValueProps> = ({ observation }) => {
    if (!observation) return null

    const {
        valueQuantity,
        valueCodeableConcept,
        valueString,
        valueBoolean,
        valueInteger,
        valueTime,
        valueDateTime,
        valuePeriod,
    } = observation

    if (valueQuantity || observation._valueQuantity) {
        return <Quantity data={valueQuantity} dataExtension={observation._valueQuantity} />
    }

    if (valueCodeableConcept || observation._valueCodeableConcept) {
        return <CodeableConcept data={valueCodeableConcept} dataExtension={observation._valueCodeableConcept} />
    }

    if (valueString || observation._valueString) {
        return <StringDisplay data={valueString} dataExtension={observation._valueString} />
    }

    if (valueBoolean || observation._valueBoolean) {
        return <Boolean data={valueBoolean} dataExtension={observation._valueBoolean} />
    }

    if (valueInteger || observation._valueInteger) {
        return <StringDisplay data={valueInteger?.toString()} dataExtension={observation._valueInteger} />
    }

    if (valueTime) {
        return <TimeDisplay data={valueTime} dataExtension={observation._valueTime} />
    }

    if (valueDateTime) {
        return <>{new Date(valueDateTime).toLocaleDateString()}</>
    }

    if (valuePeriod || observation._valuePeriod) {
        return <Period data={valuePeriod} dataExtension={observation._valuePeriod} />
    }

    return null
}

export default ObservationValue
