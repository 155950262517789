import React, { FC } from 'react'
import { Spinner, Table, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import { ProviderResponse } from '../../../../../types/FHIRTypes/ProviderResponse'
import useEnvironment from '../../../../../hooks/location/useEnviroment'
import { OrganizationAffiliation } from '../../../../../types/FHIRTypes/OrganizationAffiliation'
import Boolean from '../../../DataTypes/Boolean'
import Period from '../../../DataTypes/Period'
import Reference from '../../../DataTypes/Reference'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'

interface OrganizationAffiliationListProps {
    organizationAffiliations: ProviderResponse<OrganizationAffiliation>
    clickViewDataLink: (organizationAffiliation: OrganizationAffiliation) => void
    isFetching: boolean
    isLoading: boolean
    isSuccess: boolean
    searchProviderResource: { searchTerms: string, resource: string }
    clearForm: () => void
    newSearch: () => void
    // updatedSearchTerms: (e: React.ChangeEvent<HTMLInputElement>) => void
    totalPages: number
    currentPage: number
    handleChangePage: (page: number | string) => void
}

const OrganizationAffiliationList: FC<OrganizationAffiliationListProps> = ({ organizationAffiliations, clickViewDataLink, isFetching, isLoading, isSuccess, searchProviderResource, clearForm, newSearch, totalPages, currentPage, handleChangePage }) => {


    const { data: environment } = useEnvironment()

    return (
        <>

            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                <h3>Organization Affiliations</h3>
                {
                    organizationAffiliations?.data?.length > 0 && (
                        <div className="d-flex justify-content mt-3">
                            <PaginationButtons
                                totalPages={totalPages}
                                currentPage={currentPage}
                                handleChangePage={handleChangePage}
                            />
                        </div>

                    )
                }
                {organizationAffiliations && organizationAffiliations.total > 0 && environment?.includeTotalInFHIRRequest && (
                    <span className='d-md-block'>
                        {(isLoading || isFetching)
                            ? <Spinner animation='border' size='sm' />
                            : `${organizationAffiliations.total.toLocaleString()} record(s) returned`
                        }
                    </span>
                )}
            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'>Active</th>
                        <th className='d-table-cell'>Period</th>
                        <th className='d-table-cell'>Organization</th>
                        <th className='d-table-cell'>Participating Organization</th>
                        <th className='d-table-cell'>Detail</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={3} className='text-center'>Loading...</td>
                            </tr>
                        ) : !organizationAffiliations?.data?.length ? (
                            <tr>
                                <td colSpan={6} rowSpan={15} className='text-center'>
                                    No Organization Affiliations Found
                                </td>
                            </tr>
                        ) : (
                            <>
                                {
                                    organizationAffiliations && isSuccess ? (
                                        organizationAffiliations.data.map((organizationAffiliation: OrganizationAffiliation, index) => (
                                            <tr key={`OrganizationAffiliation-${index}`}>
                                                <td><Link to='#' onClick={() => clickViewDataLink(organizationAffiliation)}><Boolean data={organizationAffiliation.active} /></Link></td>
                                                <td><Period data={organizationAffiliation.period} /></td>
                                                <td><OrganizationReference organization={organizationAffiliation.organization} apiType='Provider Directory' /></td>
                                                <td><OrganizationReference organization={organizationAffiliation.participatingOrganization} apiType='Provider Directory' /></td>
                                                <td><Link to='#' onClick={() => clickViewDataLink(organizationAffiliation)}>Detail</Link></td>
                                            </tr>
                                        ))) : null
                                }
                            </>
                        )
                    }
                </tbody>
                <tfoot>

                    {
                        organizationAffiliations && organizationAffiliations?.data?.length > 0 && (
                            <tr className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                                <td scope='row' colSpan={2}>
                                    <PaginationButtons
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        handleChangePage={handleChangePage}
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tfoot>
            </Table>
        </>
    )
}


export default OrganizationAffiliationList

