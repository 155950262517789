import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import { CareTeamBundle } from '../../../../../types/FHIRTypes/Bundle'
// Display Components ---
import Period from '../../../DataTypes/Period'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import Narrative from '../../../DataTypes/Narrative'
import Identifier from '../../../DataTypes/Identifier'
import ContactPoint from '../../../DataTypes/ContactPoint'
import Reference from '../../../DataTypes/Reference'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import ResourceType from '../../../DisplayComponents/ResourceType'
import URL from '../../../DataTypes/URL'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'

interface Props {
  careTeamDetailId: string
  careTeamData: CareTeamBundle
  isFetching: boolean
}

const config: FieldConfig = {
  subject: { label: 'Patient', visibility: 'always' },
  status: { label: 'Care Team Status', visibility: 'always' },
  category: { label: 'Category', visibility: 'conditional' },
  encounter: { label: 'Encounter', visibility: 'conditional' },
  period: { label: 'Period', visibility: 'conditional' },
  participant: { label: 'Care Team Members', visibility: 'always' },
  telecom: { label: 'Care Team Contact', visibility: 'conditional' },
  text: { label: 'Care Team Summary', visibility: 'conditional' },
  name: { label: 'Name', visibility: 'never' },
  reasonCode: { label: 'Reason', visibility: 'never' },
  reasonReference: { label: 'Reason Reference', visibility: 'never' },
  managingOrganization: { label: 'Managing Organization', visibility: 'never' },
  note: { label: 'Note', visibility: 'never' },
  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const CareTeamDetail: FC<Props> = ({ careTeamDetailId, careTeamData, isFetching }) => {

  const careTeamEntry = careTeamData?.entry?.find(
    (careTeam: any) => careTeam.resource.id === careTeamDetailId
  )


  const resource = careTeamEntry?.resource
  return (
    <>
      {isFetching && (
        <div className='d-flex dataContainer'>
          <Spinner
            as='span'
            animation='border'
            role='status'
            aria-hidden='true'
          />
          <span style={{ marginLeft: '10px' }}>Loading Care Team Data...</span>
        </div>
      )}
      <dl className='dataContainer'>
        {resource ? (
          <ResourceConfigProvider config={config} resource={resource}>
            <div>

              <Row>
                <ShowField field='subject'>
                  <Col sm={4}>
                    <dt>{config.subject.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><PatientReference patient={resource.subject} apiType='Patient Access' /></dd>
                  </Col>
                </ShowField>
                <ShowField field='status'>
                  <Col sm={4}>
                    <dt>{config.status.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='category'>
                  <Col sm={4}>
                    <dt>{config.category.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><CodeableConcept data={resource.category} dataExtension={resource._category} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='encounter'>
                  <Col sm={4}>
                    <dt>{config.encounter.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <Reference data={resource.encounter} dataExtension={resource._encounter} />
                    </dd>
                  </Col >
                </ShowField >
                <ShowField field='period'>
                  <Col sm={4}>
                    <dt>{config.period.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Period data={resource.period} dataExtension={resource._period} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='participant'>
                  <Col sm={4}>
                    <dt>{config.participant.label}</dt>
                  </Col>
                  <Col sm={8}>
                    {resource.participant?.map((participant: any, index: number) => {
                      {/* check if the role data is absent. always show the role, but hide the member if the role is absent */ }
                      const roleDataAbsent = participant.role?.some((role: any) => {
                        return role.extension?.some((ext: any) => ext.url === 'http://hl7.org/fhir/StructureDefinition/data-absent-reason')
                      })
                      return (
                        <div key={`participant-${index}`}>
                          <span>
                            {roleDataAbsent ? (
                              <CodeableConcept data={participant.role} dataExtension={participant._role} />
                            ) : (
                              <span>
                                <CodeableConcept data={participant.role} dataExtension={participant._role} />: <Reference data={participant.member} dataExtension={participant._member} />
                              </span>
                            )}
                          </span>
                        </div>
                      )
                    })}
                  </Col>
                </ShowField>
                <ShowField field='telecom'>
                  <Col sm={4}>
                    <dt>{config.telecom.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><ContactPoint data={resource.telecom} dataExtension={resource._telecom} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='text'>
                  <Col sm={4}>
                    <dt>{config.text.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='name'>
                  <Col sm={4}>
                    <dt>{config.name.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>{resource.name}</dd>
                  </Col>
                </ShowField>
                <ShowField field='reasonCode'>
                  <Col sm={4}>
                    <dt>{config.reasonCode.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><CodeableConcept data={resource.reasonCode} dataExtension={resource._reasonCode} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='reasonReference'>
                  <Col sm={4}>
                    <dt>{config.reasonReference.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Reference data={resource.reasonReference} dataExtension={resource._reasonReference} /></dd>
                  </Col >
                </ShowField >
                <ShowField field='managingOrganization'>
                  <Col sm={4}>
                    <dt>{config.managingOrganization.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Reference data={resource.managingOrganization} dataExtension={resource._managingOrganization} /></dd>
                  </Col >
                </ShowField >
                <ShowField field='note'>
                  <Col sm={4}>
                    <dt>{config.note.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>{resource.note?.map((note: any) => note.text).join(', ')}</dd>
                  </Col>
                </ShowField>
              </Row >

              <div className='footer'>
                <hr />

                <h6>FHIR Resource Metadata</h6>
                <Row>
                  <ShowField field='resourceType'>
                    <Col sm={4}>
                      <dt>{config.resourceType.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='id'>
                    <Col sm={4}>
                      <dt>{config.id.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='meta'>
                    <Col sm={4}>
                      <dt>{config.meta.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><MetaLastUpdated data={resource.meta} dataExtension={resource._meta} /></dd>
                    </Col >
                  </ShowField >
                  <ShowField field='profile' subResource={resource.meta}>
                    <Col sm={4}>
                      <dt>{config.profile.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><Profile profile={resource.meta?.profile} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='implicitRules'>
                    <Col sm={4}>
                      <dt>{config.implicitRules.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='language'>
                    <Col sm={4}>
                      <dt>{config.language.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='identifier'>
                    <Col sm={4}>
                      <dt>{config.identifier.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><Identifier data={resource.identifier} dataExtension={resource._identifier} /></dd>
                    </Col>
                  </ShowField>
                </Row >
              </div >
              <Row>
                <Col sm={12}>
                  <ProvenanceDetail resourceName='CareTeam' resourceId={careTeamDetailId} />
                </Col>
              </Row>
            </div >
          </ResourceConfigProvider>
        ) : (
          <div className='text-center'>
            No Care Teams found for this member.
          </div>
        )}
      </dl >
    </>
  )
}

export default CareTeamDetail