import React, { FC, useState } from 'react'
import { useEffect } from 'react'
import { Button, Container, Form, Table, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useAccounts from '../../../hooks/admin/useAccounts'
import isAuthenticated from '../../../routes/PrivateRoute'
import useUserWithRole from '../../../hooks/authentication/useUserWithRole'
import PermissionGate from '../../../components/PermissionGate'
import { formatDate } from '../../../utils/helpers'

// import '../../../App.scss'

const Users: FC = () => {
  const [accountStatus, setAccountStatus] = useState('all')

  const [page, setPage] = useState(0)

  const { data: accounts, isLoading, refetch, isSuccess, isRefetching } = useAccounts()
  console.log('accounts', accounts)
  const { user } = useUserWithRole()

  const handleNextPage = () => {
    setPage((prevPage) => prevPage += 1)
    refetch()
  }

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage -= 1)
    refetch()
  }

  const updateAccountFilter = (event: any) => {
    setAccountStatus(event.target.value)
    setPage(0)
  }

  const filterAccounts = (accounts: any) => {
    return accounts.filter((account: any) => {
      if (accountStatus === 'active') return account.enabled
      if (accountStatus === 'locked') return !account.enabled
      return account
    })
  }

  return (
    <main>
      <PermissionGate
        user={user}
        role='admin'
      >
        <Container>
          {/* <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
          Admin Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Member Accounts</Breadcrumb.Item>
      </Breadcrumb> */}
          <h2 className='headerText'>Member Accounts</h2>
          <Table size='sm' hover>
            <thead>
              <tr>
                <th scope='col'>&nbsp;</th>
                <th scope='col'>
                  &nbsp;
                </th>
                <th scope='col'>
                  <Form.Control
                    as='select'
                    name='accountStatus'
                    value={accountStatus}
                    onChange={updateAccountFilter}
                  >
                    <option value='all'>All</option>
                    <option value='active'>Active</option>
                    <option value='locked'>Locked</option>
                  </Form.Control>
                </th>
                <th scope='col' colSpan={4}>&nbsp;</th>
              </tr>
              <tr>
                <th scope="col">Member Name</th>
                <th scope="col">Account ID</th>
                <th scope="col">Account Status</th>
                <th scope="col">Details</th>
                <th scope='col'>Proxy</th>
                <th scope='col'>Proxy Name</th>
                <th scope='col'>Last Login</th>
              </tr>
            </thead>
            <tbody>
              {
                (isLoading || isRefetching) && (
                  <tr>
                    <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                      <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                      />
                    </td>
                  </tr>
                )
              }
              {
                !(isLoading || isRefetching) && isSuccess && accounts.accounts.length > 0 && (
                  <>
                    {
                      filterAccounts(accounts.accounts).map((account: any) => (
                        <tr key={account.id}>
                          <td>
                            {account.firstName} {account.lastName}
                          </td>
                          <td>
                            {
                              account.attributes.memberId && account.attributes.memberId[0]
                            }
                          </td>
                          <td>
                            {
                              account.enabled ? 'Active' : 'Locked'
                            }
                          </td>
                          <td>
                            <Link to={`/account/${account.id}`}>Details</Link>
                          </td>
                          <td>
                            {
                              account.isProxy ? 'Y' : ''
                            }
                          </td>
                          <td>
                            {account.proxyFirstName} {account.proxyLastName}
                          </td>
                          <td>
                            {
                              account.lastLoginDate ? formatDate(account.lastLoginDate) : ''
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </>
                )
              }
              {
                !(isLoading || isRefetching) && isSuccess && accounts.accounts.length === 0 && (
                  <tr>
                    <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                      <span>No Data found!</span>
                    </td>
                  </tr>
                )
              }
            </tbody>
            {/* {
          accounts && accounts.hasMore !== undefined && (
            <tfoot>
              <tr>
                <td scope="row" colSpan={8}>
                  <Button
                    variant='light'
                    disabled={page === 0}
                    onClick={handlePrevPage}
                  >
                    {'<'} Back
                  </Button>
                  <Button
                    className='custom'
                    disabled={!accounts?.hasMore}
                    onClick={handleNextPage}
                  >
                    Next {'>'}
                  </Button>
                </td>
              </tr>
            </tfoot>
          )
        } */}
          </Table>
        </Container>
      </PermissionGate>

    </main>
  )
}

export default isAuthenticated(Users)