import React, { FC } from 'react'
import { Coding as FHIRCoding } from '../../../../types/FHIRTypes/Coding'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import { isCoding } from '../../../../typeGuards/fhirTypes'
import Extension from '../Extension'

interface CodingProps {
  data?: FHIRCoding | FHIRCoding[]
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Coding: FC<CodingProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    const codings = Array.isArray(data) ? data : [data]

    return (
        <div>
            {codings.map((coding, index) => (
                <div key={index}>
                    {coding?.display ? coding.display : <Extension nestedExtension={coding} />}
                </div>
            ))}
        </div>
    )
}

export default Coding