import React, { FC } from 'react'
import { Identifier as FhirIdentifier } from '../../../../types/FHIRTypes/Identifier'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import Extension from '../../DataTypes/Extension'

interface IdentifierProps {
    data?: FhirIdentifier | FhirIdentifier[]
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Identifier: FC<IdentifierProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    const getIdentifierDisplay = (identifier: FhirIdentifier): string => {
        const typeDisplay = identifier.type?.coding?.[0]?.display ? `${identifier.type.coding[0].display}: ` : ''
        const value = identifier.value || ''
        return `${typeDisplay}${value}`.trim()
    }

    const identifiers = Array.isArray(data) ? data : [data]

    return (
        <div>
            {identifiers.map((identifier, index) => (
                <div key={index}>
                    {identifier ? getIdentifierDisplay(identifier) : <Extension nestedExtension={identifier} />}
                </div>
            ))}
        </div>
    )
}

export default Identifier