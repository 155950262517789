import React, { FC } from 'react'
import { Meta as FHIRMeta } from '../../../../types/FHIRTypes/Meta'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'
import Extension from '../../DataTypes/Extension'

interface MetaProps {
    data?: FHIRMeta
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const MetaLastUpdated: FC<MetaProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }
    
    if (data && data.lastUpdated) {
        return <div>{data.lastUpdated}</div>
    } else {
        return <Extension nestedExtension={data} />
    }    
}

export default MetaLastUpdated