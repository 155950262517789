import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { capitalizeFirstLetter, formatDateInTimeZone, handleCodeableConcept, handleReference } from '../../../../../utils/helpers'
import { Activity, Detail } from '../../../../../types/FHIRTypes/CarePlan'
import { getScheduledTimeData } from '../../../../../utils/fhirUtils/timing'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import { FieldConfig } from '../../../../../types/FieldConfig'
import { CodeableConcept } from '../../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../../../DisplayComponents/DataAbsentReason'


interface Props {
  activity: Activity
  dataExtension?: CodeableConcept | CodeableConcept[] | null

}

const config: FieldConfig = {
  kind: { label: 'Kind', visibility: 'never' },
  code: { label: 'Detail', visibility: 'conditional' },
  reasonCode: { label: 'Reason Code', visibility: 'never' },
  reasonReference: { label: 'Reason Reference', visibility: 'never' },
  goal: { label: 'Goal', visibility: 'never' },
  status: { label: 'Status', visibility: 'never' },
  statusReason: { label: 'Status Reason', visibility: 'never' },
  doNotPerform: { label: 'Do Not Perform', visibility: 'never' },
  scheduled: { label: 'Scheduled', visibility: 'never' },
  location: { label: 'Location', visibility: 'never' },
  performer: { label: 'Performer', visibility: 'never' },
  product: { label: 'Product', visibility: 'never' },
  dailyAmount: { label: 'Daily Amount', visibility: 'never' },
  quantity: { label: 'Quantity', visibility: 'never' },
  description: { label: 'Description', visibility: 'never' },
}

const CarePlanActivityDetail: FC<Props> = ({ activity, dataExtension }) => {
  const details: Detail[] = activity.detail ? [activity.detail] : []
  if (!activity && !dataExtension) return null

  if (!activity && dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }


  const getSchedule = (detail: Detail) => {
    if (detail.scheduledTiming) {
      const renderScheduledTime = (detail: Detail) => {
        const scheduledTimeData = getScheduledTimeData(detail.scheduledTiming)

        if (scheduledTimeData.type === 'event' || scheduledTimeData.type === 'code' || scheduledTimeData.type === 'period' || scheduledTimeData.type === 'string') {
          return <dd>{scheduledTimeData.data}</dd>
        }

        if (scheduledTimeData.type === 'repeat') {
          const { boundsDescription, countDescription, durationDescription, frequencyDescription, periodDescription, dayOfWeek, timeOfDay, whenDescription, } = scheduledTimeData.data

          return (
            <Card>
              <Card.Header>
                <div>
                  <p>Repeat</p>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  {[
                    { label: 'Bounds', value: boundsDescription },
                    { label: 'Count', value: countDescription },
                    { label: 'Duration', value: durationDescription },
                    { label: 'Frequency', value: frequencyDescription },
                    { label: 'Period', value: periodDescription },
                    { label: 'Day of Week', value: dayOfWeek },
                    { label: 'Time of Day', value: timeOfDay },
                    { label: 'When', value: whenDescription },
                  ]
                    .filter(property => property.value).map((property, index) => (
                      <React.Fragment key={index}>
                        <Col sm={3}>
                          <dt>{property.label}</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>{property.value}</dd>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
              </Card.Body>
            </Card>
          )
        }

        return null
      }
      return renderScheduledTime(detail)
    }

    if (detail.scheduledPeriod?.start || detail.scheduledPeriod?.end) {
      return detail.scheduledPeriod.start ? detail.scheduledPeriod.end ? `${formatDateInTimeZone(detail.scheduledPeriod.start, 'MM/dd/yyyy', 'UTC')} - ${formatDateInTimeZone(detail.scheduledPeriod.end, 'MM/dd/yyyy', 'UTC')}` : formatDateInTimeZone(detail.scheduledPeriod.start, 'MM/dd/yyyy', 'UTC') : 'N/A'
    }

    if (detail.scheduledString) {
      return detail.scheduledString
    }
  }

  const getProduct = (detail: Detail) =>
    detail.productCodeableConcept ? handleCodeableConcept(detail.productCodeableConcept) :
      detail.productReference ? handleReference(detail.productReference) :
        ''

  return (
    <>
      {details.map((detail, index) => (
        <div key={`care-plan-activity-detail-${index}`}>
          <ResourceConfigProvider config={config} resource={detail}>
            {/* <Card key={`care-plan-activity-detail-${index}`} className='mt-3 w-100'> */}
            {/* <Card.Header>
            <div>
              <p>Detail</p>
            </div>
          </Card.Header> */}
            {/* <Card.Body> */}
            <Row>
              <ShowField field='kind'>
                <Col sm={4}>
                  <dt>{config.kind.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{capitalizeFirstLetter(detail.kind)}</dd>
                </Col>
              </ShowField>
              <ShowField field='code'>
                <Col sm={4}>
                  <dt>{config.code.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{handleCodeableConcept(detail.code)}</dd>
                </Col>
              </ShowField>
              <ShowField field='reasonCode'>
                <Col sm={4}>
                  <dt>{config.reasonCode.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{handleCodeableConcept(detail.reasonCode)}</dd>
                </Col>
              </ShowField>
              <ShowField field='reasonReference'>
                <Col sm={4}>
                  <dt>{config.reasonReference.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{handleReference(detail.reasonReference)}</dd>
                </Col>
              </ShowField>
              <ShowField field='goal'>
                <Col sm={4}>
                  <dt>{config.goal.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{handleReference(detail.goal)}</dd>
                </Col>
              </ShowField>
              <ShowField field='status'>
                <Col sm={4}>
                  <dt>{config.status.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{capitalizeFirstLetter(detail.status)}</dd>
                </Col>
              </ShowField>
              <ShowField field='statusReason'>
                <Col sm={4}>
                  <dt>{config.statusReason.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{handleCodeableConcept(detail.statusReason)}</dd>
                </Col>
              </ShowField>
              <ShowField field='doNotPerform'>
                <Col sm={4}>
                  <dt>{config.doNotPerform.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{detail.doNotPerform ? 'Yes' : 'No'}</dd>
                </Col>
              </ShowField>
              <ShowField field='scheduled'>
                <Col sm={4}>
                  <dt>{config.scheduled.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{getSchedule(detail)}</dd>
                </Col>
              </ShowField>
              <ShowField field='location'>
                <Col sm={4}>
                  <dt>{config.location.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{handleReference(detail.location)}</dd>
                </Col>
              </ShowField>
              <ShowField field='performer'>
                <Col sm={4}>
                  <dt>{config.performer.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{handleReference(detail.performer)}</dd>
                </Col>
              </ShowField>
              <ShowField field='product'>
                <Col sm={4}>
                  <dt>{config.product.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{getProduct(detail)}</dd>
                </Col>
              </ShowField>
              <ShowField field='dailyAmount'>
                <Col sm={4}>
                  <dt>{config.dailyAmount.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{detail.dailyAmount?.value} {detail.dailyAmount?.unit}</dd>
                </Col>
              </ShowField>
              <ShowField field='quantity'>
                <Col sm={4}>
                  <dt>{config.quantity.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{detail.quantity?.value} {detail.quantity?.unit}</dd>
                </Col>
              </ShowField>
              <ShowField field='description'>
                <Col sm={4}>
                  <dt>{config.description.label}</dt>
                </Col>
                <Col sm={8}>
                  <dd>{detail.description}</dd>
                </Col>
              </ShowField>
            </Row>
            {/* </Card.Body> */}
            {/* </Card> */}
          </ResourceConfigProvider>
        </div>
      ))}
    </>
  )
}

export default CarePlanActivityDetail