import React, { useContext } from 'react'
import { ResourceConfigContext } from '../../FhirResourceConfigProvider'

const ShowField: React.FC<{ field: string; subResource?: any }> = ({ field, subResource, children }) => {
  const context = useContext(ResourceConfigContext)
  if (!context) return null

  const { config, resource } = context
  const visibility = config[field].visibility
  const targetResource = subResource || resource

  switch (visibility) {
    case 'never':
      return null
    case 'always':
      return <>{children}</>
    case 'conditional':
      return targetResource && (targetResource[field] || targetResource[`_${field}`]) ? <>{children}</> : null
    default:
      return null
  }
}

export default ShowField