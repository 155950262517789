import { HumanName } from '../../types/FHIRTypes/HumanName'

export const getHumanName = (name?: HumanName, showUse?: boolean): string => {
    if (!name) return ''

    const prefix = name.prefix?.join(' ') || ''
    const given = name.given?.join(' ') || ''
    const family = name.family || ''
    const suffix = name.suffix?.join(' ') || ''
    const fullName = name.text || `${given} ${family}`.trim()
    const useType = showUse && name.use ? `Type: ${name.use}` : ''
    return `${prefix} ${fullName} ${suffix} ${useType}`.trim()
}