import React from 'react'
import { Button, Form, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import useEnvironment from '../../../../../hooks/location/useEnviroment'
import HumanName from '../../../DataTypes/HumanName'
import { Practitioner } from '../../../../../types/FHIRTypes/Practitioner'
import { ProviderResponse } from '../../../../../types/FHIRTypes/ProviderResponse'
import Boolean from '../../../DataTypes/Boolean'
import Period from '../../../DataTypes/Period'
import PractitionerReference from '../../../DisplayComponents/PractitionerReference'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'
import CodeableConcept from '../../../DataTypes/CodeableConcept'

interface PractitionerRoleListProps {
    practitionersRole: ProviderResponse<Practitioner>;
    clickViewDataLink: (organization: Practitioner) => void
    isFetching: boolean
    isLoading: boolean
    isSuccess: boolean
    searchProviderResource: { searchTerms: string, resource: string }
    clearForm: () => void
    newSearch: () => void
    updateSearchTerms: (e: React.ChangeEvent<HTMLInputElement>) => void
    totalPages: number;
    currentPage: number;
    handleChangePage: (page: number | string) => void;
}

const PractitionerRoleList: React.FC<PractitionerRoleListProps> = ({
    practitionersRole,
    clickViewDataLink,
    isFetching,
    isLoading,
    isSuccess,
    searchProviderResource,
    clearForm,
    newSearch,
    updateSearchTerms,
    totalPages,
    currentPage,
    handleChangePage
}) => {
    const { data: environment } = useEnvironment()


    return (
        <section>
            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                <h3>Practitioner Role</h3>
                {
                    practitionersRole && practitionersRole?.data.length > 0 && (

                        <div className="d-flex justify-content mt-3">
                                <PaginationButtons
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    handleChangePage={handleChangePage}
                                />
                        </div>
                    )
                }
                {practitionersRole && practitionersRole.total > 0 && environment?.includeTotalInFHIRRequest && (
                    <span className='d-md-block'>
                        {(isLoading || isFetching)
                            ? <Spinner animation="border" size="sm" />
                            : `${practitionersRole.total.toLocaleString()} record(s) returned`
                        }
                    </span>
                )}

            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='active-column'>Practitioner</th>
                        <th className='active-column'>Practitioner Role</th>
                        <th className='name-column'>Active</th>
                        <th className='active-column'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading || isFetching ? (
                        <tr>
                            <td colSpan={4} className="text-center">Loading...</td>
                        </tr>
                    ) : practitionersRole && isSuccess && practitionersRole.data?.length === 0 ? (
                        <tr>
                            <td colSpan={4} className="text-center">No Healthcare Services Found.</td>
                        </tr>
                    ) : practitionersRole && isSuccess ? (
                        practitionersRole.data
                            .map((practitionerRole: any) => (
                                <tr key={practitionerRole.id}>
                                    <td className="d-none d-lg-table-cell">
                                        <Link to='#' onClick={() => clickViewDataLink(practitionerRole)}>
                                            <PractitionerReference practitioner={practitionerRole.practitioner} apiType='Provider Directory' />
                                        </Link>
                                    </td>
                                    <td className="d-none d-lg-table-cell">
                                        <CodeableConcept data={practitionerRole?.code} dataExtension={practitionerRole?._code} />
                                    </td>
                                    <td className="d-none d-lg-table-cell">
                                        <Boolean data={practitionerRole?.active} dataExtension={practitionerRole?._active} />
                                    </td>
                                    <td className='d-none d-lg-table-cell'>
                                        <Link to='#' onClick={() => clickViewDataLink(practitionerRole)}>Detail</Link>
                                    </td>
                                </tr>
                            ))) : null}
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                        {
                            practitionersRole && practitionersRole?.data.length > 0 && (
                                <tr>
                                    <td scope="row" colSpan={4}>
                                        <PaginationButtons
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            handleChangePage={handleChangePage}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    </div>
                </tfoot>
            </Table>
        </section>
    )
}

export default PractitionerRoleList
