import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import CodeableConcept from '../../../DataTypes/CodeableConcept'

interface Props {
    conditionData: any;
    patientData: any;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
}


const ConditionList: FC<Props> = ({ conditionData, handleChangePage, patientData, isFetching, totalPages, currentPage }: Props) => {
    const patientConditions = conditionData && conditionData.entry ? conditionData.entry.map((condition: any) => condition.resource) : []

    return (
        <>
            {patientConditions.length > 0 && (
                <div className="mb-2">
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {`${conditionData?.total.toLocaleString()} record(s) found`}
                    </div>
                    <PaginationButtons
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                    />
                </div>
            )}

            <Table size="sm" hover>
                <thead>
                    <tr>
                        <th className="d-table-cell">Clinical Status</th>
                        <th className="d-none d-lg-table-cell">Code</th>
                        <th className="d-none d-lg-table-cell">Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {isFetching ? (
                        <tr>
                            <td colSpan={6} rowSpan={15} className="d-flex">
                                <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                                <span style={{ marginLeft: '10px' }}>Loading Member Diagnoses...</span>
                            </td>
                        </tr>
                    ) : patientConditions.length === 0 ? (
                        <tr>
                            <td colSpan={6} rowSpan={15} className="text-center">
                                No conditions found for this member.
                            </td>
                        </tr>
                    ) : (
                        patientConditions.map((condition: any) => (
                            <tr key={condition.id}>
                                <td className="d-table-cell">
                                    <Link to={`/patient/Condition/${condition.id}`}><CodeableConcept data={condition?.clinicalStatus} dataExtension={condition._clinicalStatus} /></Link>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                    <CodeableConcept data={condition?.code} dataExtension={condition._code} />
                                </td>
                                <td className="d-none d-lg-table-cell">
                                    <Link to={`/patient/Condition/${condition.id}`}>Detail</Link>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {patientConditions.length > 0 &&
                            <tr>
                                <td scope="row" colSpan={5}>
                                    <PaginationButtons
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        handleChangePage={handleChangePage}
                                    />
                                </td>
                            </tr>
                        }
                    </div>
                </tfoot>
            </Table>
        </>
    )
}

export default ConditionList