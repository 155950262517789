import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'
import { APIType } from '../../types/APIType'

export default function useFhirResourceById(apiType: APIType, resourceId: string, resource: string, searchParams?: Record<string, any>): any {

  return useQuery([searchParams, resourceId, resource], async () => {
    const resourceParams = {
      resource: resource,
      resourceId: resourceId || undefined,
      searchParams: searchParams || undefined
    }

    const apiRoutes = {
      'Patient Access': `${getApiServer()}/fhir/resource-by-id`,
      'Provider Directory': `${getApiServer()}/fhir/provider-resource-by-id`
    }

    return await request.get(apiRoutes[apiType]).query(resourceParams).set('Accept', 'application/json').withCredentials().then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 5 * 60 * 1000,
    retry: 0,
    keepPreviousData: true,
    enabled: false
  })
}