import React, { FC } from 'react'
import { formatDollar } from '../../../../utils/helpers'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Money as FHIRMoney } from '../../../../types/FHIRTypes/Money'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import Extension from '../Extension'

interface MoneyProps {
  data?: FHIRMoney
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Money: FC<MoneyProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (!data && dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

  if (data) {   
    const { value, currency } = data
  
    return (
      <span>{formatDollar(value ?? 0)}</span>
    )
  }
  return <Extension nestedExtension={data} />
}

export default Money