import { UseQueryResult, useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

interface EnvironmentData {
    environment: string
    googleRecaptchaSiteKey: string;
    includeTotalInFHIRRequest: boolean;
    isSingleTenantServer: boolean;
    smileURL: string;
    smileProviderURL: string;
    smileTenant: string;
    clientCarePlanType: string;
}

export default function useEnvironment(): UseQueryResult<EnvironmentData, unknown> {
    return useQuery<EnvironmentData, unknown>('environment', async () => {
        const environment = await request.get(`${getApiServer()}/environment`).set('Accept', 'application/json')
        return environment.body
    }, {
        staleTime: 3600000,
        cacheTime: 3600000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 0,
    })
}