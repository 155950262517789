import React, { FC, useEffect, useState } from 'react'
import { ExplanationOfBenefitBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Row, Col, Spinner } from 'react-bootstrap'
import { buildEOBPatientPayment } from '../../../../../utils/fhirUtils/eob'
import { handleCodeableConcept } from '../../../../../utils/helpers'
import EobItem from '../EobItem'
import { appConfig } from '../../../../../assets/customizations/config'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import Identifier from '../../../DataTypes/Identifier'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import RelatedClaim from '../../../DisplayComponents/RelatedClaim'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import Simple from '../../../DisplayComponents/Simple'
import ResourceType from '../../../DisplayComponents/ResourceType'
import AdjudicationItem from '../EobAdjudication'
import EobTotal from '../EobTotal'
import PatientReference from '../../../DisplayComponents/PatientReference'
import Period from '../../../DataTypes/Period'
import Narrative from '../../../DataTypes/Narrative'
import Payee from '../../../DisplayComponents/Payee'
import Diagnosis from '../../../DisplayComponents/Diagnosis'
import EobAddItem from '../EobAddItem'
import CareTeamProvider from '../../../DisplayComponents/CareTeamProvider'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import DateTime from '../../../DataTypes/DateTime'
import Code from '../../../DataTypes/Code'
import PractitionerReference from '../../../DisplayComponents/PractitionerReference'
import EobSupportingInfo from '../../../DisplayComponents/EobSupportInfo'
import { ExplanationOfBenefit } from '../../../../../types/FHIRTypes/ExplanationOfBenefit'
import URI from '../../../DataTypes/URI'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'

interface Props {
  eobDetailId: string;
  eobData: ExplanationOfBenefitBundle;
  isFetching: boolean;
  patientData: any
}

const config: FieldConfig = {
  identifier: { label: 'EOB Identifier', visibility: 'always' },
  status: { label: 'EOB Status', visibility: 'always' },
  type: { label: 'EOB Type', visibility: 'always' },
  subType: { label: 'EOB Sub Type', visibility: 'conditional' },
  use: { label: 'Use', visibility: 'always' },
  patient: { label: 'Patient', visibility: 'always' },
  billablePeriod: { label: 'Billable Period', visibility: 'always' },
  created: { label: 'Created Date', visibility: 'always' },
  insurer: { label: 'Insurer', visibility: 'always' },
  provider: { label: 'Provider', visibility: 'always' },
  related: { label: 'Related Claim', visibility: 'always' },
  payee: { label: 'Payee', visibility: 'always' },
  outcome: { label: 'Outcome', visibility: 'always' },
  careteam: { label: 'Care Team', visibility: 'always' },
  supportingInfo: { label: 'Supporting Info', visibility: 'conditional' },

  diagnosis: { label: 'Diagnosis', visibility: 'always' },
  procedure: { label: 'Procedures Performed', visibility: 'conditional' },
  insurance: { label: 'Insurance', visibility: 'always' },
  item: { label: 'Line Items', visibility: 'always' },
  adjudication: { label: 'Adjudication', visibility: 'conditional' },
  addItem: { label: 'Additional Items', visibility: 'conditional' },
  total: { label: 'Total', visibility: 'conditional' },
  processNote: { label: 'Process Note', visibility: 'always' },
  payment: { label: 'Payment', visibility: 'always' },
  text: { label: 'Summary', visibility: 'conditional' },

  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'always' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
}

const EobDetail: FC<Props> = ({ eobDetailId, eobData, isFetching, patientData }) => {

  const [eob, setEob] = useState<ExplanationOfBenefit | null>(null)
  const history = useHistory()

  useEffect(() => {
    if (!eobData) return

    const eobEntry = eobData?.entry?.find((eob) => eob.resource.id === eobDetailId)
    if (eobEntry) {
      setEob(eobEntry.resource)
    } else {
      history.push('/404')
    }
  }, [eobData])

  return (
    <>
      {
        isFetching && (
          <div className='d-flex dataContainer'>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
            <span style={{ marginLeft: '10px' }}>Loading EOB Data...</span>
          </div>
        )
      }
      <dl className='dataContainer'>
        {
          eob ? (
            <ResourceConfigProvider config={config} resource={eob}>
              <div>
                <dl>
                  <Row>
                    <ShowField field='identifier'>
                      <Col sm={4}>
                        <dt>{config.identifier.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Identifier data={eob.identifier} dataExtension={eob._identifier} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='status'>
                      <Col sm={4}>
                        <dt>{config.status.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Code data={eob.status} dataExtension={eob._status} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='type'>
                      <Col sm={4}>
                        <dt>{config.type.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><CodeableConcept data={eob.type} dataExtension={eob._type} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='subType'>
                      <Col sm={4}>
                        <dt>{config.subType.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><CodeableConcept data={eob.subType} dataExtension={eob._subType} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='use'>
                      <Col sm={4}>
                        <dt>{config.use.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Code data={eob.use} dataExtension={eob._use} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='patient'>
                      <Col sm={4}>
                        <dt>{config.patient.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><PatientReference patient={eob.patient} apiType='Patient Access' /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='billablePeriod'>
                      <Col sm={4}>
                        <dt>{config.billablePeriod.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd> <Period data={eob.billablePeriod} dataExtension={eob._billablePeriod} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='created'>
                      <Col sm={4}>
                        <dt>{config.created.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><DateTime data={eob.created} dataExtension={eob._created} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='insurer'>
                      <Col sm={4}>
                        <dt>{config.insurer.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>
                          <OrganizationReference organization={eob?.contained?.[0]?.payor?.[0]} apiType='Patient Access' />
                        </dd>
                      </Col>
                    </ShowField>
                    <ShowField field='provider'>
                      <Col sm={4}>
                        <dt>{config.provider.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>
                          <PractitionerReference practitioner={eob.provider} apiType='Patient Access' />
                        </dd>
                      </Col>
                    </ShowField>
                    <ShowField field='related'>
                      <Col sm={4}>
                        <dt>{config.related.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>
                          <RelatedClaim relatedClaim={eob.related} />
                        </dd>
                      </Col>
                    </ShowField>
                    <ShowField field='payee'>
                      <Col sm={4}>
                        <dt>{config.payee.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Payee payee={eob.payee} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='outcome'>
                      <Col sm={4}>
                        <dt>{config.outcome.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Code data={eob.outcome} dataExtension={eob._outcome} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='careteam'>
                      <Col sm={4}>
                        <dt>{config.careteam.label}</dt>
                      </Col>
                      <Col sm={8}>
                        {eob.careTeam?.map((teamMember: any, index: number) => (
                          <div key={`eob-careTeam-${index}`} className='mb-3'>
                            <CareTeamProvider
                              sequence={teamMember.sequence?.toString()}
                              providerReference={teamMember.provider?.reference}
                              role={teamMember.role ? handleCodeableConcept(teamMember.role) : ''}
                            />
                          </div>
                        ))}
                      </Col>
                    </ShowField>
                    <ShowField field='supportingInfo'>
                      <Col sm={4}>
                        <dt>{config.supportingInfo.label}</dt>
                      </Col>
                      <Col sm={8}>
                        {
                          eob.supportingInfo?.map((supportingInfo, index) => (
                            <EobSupportingInfo
                              key={`eob-supporting-info-${index}`}
                              supportingInfo={supportingInfo}
                            />
                          ))
                        }
                      </Col>
                    </ShowField>
                    <ShowField field='diagnosis'>
                      <Col sm={4}>
                        <dt>{config.diagnosis.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Diagnosis diagnosis={eob.diagnosis} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='procedure'>
                      <Col sm={4}>
                        <dt>{config.procedure.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>
                          {
                            eob.procedure?.map((procedure: any) => procedure.procedureCodeableConcept?.text).join(', ')
                          }
                        </dd>
                      </Col>
                    </ShowField>
                    <ShowField field='insurance'>
                      <Col sm={4}>
                        <dt>{config.insurance.label}</dt>
                      </Col>
                      <Col sm={8}>
                        {eob.insurance?.map((insuranceItem: any, index: number) =>
                          insuranceItem.focal && (
                            <div key={`eob-insurance-${index}`} className='mb-3'>
                              {insuranceItem.coverage?.reference}
                            </div>
                          )
                        )}
                      </Col>
                    </ShowField>
                    <ShowField field='item'>
                      <Col sm={4}>
                        <dt>{config.item.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <EobItem patientEob={eob} />
                      </Col>
                    </ShowField>
                    <ShowField field='adjudication'>
                      <Col sm={4}>
                        <dt>{config.adjudication.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <AdjudicationItem adjudications={eob.adjudication} />
                      </Col>
                    </ShowField>
                    <ShowField field='addItem'>
                      <Col sm={4}>
                        <dt>{config.addItem.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <EobAddItem addItems={eob.addItem} />
                      </Col>
                    </ShowField>
                    <ShowField field='total'>
                      <Col sm={4}>
                        <dt>{config.total.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <EobTotal total={eob.total} />
                      </Col>
                    </ShowField>
                    <ShowField field='processNote'>
                      <Col sm={4}>
                        <dt>{config.processNote.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <div>
                          {eob.processNote?.map((note: any, index: number) => (
                            <div key={`process-note-${index}`}>
                              <dd><Simple simple={note.text} /></dd>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </ShowField>
                    <ShowField field='payment'>
                      {
                        appConfig.use_eob_member_responsibility && (
                          <>
                            <Col sm={4}>
                              <dt>{config.payment.label}</dt>
                            </Col>
                            <Col sm={8}>
                              <dd>{buildEOBPatientPayment(eob)}</dd>
                            </Col>
                          </>
                        )
                      }
                    </ShowField>
                    <ShowField field='text'>
                      <Col sm={4}>
                        <dt>{config.text.label}</dt>
                      </Col>
                      <Col>
                        <dd><Narrative data={eob.text} dataExtension={eob._text} /></dd>
                      </Col>
                    </ShowField>
                  </Row>
                  <div className='footer'>
                    <hr />
                    <h6>FHIR Resource Metadata</h6>
                    <Row>
                      <ShowField field='resourceType'>
                        <Col sm={4}>
                          <dt>{config.resourceType.label}</dt>
                        </Col>
                        <Col sm={8}>
                          <dd>{<ResourceType resourceType={eob.resourceType} />}</dd>
                        </Col>
                      </ShowField>
                      <ShowField field='id'>
                        <Col sm={4}>
                          <dt>{config.id.label}</dt>
                        </Col>
                        <Col sm={8}>
                          <dd><Simple simple={eob.id} /></dd>
                        </Col>
                      </ShowField>
                      <ShowField field='meta'>
                        <Col sm={4}>
                          <dt>{config.meta.label}</dt>
                        </Col>
                        <Col sm={8}>
                          <dd><MetaLastUpdated data={eob.meta} dataExtension={eob._meta} /></dd>
                        </Col>
                      </ShowField>
                      <ShowField field='profile' subResource={eob.meta}>
                        <Col sm={4}>
                          <dt>{config.profile.label}</dt>
                        </Col>
                        <Col sm={8}>
                          <dd>{eob.meta?.profile?.join(', ')}</dd>
                        </Col>
                      </ShowField>
                      <ShowField field='implicitRules'>
                        <Col sm={4}>
                          <dt>{config.implicitRules.label}</dt>
                        </Col>
                        <Col sm={8}>
                          <dd><URI data={eob.implicitRules} dataExtension={eob._implicitRules} /></dd>
                        </Col>
                      </ShowField>
                      <ShowField field='language'>
                        <Col sm={4}>
                          <dt>{config.language.label}</dt>
                        </Col>
                        <Col sm={8}>
                          <dd><Code data={eob.language} dataExtension={eob._language} /></dd>
                        </Col>
                      </ShowField>
                    </Row>
                  </div>
                  <Row>
                    <Col sm={12}>
                      <ProvenanceDetail resourceName='ExplanationOfBenefit' resourceId={eobDetailId} />
                    </Col>
                  </Row>
                </dl>
              </div>
            </ResourceConfigProvider>
          ) : null
        }
      </dl>
    </>
  )
}

export default EobDetail