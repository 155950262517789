import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { handleReference, handleExtension } from '../../../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import { MedicationRequest } from '../../../../../types/FHIRTypes/MedicationRequest'
import ProvenanceDetail from '../../Provenance'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import { FieldConfig } from '../../../../../types/FieldConfig'
import Narrative from '../../../DataTypes/Narrative'
import Identifier from '../../../DataTypes/Identifier'
import CapitalizedText from '../../../DisplayComponents/CapitalizedText'
import Medication from '../../../DisplayComponents/Medication'
import MedDispenseRequest from '../../../DisplayComponents/MedDispenseRequest'
import Reference from '../../../DataTypes/Reference'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import ResourceType from '../../../DisplayComponents/ResourceType'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import Dosage from '../../../DataTypes/Dosage'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import DateTime from '../../../DataTypes/DateTime'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'

interface MedicationDetailProps {
    medicationDetailId: any;
    medicationData: any;
    isFetching: boolean;
    patientData: any
}

const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    medication: { label: 'Medication', visibility: 'always' },
    status: { label: 'Status', visibility: 'always' },
    statusReason: { label: 'Status Reason', visibility: 'conditional' },
    intent: { label: 'Intent Code', visibility: 'always' },
    category: { label: 'Category', visibility: 'conditional' },
    encounter: { label: 'Encounter', visibility: 'always' },
    authoredOn: { label: 'Date When Written', visibility: 'always' },
    requester: { label: 'Prescriber', visibility: 'always' },
    dosageInstruction: { label: 'Dosage Instruction', visibility: 'always' },
    dispenseRequest: { label: 'Dispense Request', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    //metadata
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
    reported: { label: 'Record Not Primary Source', visibility: 'conditional' },
}



const MedicationDetail: React.FC<MedicationDetailProps> = ({ medicationDetailId, medicationData, isFetching, patientData }) => {
    const [resource, setResource] = useState<MedicationRequest>({} as MedicationRequest)

    const history = useHistory()
    useEffect(() => {
        if (!medicationData) return

        const foundMedication = medicationData.entry.find((a: any) => a.resource.id === medicationDetailId)
        if (foundMedication) {
            setResource(foundMedication.resource)
        } else {
            history.push('/404')
        }
    }, [medicationData])

    if (isFetching) {
        return (
            <div>
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
                Loading Medication...
            </div>
        )
    }
    return (
        <>
            <dl className='dataContainer'>
                {resource && (
                    <ResourceConfigProvider config={config} resource={resource}>
                        <>
                            <Row>
                                <ShowField field='subject'>
                                    <Col sm={4}>
                                        <dt>{config.subject.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><PatientReference patient={resource.subject} apiType='Patient Access' /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='medication'>
                                    <Col sm={4}>
                                        <dt>{config.medication.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd>
                                            <Medication medication={resource} />
                                        </dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='status'>
                                    <Col sm={4}>
                                        <dt>{config.status.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='statusReason'>
                                    <Col sm={4}>
                                        <dt>{config.statusReason.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource.statusReason} dataExtension={resource._statusReason} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='intent'>
                                    <Col sm={4}>
                                        <dt>{config.intent.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.intent} dataExtension={resource._intent} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='category'>
                                    <Col sm={4}>
                                        <dt>{config.category.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><CodeableConcept data={resource.category} dataExtension={resource._category} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='encounter'>
                                    <Col sm={4}>
                                        <dt>{config.encounter.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Reference data={resource.encounter} dataExtension={resource._encounter} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='authoredOn'>
                                    <Col sm={4}>
                                        <dt>{config.authoredOn.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><DateTime data={resource.authoredOn} dataExtension={resource._authoredOn} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='requester'>
                                    <Col sm={4}>
                                        <dt>{config.requester.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd>
                                            {resource.requester?.reference
                                                ? handleReference(resource.requester)
                                                : resource.requester?.extension ? handleExtension(resource.requester.extension) : ''}
                                        </dd>

                                        <dd>{handleReference(resource.requester)}</dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='dosageInstruction'>
                                    <Col sm={4}>
                                        <dt>{config.dosageInstruction.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <Dosage data={resource.dosageInstruction} dataExtension={resource._dosageInstruction} />
                                    </Col>
                                </ShowField>

                                <ShowField field='dispenseRequest'>
                                    <Col sm={4}>
                                        <dt>{config.dispenseRequest.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><MedDispenseRequest medDispenseRequest={resource} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='text'>
                                    <Col sm={4}>
                                        <dt>{config.text.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                                    </Col>
                                </ShowField>
                                <Col sm={12} className='footer'>
                                    <hr />
                                    <h6>FHIR Resource Metadata</h6>
                                    <Row>
                                        <ShowField field='resourceType'>
                                            <Col sm={4}>
                                                <dt>{config.resourceType.label}</dt>
                                            </Col>
                                            <Col sm={8}>
                                                <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                                            </Col>
                                        </ShowField>
                                        <ShowField field='id'>
                                            <Col sm={4}>
                                                <dt>{config.id.label}</dt>
                                            </Col>
                                            <Col sm={8}>
                                                <dd><StringDisplay data={resource?.id} dataExtension={resource?._id} /></dd>
                                            </Col>
                                        </ShowField>
                                        <ShowField field='meta'>
                                            <Col sm={4}>
                                                <dt>{config.meta.label}</dt>
                                            </Col>
                                            <Col sm={8}>
                                                <dd><MetaLastUpdated data={resource.meta} dataExtension={resource._meta} /></dd>
                                            </Col>
                                        </ShowField>
                                        <ShowField field='profile' subResource={resource.meta}>
                                            <Col sm={4}>
                                                <dt>{config.profile.label}</dt>
                                            </Col>
                                            <Col sm={8}>
                                                <dd>
                                                    {<Profile profile={resource.meta?.profile} />}
                                                </dd>
                                            </Col>
                                        </ShowField>
                                        <ShowField field='language'>
                                            <Col sm={4}>
                                                <dt>{config.language.label}</dt>
                                            </Col>
                                            <Col sm={8}>
                                                <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                            </Col>
                                        </ShowField>
                                        <ShowField field='implicitRules'>
                                            <Col sm={4}>
                                                <dt>{config.implicitRules.label}</dt>
                                            </Col>
                                            <Col sm={8}>
                                                <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                            </Col>
                                        </ShowField>
                                        <ShowField field='identifier'>
                                            <Col sm={4}>
                                                <dt>{config.identifier.label}</dt>
                                            </Col>
                                            <Col sm={8}>
                                                <dd><Identifier data={resource.identifier} dataExtension={resource._identifier} /></dd>
                                            </Col >
                                        </ShowField >
                                        <ShowField field='reported'>
                                            <Col sm={4}>
                                                <dt>{config.reported.label}</dt>
                                            </Col>
                                            <Col sm={8}>
                                                <dd>
                                                    <CapitalizedText
                                                        text={resource.reportedBoolean !== undefined ? resource.reportedBoolean.toString() : resource.reportedReference?.reference ?? ''}
                                                    />
                                                </dd>
                                            </Col>
                                        </ShowField>
                                    </Row >
                                </Col >
                                <Col sm={12}>
                                    <ProvenanceDetail resourceName='MedicationRequest' resourceId={medicationDetailId} />
                                </Col>
                            </Row >
                        </>
                    </ResourceConfigProvider>
                )}
            </dl >
        </>
    )
}

export default MedicationDetail
