import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { buildCoverageMemberID, calculatedStatus, sortCoveragesByStartDate } from '../../../../utils/fhirUtils/coverage'
import { handleReference } from '../../../../utils/helpers'
import { Coverage as CoverageType } from '../../../../types/FHIRTypes/Coverage'
import ProvenanceDetail from '../Provenance'
import useEnvironment from '../../../../hooks/location/useEnviroment'
import { FieldConfig } from '../../../../types/FieldConfig'
import Identifier from '../../DataTypes/Identifier'
import Narrative from '../../DataTypes/Narrative'
import MetaLastUpdated from '../../DisplayComponents/MetaLastUpdated'
import ShowField from '../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../FhirResourceConfigProvider'
import Period from '../../DataTypes/Period'
import PatientReference from '../../DisplayComponents/PatientReference'
import CoverageClass from '../../DisplayComponents/CoverageClass'
import ResourceType from '../../DisplayComponents/ResourceType'
import Profile from '../../DisplayComponents/Profile'
import StringDisplay from '../../DataTypes/String'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Code from '../../DataTypes/Code'
import URI from '../../DataTypes/URI'
import OrganizationReference from '../../DisplayComponents/OrganizationReference'

interface Props {
  coverageData: { entry: { resource: CoverageType }[] };
  patientData: any;
  isFetching: boolean;
}

const config: FieldConfig = {
  // calculated fields
  calculatedStatus: { label: 'Coverage Status', visibility: 'always' },
  calculatedMemberID: { label: 'Member ID', visibility: 'never' },
  // main fields
  identifier: { label: 'Coverage Identifier', visibility: 'always' },
  type: { label: 'Coverage Type', visibility: 'conditional' },
  policyHolder: { label: 'Policy Holder', visibility: 'conditional' },
  subscriber: { label: 'Subscriber', visibility: 'conditional' },
  subscriberId: { label: 'Subscriber ID', visibility: 'always' },
  beneficiary: { label: 'Beneficiary', visibility: 'always' },
  relationship: { label: 'Relationship', visibility: 'always' },
  period: { label: 'Period', visibility: 'always' },
  payor: { label: 'Payor', visibility: 'always' },
  class: { label: 'Coverage Classification', visibility: 'always' },
  network: { label: 'Network', visibility: 'conditional' },
  text: { label: 'Coverage Summary', visibility: 'conditional' },
  dependent: { label: 'Dependent', visibility: 'never' },
  order: { label: 'Order', visibility: 'never' },
  costToBeneficiary: { label: 'Cost To Beneficiary', visibility: 'never' },
  subrogation: { label: 'Subrogation', visibility: 'never' },
  contract: { label: 'Contract', visibility: 'never' },
  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'always' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  status: { label: 'Resource Status', visibility: 'always' },
}

const Coverage: FC<Props> = ({ coverageData, patientData, isFetching }: Props) => {
  const { data: environmentData } = useEnvironment()
  const patientCoverages = coverageData?.entry?.map((entry) => entry.resource) || []
  const sortedPatientCoverages = sortCoveragesByStartDate(patientCoverages)

  return (
    <>
      {
        isFetching ? (
          <Row>
            <Col className='d-flex'>
              <Spinner
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
              />
              <span style={{ marginLeft: '10px' }}>Loading Member Coverage...</span>
            </Col>
          </Row>
        ) : (sortedPatientCoverages.length > 0) ? (
          sortedPatientCoverages.map((resource: CoverageType) => (
            <dl className='dataContainer' key={resource.id}>
              <ResourceConfigProvider config={config} resource={resource}>

                <Row>
                  <ShowField field='calculatedStatus'>
                    <Col sm={4}>
                      <dt>{config.calculatedStatus.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>{calculatedStatus(resource)}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='identifier'>
                    <Col sm={4}>
                      <dt>{config.identifier.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><Identifier data={resource.identifier} dataExtension={resource._identifier} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='type'>
                    <Col sm={4}>
                      <dt>{config.type.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><CodeableConcept data={resource.type} dataExtension={resource._type} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='policyHolder'>
                    <Col sm={4}>
                      <dt>{config.policyHolder.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>
                        <PatientReference patient={resource.policyHolder} apiType='Patient Access' />
                      </dd>
                    </Col>
                  </ShowField>
                  <ShowField field='subscriber'>
                    <Col sm={4}>
                      <dt>{config.subscriber.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><PatientReference patient={resource.subscriber} apiType='Patient Access' /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='subscriberId'>
                    <Col sm={4}>
                      <dt>{config.subscriberId.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><StringDisplay data={resource.subscriberId} dataExtension={resource._subscriberId} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='beneficiary'>
                    <Col sm={4}>
                      <dt>{config.beneficiary.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><PatientReference patient={resource.beneficiary} apiType='Patient Access' /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='calculatedMemberID'>
                    <Col sm={4}>
                      <dt>{config.calculatedMemberID.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>{buildCoverageMemberID(resource, environmentData?.isSingleTenantServer)}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='relationship'>
                    <Col sm={4}>
                      <dt>{config.relationship.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><CodeableConcept data={resource.relationship} dataExtension={resource._relationship} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='period'>
                    <Col sm={4}>
                      <dt>{config.period.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><Period data={resource.period} dataExtension={resource._period} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='payor'>
                    <Col sm={4}>
                      <dt>{config.payor.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>
                        {
                          Array.isArray(resource.payor) ? (
                            resource.payor.map((payor, index) => (
                              <OrganizationReference key={index} organization={payor} apiType='Patient Access' />
                            ))
                          ) : <OrganizationReference organization={resource.payor} apiType='Patient Access' />
                        }
                      </dd>
                    </Col>
                  </ShowField>
                  <ShowField field='class'>
                    <Col sm={4}>
                      <dt>{config.class.label}</dt>
                    </Col>
                    <Col sm={8}>
                      {resource.class && resource.class.length > 0 ? (
                        <CoverageClass coverageClass={resource.class} />
                      ) : (
                        <dd>N/A</dd>
                      )}
                    </Col>
                  </ShowField>
                  <ShowField field='network'>
                    <Col sm={4}>
                      <dt>{config.network.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><StringDisplay data={resource.network} dataExtension={resource._network} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='text'>
                    <Col sm={4}>
                      <dt>{config.text.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='dependent'>
                    <Col sm={4}>
                      <dt>{config.dependent.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>{resource.dependent}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='order'>
                    <Col sm={4}>
                      <dt>{config.order.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>{resource.order}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='costToBeneficiary'>
                    <Col sm={4}>
                      <dt>{config.costToBeneficiary.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>{handleReference(resource.beneficiary)}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='subrogation'>
                    <Col sm={4}>
                      <dt>{config.subrogation.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>{resource.subrogation ? 'Yes' : 'No'}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='contract'>
                    <Col sm={4}>
                      <dt>{config.contract.label}</dt>
                    </Col>
                    <Col sm={8}>
                      <dd>{handleReference(resource.contract)}</dd>
                    </Col>
                  </ShowField>
                </Row >

                <div className='footer'>

                  <hr />
                  <h6>FHIR Resource Metadata</h6>
                  <Row>
                    <ShowField field='resourceType'>
                      <Col sm={4}>
                        <dt>{config.resourceType.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                      </Col>
                    </ShowField>
                    <ShowField field='id'>
                      <Col sm={4}>
                        <dt>{config.id.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='meta'>
                      <Col sm={4}>
                        <dt>{config.meta.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><MetaLastUpdated data={resource.meta} dataExtension={resource._meta} /></dd>
                      </Col >
                    </ShowField >
                    <ShowField field='profile' subResource={resource.meta}>
                      <Col sm={4}>
                        <dt>{config.profile.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Profile profile={resource.meta?.profile} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='language'>
                      <Col sm={4}>
                        <dt>{config.language.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='implicitRules'>
                      <Col sm={4}>
                        <dt>{config.implicitRules.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='status'>
                      <Col sm={4}>
                        <dt>{config.status.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
                      </Col>
                    </ShowField>
                  </Row >
                </div >
                <Row>
                  <Col sm={12}>
                    <ProvenanceDetail resourceName='Coverage' resourceId={resource.id} />
                  </Col>
                </Row>

              </ResourceConfigProvider>
            </dl >
          ))
        ) :
          <Row>
            <Col className='text-center'>
              No Coverage found for this member.
            </Col>
          </Row>
      }
    </>
  )
}

export default Coverage