import React from 'react'
import { InsPlan } from '../../../../types/FHIRTypes/InsurancePlan'
import { CodeableConcept as FHIRCodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'
import CodeableConcept from '../../DataTypes/CodeableConcept'

interface PlanCostShareProps {
  data?: InsPlan | InsPlan[] | null
  dataExtension?: FHIRCodeableConcept | FHIRCodeableConcept[] | null
}

const InsPlanCostShare: React.FC<PlanCostShareProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (!data && dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

  const plans = Array.isArray(data) ? data : [data]

  return (
    <div>
      {plans?.map((plan, index) => (
        <div key={index}>
          <CodeableConcept data={plan?.type} dataExtension={plan?._type} />
        </div>
      ))}
    </div>
  )
}

export default InsPlanCostShare