import React, { FC, useState, useEffect } from 'react'
import { appConfig } from '../../../assets/customizations/config'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import isAuthenticated from '../../../routes/PrivateRoute'

import Eobs from '../../../components/FhirData/FhirResources/Eobs'
import Immunizations from '../../../components/FhirData/FhirResources/Immunizations'
import MemberProfile from '../../../components/FhirData/FhirResources/MemberProfile'
import Observations from '../../../components/FhirData/FhirResources/Observations'
import Coverage from '../../../components/FhirData/FhirResources/Coverage'
import Conditions from '../../../components/FhirData/FhirResources/Conditions'
import Allergies from '../../../components/FhirData/FhirResources/Allergies'
import Medications from '../../../components/FhirData/FhirResources/Medications'
import Procedure from '../../../components/FhirData/FhirResources/Procedure'
import CarePlans from '../../../components/FhirData/FhirResources/CarePlans'
import CareTeams from '../../../components/FhirData/FhirResources/CareTeams'
import Encounters from '../../../components/FhirData/FhirResources/Encounters'
import DiagnosticReport from '../../../components/FhirData/FhirResources/DiagnosticReport'
import DigitalIdCard from '../../../components/FhirData/FhirResources/DigitalIdCard'

import useUserWithRole from '../../../hooks/authentication/useUserWithRole'
import { useGlobalState } from '../../../hooks/store/useGlobalState'
import useFhirResource from '../../../hooks/admin/useFhirResource'
import usePaginatedFhirResource from '../../../hooks/admin/usePaginatedFhirResource'
import useEnvironment from '../../../hooks/location/useEnviroment'
import { scrollToTop } from '../../../utils/helpers'

import '../../../App.scss'
import HumanName from '../../../components/FhirData/DataTypes/HumanName'
import IPS from '../../../components/FhirData/FhirResources/IPS'

interface RouteParams {
  page: string | undefined;
  detailId: string | undefined;
}

const getKeys = () => {
  const toolsKeys = [
    { label: 'Digital ID Card', resource: 'DigitalIdCard', show: true },
    { label: 'Patient Summary (IPS)', resource: 'IPS', show: true }
  ]
  const memberKeys = [
    { label: 'Member Profile', resource: 'Patient', show: true },
    { label: 'Coverage', resource: 'Coverage', show: true },
    { label: 'EOBs', resource: 'ExplanationOfBenefit', show: true, longLabel: 'Explanation of Benefits (EOBs)' },
  ]
  const clinicalKeys = appConfig.clinical_fhir_data.filter((data) => data.show)
  const allKeys: {
    label: string;
    resource: string;
    show?: boolean;
    longLabel?: string
  }[] = [...toolsKeys, ...memberKeys, ...clinicalKeys]

  const keyResourceMap = allKeys.reduce((acc, { label, resource }) => {
    acc[label] = resource
    return acc
  }, {} as Record<string, string>)

  return { toolsKeys, memberKeys, clinicalKeys, allKeys, keyResourceMap }
}

const Member: FC = () => {
  const { data: environmentData } = useEnvironment()
  const fhirId = useGlobalState((state) => state.fhirId)

  const { toolsKeys, memberKeys, clinicalKeys, allKeys: keys } = getKeys()
  const [key, setKey] = useState('Member Profile')

  const { page, detailId } = useParams<RouteParams>()
  const history = useHistory()

  const {
    data: eobData,
    refetch: getPatientEobs,
    isStale: eobsIsStale,
    isFetching: fetchingEobs,
    handleChangePage: handleChangeEobPage,
    totalPages: eobsTotalPages,
    currentPage: eobsCurrentPage
  } = usePaginatedFhirResource(fhirId, 'ExplanationOfBenefit', 25)


  const {
    data: allergiesData,
    refetch: getPatientAllergy,
    isStale: allergyIsStale,
    isFetching: fetchingAllergy,
    handleChangePage: handleChangeAllergyPage,
    totalPages: allergyTotalPages,
    currentPage: allergyCurrentPage
  } = usePaginatedFhirResource(fhirId, 'AllergyIntolerance', 25)

  const {
    data: conditionData,
    refetch: getPatientCondtions,
    isStale: condtionsIsStale,
    isFetching: fetchingCondtions,
    handleChangePage: handleChangeConditionPage,
    totalPages: conditionTotalPages,
    currentPage: conditionCurrentPage
  } = usePaginatedFhirResource(fhirId, 'Condition', 25)

  const {
    data: encounterData,
    refetch: getPatientEncounters,
    isStale: encountersIsStale,
    isFetching: fetchingEncounters,
    handleChangePage: handleChangeEncounterPage,
    totalPages: encounterTotalPages,
    currentPage: encounterCurrentPage
  } = usePaginatedFhirResource(fhirId, 'Encounter', 25)

  const {
    data: medicationData,
    refetch: getMedicationRequest,
    isStale: medicationIsStale,
    isFetching: fetchingMedication,
    handleChangePage: handleChangeMedication,
    totalPages: medicationRequestTotalPages,
    currentPage: medicationRequestCurrentPage
  } = usePaginatedFhirResource(fhirId, 'MedicationRequest', 25)

  const {
    data: observationData,
    refetch: getObservations,
    isStale: observationIsStale,
    isFetching: fetchingObservation,
    handleChangePage: handleChangeObservation,
    totalPages: observationTotalPages,
    currentPage: observationCurrentPage
  } = usePaginatedFhirResource(fhirId, 'Observation', 25)

  const {
    data: immunizationData,
    refetch: getImmunizations,
    isStale: immunizationIsStale,
    isFetching: fetchingImmunization,
    handleChangePage: handleChangeImmunization,
    totalPages: immunizationsTotalPages,
    currentPage: immunizationsCurrentPage
  } = usePaginatedFhirResource(fhirId, 'Immunization', 25)


  const {
    data: procedureData,
    refetch: getProcedures,
    isStale: procedureIsStale,
    isFetching: fetchingProcedure,
    handleChangePage: handleChangeProcedure,
    totalPages: procedureTotalPages,
    currentPage: procedureCurrentPage
  } = usePaginatedFhirResource(fhirId, 'Procedure', 25)

  const {
    data: diagnosticReportData,
    refetch: getDiagnosticReports,
    isStale: diagnosticReportIsStale,
    isFetching: fetchingDiagnosticReport,
    handleChangePage: handleChangeDiagnosticReport,
    totalPages: diagnosticReportTotalPages,
    currentPage: diagnosticReportCurrentPage
  } = usePaginatedFhirResource(fhirId, 'DiagnosticReport', 25)

  const {
    data: carePlanData,
    refetch: getCarePlans,
    isStale: carePlansIsStale,
    isFetching: fetchingCarePlans,
    handleChangePage: handleChangeCarePlanPage,
    totalPages: carePlanTotalPages,
    currentPage: carePlanCurrentPage
  } = usePaginatedFhirResource(fhirId, 'CarePlan', 25)

  const {
    data: careTeamData,
    refetch: getCareTeams,
    isStale: careTeamsIsStale,
    isFetching: fetchingCareTeams,
    handleChangePage: handleChangeCareTeamPage,
    totalPages: careTeamTotalPages,
    currentPage: careTeamCurrentPage,
  } = usePaginatedFhirResource(fhirId, 'CareTeam', 25)

  const fhirResourcesConfig = [
    { key: 'patientData', resourceType: 'Patient' },
    { key: 'coverageData', resourceType: 'Coverage', count: 25 },
  ]

  const fhirResources = fhirResourcesConfig.map(({ key, resourceType, count }) => {
    const { data, refetch, isStale, isFetching, isLoading } = useFhirResource(fhirId, resourceType, count)
    return { key, data, refetch, isStale, isFetching, isLoading }
  })

  const {
    patientData,
    coverageData,
  } = fhirResources.reduce((acc, { key, data, refetch, isStale, isFetching, isLoading }) => {
    acc[key] = { data, refetch, isStale, isFetching, isLoading }
    return acc
  }, {} as Record<string, any>)

  const { user, role } = useUserWithRole()

  useEffect(() => {
    patientData.refetch()
    coverageData.refetch()
  }, [])

  useEffect(() => {
    const fetchMap: Record<string, { isStale: boolean; fetchFunction: () => void }> = {
      AllergyIntolerance: { isStale: allergyIsStale, fetchFunction: getPatientAllergy },
      Condition: { isStale: condtionsIsStale, fetchFunction: getPatientCondtions },
      ExplanationOfBenefit: { isStale: eobsIsStale, fetchFunction: getPatientEobs },
      Immunization: { isStale: immunizationIsStale, fetchFunction: getImmunizations },
      Observation: { isStale: observationIsStale, fetchFunction: getObservations },
      MedicationRequest: { isStale: medicationIsStale, fetchFunction: getMedicationRequest },
      Procedure: { isStale: procedureIsStale, fetchFunction: getProcedures },
      Encounter: { isStale: encountersIsStale, fetchFunction: getPatientEncounters },
      CarePlan: { isStale: carePlansIsStale, fetchFunction: getCarePlans },
      CareTeam: { isStale: careTeamsIsStale, fetchFunction: getCareTeams },
      DiagnosticReport: { isStale: diagnosticReportIsStale, fetchFunction: getDiagnosticReports },
    }

    if (key in fetchMap) {
      const { isStale, fetchFunction } = fetchMap[key]

      if (isStale) {
        fetchFunction()
      }
    }
  }, [
    key,
    patientData.isStale,
    eobsIsStale,
    coverageData.isStale,
    condtionsIsStale,
    detailId,
    allergyIsStale,
    procedureIsStale,
    medicationIsStale,
    immunizationIsStale,
    observationIsStale,
    encountersIsStale,
    carePlansIsStale,
    condtionsIsStale,
    careTeamsIsStale,
    diagnosticReportIsStale,
  ])

  useEffect(() => {
    if (page) {
      const key = keys.find((k) => k.resource === page)
      if (key) {
        setKey(key.resource)
      } else {
        history.replace('/404')
      }
    } else {
      setKey('Patient')
    }
    scrollToTop()
  }, [page])

  useEffect(() => {
    if (detailId) {
      if (key === 'Coverage') {
        history.replace('/404')
      }

      scrollToTop()
    }
  }, [key, detailId])

  const handleChangeKey = (k: string | null) => {
    const key = k || ''
    setKey(key)

    if (key) {
      const path = key === 'Patient' ? '/patient' : `/patient/${key}`
      history.push(path)
    }

    scrollToTop()
  }

  useEffect(() => {
    if (role === 'member') {
      if (fhirId !== user.memberId) {
        history.replace('/404')
      }
    }
  }, [fhirId, role])

  const renderFhirDataResource = (resource: string) => {
    switch (resource) {
      case 'DigitalIdCard':
        return (
          <DigitalIdCard
            coverageData={coverageData?.data}
            patientData={patientData?.data}
            isFetching={coverageData?.isLoading}
          />
        )
      case 'IPS':
        return (
          <IPS patientData={patientData.data} />
        )
      case 'Patient':
        return (
          <MemberProfile
            patientData={patientData?.data}
            loading={patientData?.isLoading || coverageData?.isLoading}
            coverageData={coverageData?.data}
          />
        )
      case 'Coverage':
        return (
          <Coverage
            patientData={patientData?.data}
            coverageData={coverageData?.data}
            isFetching={coverageData?.isFetching}
          />
        )
      case 'ExplanationOfBenefit':
        return (
          <Eobs
            patientData={patientData?.data}
            eobData={eobData}
            eobDetailId={detailId}
            page={page}
            isFetching={fetchingEobs}
            totalPages={eobsTotalPages}
            currentPage={eobsCurrentPage}
            handleChangePage={handleChangeEobPage}
          />
        )
      case 'AllergyIntolerance':
        return (
          <Allergies
            patientData={patientData?.data}
            allergyData={allergiesData}
            isFetching={fetchingAllergy}
            allergyDetailId={detailId}
            page={page}
            totalPages={allergyTotalPages}
            currentPage={allergyCurrentPage}
            handleChangePage={handleChangeAllergyPage}
          />
        )
      case 'Condition':
        return (
          <Conditions
            conditionData={conditionData}
            isFetching={fetchingCondtions}
            patientData={patientData?.data}
            conditionDetailId={detailId}
            page={page}
            totalPages={conditionTotalPages}
            currentPage={conditionCurrentPage}
            handleChangePage={handleChangeConditionPage}
          />
        )
      case 'Immunization':
        return (
          <Immunizations
            patientData={patientData?.data}
            immunizationData={immunizationData}
            immunizationDetailId={detailId}
            page={page}
            isFetching={fetchingImmunization}
            totalPages={immunizationsTotalPages}
            currentPage={immunizationsCurrentPage}
            handleChangePage={handleChangeImmunization}
          />
        )
      case 'Observation':
        return (
          <Observations
            patientData={patientData?.data}
            observationData={observationData}
            observationDetailId={detailId}
            page={page}
            isFetching={fetchingObservation}
            totalPages={observationTotalPages}
            currentPage={observationCurrentPage}
            handleChangePage={handleChangeObservation}
          />
        )
      case 'MedicationRequest':
        return (
          <Medications
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingMedication}
            medicationData={medicationData}
            medicationDetailId={detailId}
            handleChangePage={handleChangeMedication}
            totalPages={medicationRequestTotalPages}
            currentPage={medicationRequestCurrentPage}
          />
        )
      case 'Procedure':
        return (
          <Procedure
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingProcedure}
            procedureData={procedureData}
            procedureDetailId={detailId}
            handleChangePage={handleChangeProcedure}
            totalPages={procedureTotalPages}
            currentPage={procedureCurrentPage}
          />
        )
      case 'Encounter':
        return (
          <Encounters
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingEncounters}
            encounterData={encounterData}
            encounterDetailId={detailId}
            handleChangePage={handleChangeEncounterPage}
            totalPages={encounterTotalPages}
            currentPage={encounterCurrentPage}
          />
        )
      case 'CarePlan':
        return (
          <CarePlans
            patientData={patientData?.data}
            page={page}
            clientCarePlanType={environmentData?.clientCarePlanType.toLocaleUpperCase()}
            isFetching={fetchingCarePlans}
            carePlanData={carePlanData}
            carePlanDetailId={detailId}
            handleChangePage={handleChangeCarePlanPage}
            totalPages={carePlanTotalPages}
            currentPage={carePlanCurrentPage}
          />
        )
      case 'CareTeam':
        return (
          <CareTeams
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingCareTeams}
            careTeamData={careTeamData}
            careTeamDetailId={detailId}
            handleChangePage={handleChangeCareTeamPage}
            totalPages={careTeamTotalPages}
            currentPage={careTeamCurrentPage}
          />
        )

      case 'DiagnosticReport':
        return (
          <DiagnosticReport
            page={page}
            isFetching={fetchingDiagnosticReport}
            diagnosticReportData={diagnosticReportData}
            diagnosticReportDetailId={detailId}
            handleChangePage={handleChangeDiagnosticReport}
            totalPages={diagnosticReportTotalPages}
            currentPage={diagnosticReportCurrentPage}
          />
        )
      default:
        return <div>Not a supported Portal Resource</div>
    }
  }

  return (
    <main className='fhirData'>
      <Container>
        <Tab.Container activeKey={key} onSelect={handleChangeKey} transition={false}>
          <h2 className='font-weight-bold mb-1'><HumanName data={patientData?.data?.name?.[0]} dataExtension={patientData?.data?._name} /></h2>
          <Row>
            <Col sm={3}>
              <Nav variant='pills' className='flex-column'>
                {environmentData?.smileTenant === 'DEMO' && (
                  <>
                    <p className='text-uppercase font-weight-bold mt-3 mb-2 section-header'>Tools</p>
                    {toolsKeys.map((key) => (
                      <Nav.Item className='patient-pill' key={`nav-item-${key.resource}`}>
                        <Nav.Link eventKey={key.resource}>{key.label}</Nav.Link>
                      </Nav.Item>
                    ))}
                  </>
                )}
                <p className='text-uppercase font-weight-bold mt-3 mb-2 section-header'>Member Data</p>
                {
                  [...memberKeys, ...clinicalKeys].map((key) => (
                    <Nav.Item className='patient-pill' key={`nav-item-${key.resource}`}>
                      <Nav.Link eventKey={key.resource}>{key.label}</Nav.Link>
                    </Nav.Item>
                  ))
                }
              </Nav>
            </Col>
            <Col sm={9} className='mt-sm-0 mt-3'>
              <Tab.Content>
                {
                  keys.map((key) => (
                    <Tab.Pane eventKey={key.resource} key={`tab-pane-${key.resource}`}>
                      <h3 className='font-weight-bold'>{key.longLabel || key.label}</h3>
                      {!toolsKeys.some(tool => tool.resource === key.resource) && (
                        <h4><HumanName data={patientData?.data?.name?.[0]} dataExtension={patientData?.data?._name} /></h4>
                      )}
                      {
                        renderFhirDataResource(key.resource)
                      }
                    </Tab.Pane>
                  ))
                }
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </main>
  )
}

export default isAuthenticated(Member)
