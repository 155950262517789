import React from 'react'
import { Button, Form, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import { InsurancePlan } from '../../../../../types/FHIRTypes/InsurancePlan'
import useEnvironment from '../../../../../hooks/location/useEnviroment'
import Code from '../../../DataTypes/Code'
import { ProviderResponse } from '../../../../../types/FHIRTypes/ProviderResponse'
import Period from '../../../DataTypes/Period'

interface InsurancePlanListProps {
    insurancePlans: ProviderResponse<InsurancePlan>
    isFetching: boolean
    isLoading: boolean
    isSuccess: boolean
    totalPages: number;
    currentPage: number;
    handleChangePage: (page: number | string) => void
    clickViewDataLink: (insurancePlan: InsurancePlan) => void
    searchProviderResource: { searchTerms: string, resource: string }
    clearForm: () => void
    newSearch: () => void
    updateSearchTerms: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InsurancePlanList: React.FC<InsurancePlanListProps> = ({
    insurancePlans,
    clickViewDataLink,
    isFetching,
    isLoading,
    isSuccess,
    totalPages,
    currentPage,
    handleChangePage,
    searchProviderResource,
    clearForm,
    newSearch,
    updateSearchTerms
}) => {

    const handleNewSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        newSearch()
    }
    const { data: environment } = useEnvironment()

    return (
        <>
            <h3>Insurance Plans</h3>
            {/* <Form noValidate className='form-inline' onSubmit={handleNewSearch}>
                    <Form.Group>
                        <Form.Label style={{ margin: '0 1em 0 0' }}>Search by Insurance Plan Name</Form.Label>
                        <Form.Control
                            style={{ margin: '0 1em 0 0' }}
                            type='text'
                            name='keyword'
                            placeholder="Enter Search Terms"
                            value={searchProviderResource.searchTerms}
                            onChange={updateSearchTerms}
                        />
                    </Form.Group>
                    <Button className='searchBtn mr-2' type='submit'>
                        Search
                    </Button>
                    <Button className='outline-searchBtn' onClick={clearForm}>
                        Clear
                    </Button>
                </Form>
                <hr /> */}
              <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                {insurancePlans && insurancePlans?.data.length > 0 && (
                     <tr>
                     <td scope="row" colSpan={4}>
                         <PaginationButtons
                             totalPages={totalPages}
                             currentPage={currentPage}
                             handleChangePage={handleChangePage}
                         />
                     </td>
                 </tr>
                )}
                {insurancePlans && insurancePlans.total > 0 && environment?.includeTotalInFHIRRequest && (
                    <span className='d-md-block'>
                        {(isLoading || isFetching)
                            ? <Spinner animation="border" size="sm" />
                            : `${insurancePlans.total.toLocaleString()} record(s) returned`
                        }
                    </span>
                )}
            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='name-column'>Name</th>
                        <th className='type-column'>Period</th>
                        <th className='status-column'>Status</th>
                        <th className='active-column'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading || isFetching ? (
                        <tr>
                            <td colSpan={4} className="text-center">Loading...</td>
                        </tr>
                    ) : !insurancePlans?.data?.length ? (
                        <tr>
                            <td colSpan={4} className="text-center">No Insurance Plans Found.</td>
                        </tr>
                    ) : insurancePlans && isSuccess ? (
                        insurancePlans.data.map((insurancePlan: InsurancePlan) => (
                            <tr key={insurancePlan.id}>
                                <td>
                                    <Link to='#' onClick={() => clickViewDataLink(insurancePlan)}>
                                        {insurancePlan.name}
                                    </Link>
                                </td>
                                <td>
                                    <Period data={insurancePlan.period} dataExtension={insurancePlan._period} />
                                </td>
                                <td>
                                    <Code data={insurancePlan.status} dataExtension={insurancePlan._status} />
                                </td>
                                <td className='d-none d-lg-table-cell'>
                                    <Link to='#' onClick={() => clickViewDataLink(insurancePlan)}>Detail</Link>
                                </td>
                            </tr>
                        ))) : null}
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                        {
                            insurancePlans && insurancePlans?.data.length > 0 && (
                                <div className="d-flex justify-content mt-3">
                                        <PaginationButtons
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            handleChangePage={handleChangePage}
                                        />
                                </div>
                            )
                        }
                    </div>
                </tfoot>
            </Table>
        </>
    )
}

export default InsurancePlanList