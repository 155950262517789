import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { GlobalState } from '../../types/GlobalState'
import { Organization } from '../../types/FHIRTypes/Organization'
import { OrganizationAffiliation } from '../../types/FHIRTypes/OrganizationAffiliation'
import { Practitioner } from '../../types/FHIRTypes/Practitioner'
import { InsurancePlan } from '../../types/FHIRTypes/InsurancePlan'
import { Location } from '../../types/FHIRTypes/Location'
import { PractitionerRole } from '../../types/FHIRTypes/PractitionerRole'

export const useGlobalState = create<GlobalState>()(
    persist(
        (set) => ({
            fhirId: '',
            setFhirId: (fhirId: string) => set(() => ({ fhirId })),
            providerResource: 'Organization',
            setProviderResource: (providerResource: string) => set(() => ({ providerResource })),
            providerSearchTerms: '',
            setProviderSearchTerms: (providerSearchTerms: string) => set(() => ({ providerSearchTerms })),
            provider: null,
            setProvider: (provider: Practitioner | null) => set(() => ({ provider })),
            sessionTimeOut: false,
            setSessionTimeOut: (sessionTimeOut: boolean) => set(() => ({ sessionTimeOut })),
            organization: null,
            setOrganization: (organization: Organization | null) => set(() => ({ organization })),
            organizationAffiliation: null,
            setOrganizationAffiliation: (organizationAffiliation: OrganizationAffiliation | null) => set(() => ({ organizationAffiliation })),
            insurancePlan: null,
            setInsurancePlan: (insurancePlan: InsurancePlan | null) => set(() => ({ insurancePlan })),
            location: null,
            setLocation: (location: Location | null) => set(() => ({ location })),
            practitionerRole: null,
            setPractitionerRole: (practitionerRole: PractitionerRole | null) => set(() => ({ practitionerRole })),
        }),
        {
            name: 'global-state-storage',
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => ({
                fhirId: state.fhirId,
                providerResource: state.providerResource,
                provider: state.provider,
                organization: state.organization,
                organizationAffiliation: state.organizationAffiliation,
                insurancePlan: state.insurancePlan,
                location: state.location,
                practitionerRole: state.practitionerRole
            })
        }
    )
)