import React, { FC, useEffect } from 'react'
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap'
import ChangePassword from '../../../components/Member/ChangePassword'
import useChangePassword from '../../../hooks/authentication/useChangePassword'
import useUserWithRole from '../../../hooks/authentication/useUserWithRole'
import isAuthenticated from '../../../routes/PrivateRoute'
import useAccount from '../../../hooks/admin/useAccount'
import useEnvironment from '../../../hooks/location/useEnviroment'
import DataConsentDemo from '../DataConsent/DEMO'
import DataConsentLive from '../DataConsent/Live'

const initialPasswordValues = {
  currentPassword: '',
  password: '',
  passwordAgain: ''
}

const initialAlertState = {
  show: false,
  variant: 'success',
  message: ''
}

const MemberAccount: FC = () => {
  const { data: environmentData } = useEnvironment()
  const { user, role } = useUserWithRole()
  const { data: accountInfo, refetch: refetchAccount } = useAccount(user?.pid)
  const userChangePassword = useChangePassword()
  const isDemo = environmentData?.smileTenant === 'DEMO' ? true : false


  useEffect(() => {
    if (role === 'member') {
      refetchAccount()
    }
  }, [role])

  return (
    <main className='content'>
      <Container>
        <Row>
          <Col sm={6}>
            <h2 className='headerText'>My Account</h2>
          </Col>
          <Col sm={12}>
            <section className='dataContainer'>
              {role === 'member' && (
                isDemo ? (
                  <Row>
                    <Col>
                      <DataConsentDemo />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <DataConsentLive />
                    </Col>
                  </Row>
                )
              )}
              <dl>
                <h6 style={{ fontWeight: 'bold', paddingTop: '7.5px', paddingBottom: '7.5px' }}>ACCOUNT DETAILS</h6>
                <Row>
                  <Col sm={3}>
                    <dt>Username</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {user.sub}
                    </dd>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <dt>Email</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {user?.email}
                    </dd>
                  </Col>
                </Row>
                {role === 'member' && (
                  <>
                    <Row>
                      <Col sm={3}>
                        <dt>Proxy</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {accountInfo?.isProxy ? 'Y' : 'N'}
                        </dd>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <dt>Proxy Name</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {accountInfo?.proxyFirstName || ''} {accountInfo?.proxyLastName || ''}
                        </dd>
                      </Col>
                    </Row>
                  </>
                )}
                <h6 style={{ fontWeight: 'bold', paddingTop: '14px', paddingBottom: '10px' }}>CHANGE PASSWORD</h6>
                <Accordion as={Row}>
                  <Col sm={3}>
                    <dt>Change Password</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      <Accordion.Toggle as={Button} eventKey='0' className='app-button'>
                        Change Password
                      </Accordion.Toggle>
                    </dd>
                  </Col>
                  <Col sm={12}>
                    <Accordion.Collapse eventKey="0">
                      <Container>
                        <ChangePassword
                          userId={user.sub}
                          changePassword={userChangePassword}
                          initialValues={initialPasswordValues}
                        />
                      </Container>
                    </Accordion.Collapse>
                  </Col>
                </Accordion>
              </dl>
            </section>
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default isAuthenticated(MemberAccount)