import React, { FC, useEffect, useState } from 'react'
import isAuthenticated from '../../routes/PrivateRoute'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useGlobalState } from '../../hooks/store/useGlobalState'
import { useHistory, useParams } from 'react-router-dom'
import { OrganizationDetail, OrganizationList } from '../../components/FhirData/FhirResources/Organization'
import { PractitionerDetail, PractitionerList } from '../../components/FhirData/FhirResources/Practitioner'
import { InsurancePlanDetail, InsurancePlanList } from '../../components/FhirData/FhirResources/InsurancePlan'
import { NetworkList } from '../../components/FhirData/FhirResources/Network'
import PermissionGate from '../../components/PermissionGate'
import useUserWithRole from '../../hooks/authentication/useUserWithRole'
import NetworkDetail from '../../components/FhirData/FhirResources/Network/NetworkDetail'
import { Organization } from '../../types/FHIRTypes/Organization'
import { Practitioner } from '../../types/FHIRTypes/Practitioner'
import { InsurancePlan } from '../../types/FHIRTypes/InsurancePlan'
import { scrollToTop } from '../../utils/helpers'
import usePaginatedProviderFhirResource from '../../hooks/admin/usePaginatedProviderResource'
import HealthcareServiceDetail from '../../components/FhirData/FhirResources/HealthcareService/HealthcareServiceDetail'
import HealthcareServiceList from '../../components/FhirData/FhirResources/HealthcareService/HealthcareServiceList'
import { HealthcareService } from '../../types/FHIRTypes/HealthcareService'
import { Location } from '../../types/FHIRTypes/Location'
import LocationDetail from '../../components/FhirData/FhirResources/Location/LocationDetail'
import LocationList from '../../components/FhirData/FhirResources/Location/LocationList'
import { OrganizationAffiliation } from '../../types/FHIRTypes/OrganizationAffiliation'
import OrganizationAffiliationDetail from '../../components/FhirData/FhirResources/OrganizationAffiliation/OrganizationAffiliationDetail'
import OrganizationAffiliationList from '../../components/FhirData/FhirResources/OrganizationAffiliation/OrganizationAffiliationList'
import PractitionerRoleDetail from '../../components/FhirData/FhirResources/PractitionerRole/PractitionerRoleDetail'
import PractitionerRoleList from '../../components/FhirData/FhirResources/PractitionerRole/PractitionerRoleList'
import { PractitionerRole } from '../../types/FHIRTypes/PractitionerRole'

interface RouteParams {
    resourceId: string | undefined
}

interface ResourceState<T> {
    data: T;
    isLoading: boolean;
    isSuccess: boolean;
    isFetching: boolean;
    remove: () => void;
    isError: boolean;
    fetchResources: () => void;
    newSearch: () => void;
}

interface ResourceMap {
    Location: ResourceState<any>;
    Organization: ResourceState<any>;
    OrganizationAffiliation: ResourceState<any>;
    Practitioner: ResourceState<any>;
    PractitionerRole: ResourceState<any>;
    Network: ResourceState<any>;
    HealthcareService: ResourceState<any>;
    InsurancePlan: ResourceState<any>;
}

/**
 *  NOTE: For now, only admins can view the provider directory. This will be updated in the future once it has gone through a redesign and is ready for public use. 
 *  As discussed by @tyler-gotz and @emilynorton and documented in JIRA ticket: https://teschglobal.atlassian.net/browse/PORTAL-723
 */
const ProviderList: FC = () => {

    //Extract state and setter functions from global state
    const {
        providerResource,
        setProviderResource,
        providerSearchTerms,
        setProviderSearchTerms,
        setProvider,
        setOrganization,
        setOrganizationAffiliation,
        setInsurancePlan,
        setPractitionerRole,
        provider,
        organization,
        organizationAffiliation,
        insurancePlan,
        practitionerRole,
        setLocation,
        location,
    } = useGlobalState((state) => ({
        providerResource: state.providerResource,
        providerSearchTerms: state.providerSearchTerms,
        setProviderSearchTerms: state.setProviderSearchTerms,
        setProvider: state.setProvider,
        setOrganization: state.setOrganization,
        setOrganizationAffiliation: state.setOrganizationAffiliation,
        setInsurancePlan: state.setInsurancePlan,
        setPractitionerRole: state.setPractitionerRole,
        provider: state.provider,
        organization: state.organization,
        organizationAffiliation: state.organizationAffiliation,
        insurancePlan: state.insurancePlan,
        practitionerRole: state.practitionerRole,
        setProviderResource: state.setProviderResource,
        location: state.location,
        setLocation: state.setLocation,
    }))

    const [key, setKey] = useState(providerResource)

    // Gets FHIR resourceID for detail from URL
    const { resourceId } = useParams<RouteParams>()
    const history = useHistory()

    const handleChangeKey = (k: string | null) => {
        setKey(k || '')
        setProviderResource(k || '')
        handleClearForm()
        newSearch()
        if (resourceId) {
            history.push('/provider-list')
        }
    }

    const { user } = useUserWithRole()

    // Fetch resources - Outline returned in usePaginatedProviderFhirResource
    const {
        refetch: fetchOrganizations,
        data: organizations,
        isLoading: isLoadingOrganizations,
        isSuccess: isSuccessOrganizations,
        isFetching: isFetchingOrganizations,
        remove: removeOrganizations,
        isError: isErrorOrganizations,
        totalPages: organizationTotalPages,
        currentPage: organizationCurrentPage,
        handleChangePage: handleChangeOrganization,
        handleNewSearch: handleNewSearchOrganizations
    } = usePaginatedProviderFhirResource({
        resource: 'Organization',
        searchTerms: providerSearchTerms,
    }, 25)

    const {
        refetch: fetchOrganizationAffiliations,
        data: organizationAffiliations,
        isLoading: isLoadingOrganizationAffiliations,
        isSuccess: isSuccessOrganizationAffiliations,
        isFetching: isFetchingOrganizationAffiliations,
        remove: removeOrganizationAffiliations,
        isError: isErrorOrganizationAffiliations,
        totalPages: organizationAffiliationTotalPages,
        currentPage: organizationAffiliationCurrentPage,
        handleChangePage: handleChangeOrganizationAffiliation,
        handleNewSearch: HandleNewSearchOrganizationAffiliation,
    } = usePaginatedProviderFhirResource({
        resource: 'OrganizationAffiliation',
        searchTerms: providerSearchTerms,
    }, 25)

    const {
        refetch: fetchPractitioners,
        data: practitioners,
        isLoading: isLoadingPractitioners,
        isSuccess: isSuccessPractitioners,
        isFetching: isFetchingPractitioners,
        remove: removePractitioners,
        isError: isErrorPractitioners,
        totalPages: practitionerTotalPages,
        currentPage: practitionerCurrentPage,
        handleChangePage: handleChangePractitioner,
        handleNewSearch: handleNewSearchPractitioners
    } = usePaginatedProviderFhirResource({
        resource: 'Practitioner',
        searchTerms: providerSearchTerms,
    }, 25)

    const {
        refetch: fetchPractitionersRole,
        data: practitionersRole,
        isLoading: isLoadingPractitionersRole,
        isSuccess: isSuccessPractitionersRole,
        isFetching: isFetchingPractitionersRole,
        remove: removePractitionersRole,
        isError: isErrorPractitionersRole,
        totalPages: practitionerRoleTotalPages,
        currentPage: practitionerRoleCurrentPage,
        handleChangePage: handleChangePractitionerRole,
        handleNewSearch: handleNewSearchPractitionersRole
    } = usePaginatedProviderFhirResource({
        resource: 'PractitionerRole',
        searchTerms: providerSearchTerms,
    }, 25)



    const {
        refetch: fetchLocations,
        data: locations,
        isLoading: isLoadingLocations,
        isSuccess: isSuccessLocations,
        isFetching: isFetchingLocations,
        remove: removeLocations,
        isError: isErrorLocations,
        totalPages: locationTotalPages,
        currentPage: locationCurrentPage,
        handleChangePage: handleChangeLocation,
        handleNewSearch: handleNewSearchLocations,
    } = usePaginatedProviderFhirResource({
        resource: 'Location',
        searchTerms: providerSearchTerms,
    }, 25)

    const {
        refetch: fetchNetworks,
        data: networks,
        isLoading: isLoadingNetworks,
        isSuccess: isSuccessNetworks,
        isFetching: isFetchingNetworks,
        remove: removeNetworks,
        isError: isErrorNetworks,
        totalPages: networkTotalPages,
        currentPage: networkCurrentPage,
        handleChangePage: handleChangeNetwork,
        handleNewSearch: handleNewSearchNetworks
    } = usePaginatedProviderFhirResource({
        resource: 'Network',
        searchTerms: providerSearchTerms,
    }, 25)

    const {
        refetch: fetchHealthcareService,
        data: healthcareService,
        isLoading: isLoadingHealthcareService,
        isSuccess: isSuccessHealthcareService,
        isFetching: isFetchingHealthcareService,
        remove: removeHealthcareService,
        isError: isErrorHealthcareService,
        totalPages: healthcareServiceTotalPages,
        currentPage: healthcareServiceCurrentPage,
        handleChangePage: handleChangeHealthcareService,
        handleNewSearch: handleNewSearchHealthcareService
    } = usePaginatedProviderFhirResource({
        resource: 'HealthcareService',
        searchTerms: providerSearchTerms,
    }, 25)

    const {
        refetch: fetchInsurancePlans,
        data: insurancePlans,
        isLoading: isLoadingInsurancePlans,
        isSuccess: isSuccessInsurancePlans,
        isFetching: isFetchingInsurancePlans,
        remove: removeInsurancePlans,
        isError: isErrorInsurancePlans,
        totalPages: insurancePlanTotalPages,
        currentPage: insurancePlanCurrentPage,
        handleChangePage: handleChangeInsurancePlan,
        handleNewSearch: handleNewSearchInsurancePlans
    } = usePaginatedProviderFhirResource({
        resource: 'InsurancePlan',
        searchTerms: providerSearchTerms,
    }, 25)

    const resourceMap: ResourceMap = {
        Organization: {
            data: organizations,
            isLoading: isLoadingOrganizations,
            isSuccess: isSuccessOrganizations,
            isFetching: isFetchingOrganizations,
            remove: removeOrganizations,
            isError: isErrorOrganizations,
            fetchResources: fetchOrganizations,
            newSearch: handleNewSearchOrganizations,
        },
        OrganizationAffiliation: {
            data: organizationAffiliations,
            isLoading: isLoadingOrganizationAffiliations,
            isSuccess: isSuccessOrganizationAffiliations,
            isFetching: isFetchingOrganizations,
            remove: removeOrganizationAffiliations,
            isError: isErrorOrganizationAffiliations,
            fetchResources: fetchOrganizationAffiliations,
            newSearch: HandleNewSearchOrganizationAffiliation,
        },

        Practitioner: {
            data: practitioners,
            isLoading: isLoadingPractitioners,
            isSuccess: isSuccessPractitioners,
            isFetching: isFetchingPractitioners,
            remove: removePractitioners,
            isError: isErrorPractitioners,
            fetchResources: fetchPractitioners,
            newSearch: handleNewSearchPractitioners,
        },
        PractitionerRole: {
            data: practitionersRole,
            isLoading: isLoadingPractitionersRole,
            isSuccess: isSuccessPractitionersRole,
            isFetching: isFetchingPractitionersRole,
            remove: removePractitionersRole,
            isError: isErrorPractitionersRole,
            fetchResources: fetchPractitionersRole,
            newSearch: handleNewSearchPractitionersRole,
        },
        Location: {
            data: locations,
            isLoading: isLoadingLocations,
            isSuccess: isSuccessLocations,
            isFetching: isFetchingLocations,
            remove: removeLocations,
            isError: isErrorLocations,
            fetchResources: fetchLocations,
            newSearch: handleNewSearchLocations,
        },
        Network: {
            data: networks,
            isLoading: isLoadingNetworks,
            isSuccess: isSuccessNetworks,
            isFetching: isFetchingNetworks,
            remove: removeNetworks,
            isError: isErrorNetworks,
            fetchResources: fetchNetworks,
            newSearch: handleNewSearchNetworks,
        },
        HealthcareService: {
            data: healthcareService,
            isLoading: isLoadingHealthcareService,
            isSuccess: isSuccessHealthcareService,
            isFetching: isFetchingHealthcareService,
            remove: removeHealthcareService,
            isError: isErrorHealthcareService,
            fetchResources: fetchHealthcareService,
            newSearch: handleNewSearchHealthcareService,
        },
        InsurancePlan: {
            data: insurancePlans,
            isLoading: isLoadingInsurancePlans,
            isSuccess: isSuccessInsurancePlans,
            isFetching: isFetchingInsurancePlans,
            remove: removeInsurancePlans,
            isError: isErrorInsurancePlans,
            fetchResources: fetchInsurancePlans,
            newSearch: handleNewSearchInsurancePlans,
        },
    }

    useEffect(() => {
        const resourceKey = key as keyof ResourceMap
        const resource = resourceMap[resourceKey]
        resource.fetchResources()
    }, [])

    const newSearch = () => {
        const resourceKey = key as keyof ResourceMap
        const resource = resourceMap[resourceKey]
        resource.newSearch()
    }
    const handleLinkClick = (resource: Organization | Practitioner | PractitionerRole | InsurancePlan | Location | OrganizationAffiliation) => {

        if (key === 'Organization' || key === 'Network') {
            setOrganization(resource as Organization)
        }

        if (key === 'Practitioner') {
            setProvider(resource as Practitioner)
        }
        if (key === 'PractitionerRole') {
            setPractitionerRole(resource as PractitionerRole)
        }

        if (key === 'InsurancePlan') {
            setInsurancePlan(resource as InsurancePlan)
        }
        if (key === 'Location') {
            setLocation(resource as Location)
        }
        if (key === 'OrganizationAffiliation') {
            setOrganizationAffiliation(resource as OrganizationAffiliation)
        }

        scrollToTop()
        history.push(`/provider-list/${resource.id}`)
    }

    const handleClearForm = () => {
        setProviderSearchTerms('')
        newSearch()
    }

    const handleUpdateSearchTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProviderSearchTerms(e.target.value)
    }

    const selectedService: HealthcareService | undefined = healthcareService?.data?.find(
        (s: HealthcareService) => s.id === resourceId
    )

    return (
        <PermissionGate
            user={user}
            role='admin'
        >
            <Container
                style={{
                    padding: '10px 0',
                }}
            >
                <Tab.Container activeKey={key} onSelect={handleChangeKey} transition={false}>
                    <h2 className='headerText'>Provider Directory API</h2>
                    <Row>
                        <Col sm={3}>
                            <Nav variant='pills' className='flex-column'>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link style={{ color: 'white' }} eventKey='Organization'>Organizations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='OrganizationAffiliation'>Organization Affiliations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='Practitioner'>Practitioners</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='PractitionerRole'>Practitioner Role</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='HealthcareService'>Healthcare Services</Nav.Link>
                                </Nav.Item >
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='InsurancePlan'>Insurance Plans</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='Location'>Locations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='Network'>Networks</Nav.Link>
                                </Nav.Item>
                            </Nav >
                        </Col >
                        <Col sm={9} className='mt-sm-0 mt-3'>
                            <Tab.Content>
                                <Tab.Pane eventKey='Organization'>
                                    {
                                        key === 'Organization' && (
                                            <Tab.Content>
                                                {
                                                    resourceId ? (
                                                        <OrganizationDetail organization={organization} />
                                                    ) : (
                                                        <OrganizationList
                                                            organizations={organizations}
                                                            clickViewDataLink={handleLinkClick}
                                                            searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                            isFetching={isFetchingOrganizations}
                                                            isLoading={isLoadingOrganizations}
                                                            isSuccess={isSuccessOrganizations}
                                                            clearForm={handleClearForm}
                                                            newSearch={newSearch}
                                                            updateSearchTerms={handleUpdateSearchTerms}
                                                            totalPages={organizationTotalPages}
                                                            currentPage={organizationCurrentPage}
                                                            handleChangePage={handleChangeOrganization}
                                                        />
                                                    )
                                                }
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>

                                <Tab.Pane eventKey='OrganizationAffiliation'>
                                    {
                                        key === 'OrganizationAffiliation' && (
                                            <Tab.Content>
                                                {
                                                    resourceId ? (
                                                        <OrganizationAffiliationDetail resource={organizationAffiliation} />
                                                    ) : (
                                                        <OrganizationAffiliationList
                                                            organizationAffiliations={organizationAffiliations}
                                                            clickViewDataLink={handleLinkClick}
                                                            searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                            isFetching={isFetchingPractitioners}
                                                            isLoading={isLoadingPractitioners}
                                                            isSuccess={isSuccessPractitioners}
                                                            clearForm={handleClearForm}
                                                            newSearch={newSearch}
                                                            // updateSearchTerms={handleUpdateSearchTerms}
                                                            totalPages={practitionerTotalPages}
                                                            currentPage={practitionerCurrentPage}
                                                            handleChangePage={handleChangePractitioner}
                                                        />
                                                    )
                                                }
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey='Practitioner'>
                                    {
                                        key === 'Practitioner' && (
                                            <Tab.Content>
                                                {
                                                    resourceId ? (
                                                        <PractitionerDetail resource={provider} />
                                                    ) : (
                                                        <PractitionerList
                                                            practitioners={practitioners}
                                                            clickViewDataLink={handleLinkClick}
                                                            searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                            isFetching={isFetchingPractitioners}
                                                            isLoading={isLoadingPractitioners}
                                                            isSuccess={isSuccessPractitioners}
                                                            clearForm={handleClearForm}
                                                            newSearch={newSearch}
                                                            updateSearchTerms={handleUpdateSearchTerms}
                                                            totalPages={practitionerTotalPages}
                                                            currentPage={practitionerCurrentPage}
                                                            handleChangePage={handleChangePractitioner}
                                                        />
                                                    )
                                                }
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey='PractitionerRole'>
                                    {
                                        key === 'PractitionerRole' && (
                                            <Tab.Content>
                                                {
                                                    resourceId ? (
                                                        <PractitionerRoleDetail resource={practitionerRole} />
                                                    ) : (
                                                        <PractitionerRoleList
                                                            practitionersRole={practitionersRole}
                                                            clickViewDataLink={handleLinkClick}
                                                            searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                            isFetching={isFetchingPractitionersRole}
                                                            isLoading={isLoadingPractitionersRole}
                                                            isSuccess={isSuccessPractitionersRole}
                                                            clearForm={handleClearForm}
                                                            newSearch={newSearch}
                                                            updateSearchTerms={handleUpdateSearchTerms}
                                                            totalPages={practitionerRoleTotalPages}
                                                            currentPage={practitionerRoleCurrentPage}
                                                            handleChangePage={handleChangePractitionerRole}
                                                        />
                                                    )
                                                }
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey='HealthcareService'>
                                    {
                                        key === 'HealthcareService' && (
                                            <Tab.Content>
                                                {resourceId ? (
                                                    <HealthcareServiceDetail service={selectedService} />
                                                ) : (
                                                    <HealthcareServiceList
                                                        services={healthcareService}
                                                        clickViewDataLink={handleLinkClick}
                                                        isFetching={isFetchingHealthcareService}
                                                        isLoading={isLoadingHealthcareService}
                                                        isSuccess={isSuccessHealthcareService}
                                                        totalPages={healthcareServiceTotalPages}
                                                        currentPage={healthcareServiceCurrentPage}
                                                        handleChangePage={handleChangeHealthcareService}
                                                    />
                                                )}
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="InsurancePlan">
                                    {
                                        key === 'InsurancePlan' && (
                                            <Tab.Content>
                                                {resourceId ? (
                                                    <InsurancePlanDetail resource={insurancePlan} />
                                                ) : (
                                                    <InsurancePlanList
                                                        insurancePlans={insurancePlans}
                                                        clickViewDataLink={handleLinkClick}
                                                        isFetching={isFetchingInsurancePlans}
                                                        isLoading={isLoadingInsurancePlans}
                                                        isSuccess={isSuccessInsurancePlans}
                                                        totalPages={insurancePlanTotalPages}
                                                        currentPage={insurancePlanCurrentPage}
                                                        handleChangePage={handleChangeInsurancePlan}
                                                        clearForm={handleClearForm}
                                                        newSearch={newSearch}
                                                        updateSearchTerms={handleUpdateSearchTerms}
                                                        searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                    />
                                                )}
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>

                                <Tab.Pane eventKey='Location'>
                                    <Tab.Content>
                                        {
                                            resourceId ? (
                                                <LocationDetail resource={location} />
                                            ) : (
                                                <LocationList
                                                    locations={locations}
                                                    clickViewDataLink={handleLinkClick}
                                                    searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                    isFetching={isFetchingLocations}
                                                    isLoading={isLoadingLocations}
                                                    isSuccess={isSuccessLocations}
                                                    clearForm={handleClearForm}
                                                    newSearch={newSearch}
                                                    updateSearchTerms={handleUpdateSearchTerms}
                                                    totalPages={locationTotalPages}
                                                    currentPage={locationCurrentPage}
                                                    handleChangePage={handleChangeLocation}
                                                />
                                            )
                                        }
                                    </Tab.Content>
                                </Tab.Pane>

                                <Tab.Pane eventKey="Network">
                                    {
                                        key === 'Network' && (
                                            <Tab.Content>
                                                {resourceId ? (
                                                    <NetworkDetail networks={organization} />
                                                ) : (
                                                    <NetworkList
                                                        networks={networks}
                                                        clickViewDataLink={handleLinkClick}
                                                        isFetching={isFetchingNetworks}
                                                        isLoading={isLoadingNetworks}
                                                        isSuccess={isSuccessNetworks}
                                                        totalPages={networkTotalPages}
                                                        currentPage={networkCurrentPage}
                                                        handleChangePage={handleChangeNetwork}
                                                    />
                                                )}
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>
                            </Tab.Content >
                        </Col >
                    </Row >
                </Tab.Container >
            </Container >
        </PermissionGate >
    )
}

export default isAuthenticated(ProviderList)