import React, { FC, useState, useEffect } from 'react'
import { Accordion, Button, Col, Row, Form } from 'react-bootstrap'

const DataConsentDemo: FC = () => {
    const [providerAccess, setProviderAccess] = useState(false)
    const [payerAccess, setPayerAccess] = useState(true)
    const [sensitiveDataAccess, setSensitiveDataAccess] = useState(false)
    const [isModified, setIsModified] = useState(false)

    const defaultState = {
        providerAccess: false,
        payerAccess: true,
        sensitiveDataAccess: false
    }

    const handleProviderChange = () => {
        const newProviderAccess = !providerAccess
        setProviderAccess(newProviderAccess)
        setIsModified(
            newProviderAccess !== defaultState.providerAccess ||
            payerAccess !== defaultState.payerAccess ||
            sensitiveDataAccess !== defaultState.sensitiveDataAccess
        )
    }

    const handlePayerChange = () => {
        const newPayerAccess = !payerAccess
        setPayerAccess(newPayerAccess)
        setIsModified(
            providerAccess !== defaultState.providerAccess ||
            newPayerAccess !== defaultState.payerAccess ||
            sensitiveDataAccess !== defaultState.sensitiveDataAccess
        )
    }

    const handleSensitiveDataChange = () => {
        const newSensitiveDataAccess = !sensitiveDataAccess
        setSensitiveDataAccess(newSensitiveDataAccess)
        setIsModified(
            providerAccess !== defaultState.providerAccess ||
            payerAccess !== defaultState.payerAccess ||
            newSensitiveDataAccess !== defaultState.sensitiveDataAccess
        )
    }

    const restoreDefaults = () => {
        setProviderAccess(defaultState.providerAccess)
        setPayerAccess(defaultState.payerAccess)
        setSensitiveDataAccess(defaultState.sensitiveDataAccess)
        setIsModified(false)
    }

    useEffect(() => {
        setIsModified(
            providerAccess !== defaultState.providerAccess ||
            payerAccess !== defaultState.payerAccess ||
            sensitiveDataAccess !== defaultState.sensitiveDataAccess
        )
    }, [providerAccess, payerAccess, sensitiveDataAccess])

    return (
        <div>
            <h6 style={{ fontWeight: 'bolder', marginBottom: '14px' }}>CONSENT FOR DATA ACCESS</h6>
            <Row className="align-items-center" style={{ marginBottom: '5px' }}>
                <Col className="d-flex align-items-center">
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '10px' }}>Consent for External Apps I Have Authorized</h6>
                    <Form.Check
                        type="switch"
                        id="external-apps-switch"
                        label=""
                        checked={false}
                        readOnly
                        disabled
                        style={{ pointerEvents: 'none' }}
                    />
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '10px' }}>N/A</h6>
                </Col>
            </Row>
            <p style={{ marginTop: '5px' }}>If you have authorized any of the Available Apps to access your data using your credentials from this site, you should go to that app directly and remove access. You can also change your password here to prevent further connections from that app, but it may not immediately revoke access to connections that currently exist.</p>

            <Row className="align-items-center" style={{ marginBottom: '5px' }}>
                <Col className="d-flex align-items-center">
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '15px' }}>Consent for Provider Access</h6>
                    <Form.Check
                        type="switch"
                        id="provider-switch"
                        label=""
                        checked={providerAccess}
                        onChange={handleProviderChange}
                    />
                    <h6 style={{ marginBottom: '0', marginLeft: '15px', fontWeight: 'bold', color: providerAccess ? '#3ab413' : 'red' }}>{providerAccess ? 'ON' : 'OFF'}</h6>
                    <div className="d-flex align-items-center" style={{ marginLeft: '15px' }}>
                        <Form.Check
                            type="checkbox"
                            id="sensitive-data-checkbox"
                            label=""
                            checked={sensitiveDataAccess}
                            onChange={handleSensitiveDataChange}
                            disabled={!providerAccess}
                            style={{ transform: 'scale(0.8)', marginBottom: '0' }}
                        />
                        <span style={{ marginLeft: '0', color: providerAccess ? 'inherit' : '#6c757d' }}>Access includes sensitive and confidential data</span>
                    </div>
                </Col>
            </Row>
            <p style={{ marginBottom: '8px', marginTop: '0' }}>This access will allow designated providers to access your Spruce Health data. Additional consent is required for providers to access data that is designated sensitive or confidential.</p>
            <h6 style={{ fontWeight: '600', marginBottom: '23px' }}>PCP: Spruce Valley Regional Clinic, Betsy M. Sullivan, MD</h6>

            <Row className="align-items-center" style={{ marginBottom: '5px' }}>
                <Col className="d-flex align-items-center">
                    <h6 style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '15px' }}>Consent for Payer to Payer Access</h6>
                    <Form.Check
                        type="switch"
                        id="payer-switch"
                        label=""
                        checked={payerAccess}
                        onChange={handlePayerChange}
                    />
                    <h6 style={{ marginBottom: '0', marginLeft: '15px', fontWeight: 'bold', color: payerAccess ? '#3ab413' : 'red' }}>{payerAccess ? 'ON' : 'OFF'}</h6>
                </Col>
            </Row>
            <p style={{ marginBottom: '8px', marginTop: '0' }}>This access will allow Spruce Health to retrieve data from your previous health plans.</p>
            <h6 style={{ marginBottom: '8px', fontWeight: '600' }}>Prior Health Plan: BCBS West</h6>
            <dd>
                <Accordion.Toggle as={Button} eventKey='0' className='restore-button' onClick={restoreDefaults} disabled={!isModified}>
                    Restore Defaults
                </Accordion.Toggle>
            </dd>
        </div>
    )
}

export default DataConsentDemo