import React, { FC } from 'react'
import { MedicationRequest } from '../../../../types/FHIRTypes/MedicationRequest'
import { buildPeriod } from '../../../../utils/helpers'
import { handleReference } from '../../../../utils/helpers'
import Reference from '../../DataTypes/Reference'

interface MedDispenseRequestProps {
    medDispenseRequest: MedicationRequest;
}

const MedDispenseRequest: FC<MedDispenseRequestProps> = ({ medDispenseRequest }) => {
    const {
        initialFill, // First fill details
        dispenseInterval, // Minimum period of time between dispenses
        validityPeriod, // Time period supply is authorized for
        numberOfRepeatsAllowed, // Number of refills authorized
        quantity, // Amount of medication to supply per dispense
        expectedSupplyDuration, // Number of days supply per dispense
        performer // Intended dispenser 
    } = medDispenseRequest.dispenseRequest || {}

    const initialFillQuantity = initialFill?.quantity?.value // First fill quantity
    const initialFillDuration = initialFill?.duration // First fill duration

    return (
        <div>
            {initialFillQuantity || initialFillDuration ? 'Initial fill: ' : ''}
            {initialFillQuantity && `Quantity: ${initialFillQuantity} `}
            {initialFillDuration && `Duration: ${initialFillDuration} `}
            {dispenseInterval && `Dispense Interval: ${dispenseInterval} `}
            {validityPeriod && `Validity Period: ${buildPeriod(validityPeriod)} `}
            {numberOfRepeatsAllowed && `Number of Repeats Allowed: ${numberOfRepeatsAllowed} `}
            {quantity && `Quantity: ${quantity.value} `}
            {expectedSupplyDuration && `Expected Supply Duration: ${expectedSupplyDuration} `}
            {performer && <>Performer: <Reference data={performer} /></>}
        </div>
    )
}

export default MedDispenseRequest