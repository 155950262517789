import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import Reference from '../../DataTypes/Reference'
import { Organization } from '../../../../types/FHIRTypes/Organization'
import ReferencePopover from '../../../ReferencePopover'
import { FieldConfig } from '../../../../types/FieldConfig'
import ShowField from '../ShowField'
import ResourceConfigProvider from '../../FhirResourceConfigProvider'
import { isReference } from '../../../../typeGuards/fhirTypes'
import Extension from '../../DataTypes/Extension'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import CodeableConceptDT from '../../DataTypes/CodeableConcept'
import { APIType } from '../../../../types/APIType'
import StringDisplay from '../../DataTypes/String'

interface NetworkReferenceProps {
    network: FHIRReference
    apiType: APIType
}
const config: FieldConfig = {
    value: { label: 'Value', visibility: 'always' },
    display: { label: 'Display', visibility: 'conditional' }
}

const NetworkReference: React.FC<NetworkReferenceProps> = ({ network, apiType }) => {
    if (!network) return null

    if (!isReference(network)) {
        return <Extension nestedExtension={network as CodeableConcept} />
    }
    const reference = network?.reference?.split('/')
    const networkId = reference?.[1] || ''

    const {
        data: organizationData,
        refetch: getOrganization,
        isFetching: fetchingOrganization,
        isLoading: loadingOrganization
    }: UseQueryResult<Organization, Error> = useFhirResourceById(apiType, networkId, 'Organization') // Still fetching Organization


    useEffect(() => {
        if (network) {
            getOrganization()
        }
    }, [network])

    if (fetchingOrganization || loadingOrganization) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    if (organizationData?.resourceType !== 'Organization') {
        return null
    }

    return (
        <ResourceConfigProvider config={config} resource={network}>
            <div className="d-flex">
                {organizationData?.name ? (
                    <div className="d-flex align-items-center">
                        <StringDisplay data={organizationData.name} />
                        <ReferencePopover title="Reference">
                            <ShowField field="value">
                                <Reference data={network} />
                            </ShowField>
                            <ShowField field="display">
                                <StringDisplay data={network.display} />
                            </ShowField>
                        </ReferencePopover>
                    </div>
                ) : (
                    <span>
                        {network?.reference && network?.display
                            ? `${network.reference} (${network.display})`
                            : <Reference data={network} />}
                    </span>
                )}
            </div>
        </ResourceConfigProvider>
    )
}



export default NetworkReference

