import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Practitioner } from '../../../../../types/FHIRTypes/Practitioner'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Address from '../../../DataTypes/Address'
import Identifier from '../../../DataTypes/Identifier'
import Code from '../../../DataTypes/Code'
import Boolean from '../../../DataTypes/Boolean'
import HumanName from '../../../DataTypes/HumanName'
import ContactPoint from '../../../DataTypes/ContactPoint'
import Date from '../../../DataTypes/Date'
import Narrative from '../../../DataTypes/Narrative'
import ResourceType from '../../../DisplayComponents/ResourceType'
import StringDisplay from '../../../DataTypes/String'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import Profile from '../../../DisplayComponents/Profile'
import URI from '../../../DataTypes/URI'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import PractitionerQualifications from '../../../DisplayComponents/PractitionerQualifications'

interface PractitionerDetailProps {
    resource: Practitioner | null
}

const config: FieldConfig = {
    identifier: { label: 'Practitioner Identifier', visibility: 'always' },
    active: { label: 'Active', visibility: 'always' },
    name: { label: 'Name', visibility: 'always' },
    telecom: { label: 'Contact Information', visibility: 'always' },
    address: { label: 'Address', visibility: 'always' },
    gender: { label: 'Gender', visibility: 'conditional' },
    birthDate: { label: 'Birth Date', visibility: 'conditional' },
    qualification: { label: 'Qualification', visibility: 'always' },
    communication: { label: 'Communication Language', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
}

const PractitionerDetail: React.FC<PractitionerDetailProps> = ({ resource }) => {
    if (!resource) {
        return (
            <div className='text-center'>
                <dl className='dataContainer'>
                    No Practitioners Found
                </dl>
            </div>
        )
    }

    return (
        <ResourceConfigProvider config={config} resource={resource}>
            <section>
                <h3>Practitioners</h3>
                <dl className='dataContainer'>
                    <Row>
                        <ShowField field='identifier'>
                            <Col sm={4}>
                                <dt>{config.identifier.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Identifier data={resource.identifier} dataExtension={resource._identifier} /> </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='active'>
                            <Col sm={4}>
                                <dt>{config.active.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Boolean data={resource.active} dataExtension={resource._active} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='name'>
                            <Col sm={4}>
                                <dt>{config.name.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><HumanName data={resource.name} dataExtension={resource._name} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='telecom'>
                            <Col sm={4}>
                                <dt>{config.telecom.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><ContactPoint data={resource.telecom} dataExtension={resource._telecom} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='address'>
                            <Col sm={4}>
                                <dt>{config.address.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Address data={resource.address} showUse={resource.address && resource.address.length > 1} dataExtension={resource._address} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='gender'>
                            <Col sm={4}>
                                <dt>{config.gender.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Code data={resource.gender} dataExtension={resource._gender} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='birthDate'>
                            <Col sm={4}>
                                <dt>{config.birthDate.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Date data={resource.birthDate} dataExtension={resource._birthDate} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='qualification'>
                            <Col sm={4}>
                                <dt>{config.qualification.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><PractitionerQualifications data={resource.qualification} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='communication'>
                            <Col sm={4}>
                                <dt>{config.communication.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={resource.communication} dataExtension={resource._communication} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='text'>
                            <Col sm={4}>
                                <dt>{config.text.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                            </Col>
                        </ShowField>
                        <Col sm={12} className='footer'>
                            <hr />

                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType'>
                                    <Col sm={4}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><ResourceType resourceType={resource.resourceType} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id'>
                                    <Col sm={4}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta'>
                                    <Col sm={4}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><MetaLastUpdated data={resource.meta} dataExtension={resource._meta} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' subResource={resource.meta}>
                                    <Col sm={4}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Profile profile={resource.meta?.profile} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language'>
                                    <Col sm={4}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules'>
                                    <Col sm={4}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                    </Col>
                                </ShowField>
                            </Row>
                        </Col>
                    </Row>
                </dl>
            </section>
        </ResourceConfigProvider>
    )
}

export default PractitionerDetail