// This enum contains the codes from system http://hl7.org/fhir/us/davinci-pdex-plan-net/STU1.1/ValueSet-QualificationStatusVS.html
// The enum defines the display values as described in the FHIR documentation. 

export enum QualificationStatusCodeEnum {
  active = 'active',
  inactive = 'inactive',
  issiner = 'issued in error',
  revoked = 'revoked',
  pending = 'pending',
  unknown = 'unknown',
}