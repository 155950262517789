import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Extension as FHIRExtension } from '../../../../types/FHIRTypes/Extension'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import { DateTime as FHIRDateTime } from '../../../../types/FHIRTypes/PrimitiveFhirTypes'

interface DateTimeProps {
    data?: FHIRDateTime
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const DateTime: FC<DateTimeProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (!data && dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }
    
    if (data) {
        return <span>{data}</span>
    }
    return null
}

export default DateTime