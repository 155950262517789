import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { findExtensionByURI } from '../../../../../utils/helpers'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import Identifier from '../../../DataTypes/Identifier'
import Boolean from '../../../DataTypes/Boolean'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import StringDisplay from '../../../DataTypes/String'
import Reference from '../../../DataTypes/Reference'
import ContactPoint from '../../../DataTypes/ContactPoint'
import URI from '../../../DataTypes/URI'
import Code from '../../../DataTypes/Code'
import Profile from '../../../DisplayComponents/Profile'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import ResourceType from '../../../DisplayComponents/ResourceType'
import Narrative from '../../../DataTypes/Narrative'
import LocationReference from '../../../DisplayComponents/LocationReference'
import { Extension } from '../../../../../types/FHIRTypes/Extension'
import { HealthcareService } from '../../../../../types/FHIRTypes/HealthcareService'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'
import TimeDisplay from '../../../DataTypes/Time'
import ServiceNotAvailable from '../../../DisplayComponents/NotAvailable'
import AcceptingPatients from '../../../DisplayComponents/AcceptingPatients'
import DeliveryMethod from '../../../DisplayComponents/DeilveryMethod'

interface HealthcareServiceDetailProps {
    service?: HealthcareService | null
}

const config: FieldConfig = {
    category: { label: 'Category', visibility: 'always' },
    name: { label: 'Service Name', visibility: 'conditional' },
    identifier: { label: ' Service Identifier', visibility: 'conditional' },
    providedBy: { label: 'Service Provided By', visibility: 'conditional' },
    active: { label: 'Active', visibility: 'always' },
    type: { label: 'Type', visibility: 'conditional' },
    specialty: { label: 'Specialty', visibility: 'conditional' },
    location: { label: 'Location', visibility: 'conditional' },
    telecom: { label: 'Contact Information', visibility: 'conditional' },
    coverageArea: { label: 'Coverage Area', visibility: 'conditional' },
    appointmentRequired: { label: 'Appointment Required', visibility: 'conditional' },
    daysOfWeek: { label: 'Available Time - Days of Week', visibility: 'conditional' },
    allDay: { label: 'Available Time - All Day', visibility: 'conditional' },
    startTime: { label: 'Available Time - Start Time', visibility: 'conditional' },
    endTime: { label: 'Available Time - End Time', visibility: 'conditional' },
    availabilityExceptions: { label: 'Availability Exceptions', visibility: 'conditional' },
    notAvailable: { label: 'Service Not Available', visibility: 'conditional' },
    acceptingpatients: { label: 'Accepting New Patients', visibility: 'conditional' },
    deliverymethod: { label: 'Service Delivery Method', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    comment: { label: 'Comment', visibility: 'conditional' },
    endpoint: { label: 'Endpoint', visibility: 'conditional' },

    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
}

const HealthcareServiceDetail: React.FC<HealthcareServiceDetailProps> = ({ service }) => {

    const filterExtensionsByUrl = (extensions: Extension[] | undefined, url: string): Extension[] => {
        return extensions?.filter((ext: Extension) => ext.url === url) || []
    }
    const acceptingPatientsExtension = findExtensionByURI(
        service?.extension,
        'http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/newpatients'
    )?.extension

    const deliveryMethodExtension = findExtensionByURI(
        service?.extension,
        'http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/delivery-method'
    )?.extension

    const updatedService = {
        ...service,
        acceptingpatients: acceptingPatientsExtension,
        deliverymethod: deliveryMethodExtension,
    }

    if (!service) {
        return (
            <div className='text-center'>
                <dl className='dataContainer'>
                    No Health Care Serives Found
                </dl>
            </div>
        )
    }

    return (
        <ResourceConfigProvider config={config} resource={updatedService}>
            <section>
                <dl className='dataContainer'>
                    <Row>
                        <ShowField field='category'>
                            <Col sm={4}>
                                <dt>{config.category.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={service?.category} dataExtension={service?._category} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='name'>
                            <Col sm={4}>
                                <dt>{config.name.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <StringDisplay data={service?.name} dataExtension={service?._name} />
                                </dd>
                            </Col>

                        </ShowField>

                        <ShowField field='identifier'>
                            <Col sm={4}>
                                <dt>{config.identifier.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Identifier data={service?.identifier} dataExtension={service?._identifier} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='providedBy'>
                            <Col sm={4}>
                                <dt>{config.providedBy.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><OrganizationReference organization={service?.providedBy} apiType='Provider Directory' /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='active'>
                            <Col sm={4}>
                                <dt>{config.active.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    <Boolean data={service?.active} dataExtension={service?._active} />
                                </dd>
                            </Col>

                        </ShowField>
                        <ShowField field='type'>
                            <Col sm={4}>
                                <dt>{config.type.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={service?.type} dataExtension={service?._type} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field="specialty">
                            <Col sm={4}>
                                <dt>{config.specialty.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><CodeableConcept data={service?.specialty} dataExtension={service?._specialty} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field="location">
                            <Col sm={4}>
                                <dt>{config.location.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd>
                                    {
                                        service?.location?.map((loc, index) => (
                                            <LocationReference
                                                key={`location-${index}`}
                                                location={loc}
                                                apiType='Provider Directory'
                                            />
                                        ))
                                    }
                                </dd>
                            </Col>
                        </ShowField>
                        <ShowField field='telecom'>
                            <Col sm={4}>
                                <dt>{config.telecom.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><ContactPoint data={service?.telecom} dataExtension={service?._telecom} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='coverageArea'>
                            <Col sm={4}>
                                <dt>{config.coverageArea.label}</dt>
                            </Col>
                            <Col sm={8}>
                                {service?.coverageArea?.map((loc, index) => (
                                    <LocationReference
                                        key={`coverage-location-${index}`}
                                        location={loc}
                                        apiType='Provider Directory'
                                    />
                                ))}

                            </Col>
                        </ShowField>

                        <ShowField field='appointmentRequired'>
                            <Col sm={4}>
                                <dt>{config.appointmentRequired.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Boolean data={service?.appointmentRequired} dataExtension={service?._appointmentRequired} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='daysOfWeek'>
                            <Col sm={4}>
                                <dt>{config.daysOfWeek.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Code data={service?.daysOfWeek} dataExtension={service?._daysOfWeek} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='allDay'>
                            <Col sm={4}>
                                <dt>{config.allDay.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Boolean data={service?.allDay} dataExtension={service?._allDay} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='startTime'>
                            <Col sm={4}>
                                <dt>{config.startTime.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><TimeDisplay data={service?.startTime} dataExtension={service?._startTime} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='endTime'>
                            <Col sm={4}>
                                <dt>{config.endTime.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><TimeDisplay data={service?.endTime} dataExtension={service?._endTime} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='availabilityExceptions'>
                            <Col sm={4}>
                                <dt>{config.availabilityExceptions.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><StringDisplay data={service?.availabilityExceptions} dataExtension={service?._availabilityExceptions} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='notAvailable'>
                            <Col sm={4}>
                                <dt>{config.notAvailable.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><ServiceNotAvailable serviceNotAvailable={service?.notAvailable} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='acceptingpatients'>
                            <Col sm={4}>
                                <dt>{config.acceptingpatients.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><AcceptingPatients acceptingPatients={updatedService.acceptingpatients} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='deliverymethod'>
                            <Col sm={4}>
                                <dt>{config.deliverymethod.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><DeliveryMethod deliveryMethod={updatedService.deliverymethod} /></dd>
                            </Col>
                        </ShowField>
                        <ShowField field='text'>
                            <Col sm={4}>
                                <dt>{config.text.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Narrative data={service?.text} dataExtension={service?._text} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='comment'>
                            <Col sm={4}>
                                <dt>{config.comment.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><StringDisplay data={service?.comment} dataExtension={service?._comment} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='endpoint'>
                            <Col sm={4}>
                                <dt>{config.endpoint.label}</dt>
                            </Col>
                            <Col sm={8}>
                                <dd><Reference data={service?.endpoint} dataExtension={service?._endpoint} /></dd>
                            </Col>
                        </ShowField>

                        <Col sm={12} className='footer'>
                            <hr />

                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType'>
                                    <Col sm={4}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd>{<ResourceType resourceType={service?.resourceType} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id'>
                                    <Col sm={4}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><StringDisplay data={service?.id} dataExtension={service?._id} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta'>
                                    <Col sm={4}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><MetaLastUpdated data={service?.meta} dataExtension={service?._meta} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' subResource={service?.meta}>
                                    <Col sm={4}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd>{<Profile profile={service?.meta?.profile} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language'>
                                    <Col sm={4}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code
                                            data={service?.language} dataExtension={service?._language}
                                        /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules'>
                                    <Col sm={4}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><URI data={service?.implicitRules} dataExtension={service?._implicitRules} /></dd>
                                    </Col>
                                </ShowField>

                            </Row>
                        </Col>
                    </Row>
                </dl >
            </section >
        </ResourceConfigProvider >
    )
}

export default HealthcareServiceDetail