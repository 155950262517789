import React, { FC } from 'react'
import { Spinner, Image, Row, Col } from 'react-bootstrap'
import { Coverage } from '../../../../types/FHIRTypes/Coverage'
import { sortCoveragesByStartDate } from '../../../../utils/fhirUtils/coverage'
import { CoverageClassCodeEnum } from '../../../../types/CoverageClassCodeEnum'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import humanizeString from 'humanize-string'
import HumanName from '../../DataTypes/HumanName'
import client_logo from '../../../../assets/customizations/logos/client_banner.svg'
import ConnectID_QR from '../../../../assets/images/ConnectID_QR.svg'
import './DigitalIdCard.scss'
import { formatDateWithFormat } from '../../../../utils/helpers'
import { Patient } from '../../../../types/FHIRTypes/Patient'

interface Props {
  coverageData?: { entry: { resource: Coverage }[] }
  patientData?: Patient
  isFetching: boolean
}

// this is basically just the code from the CoverageClass component, adjusted to match the styles for the mock.
// const mandatoryTypes = ['group', 'plan']
// const typeOrder = ['policy number', 'group', 'subgroup', 'plan', 'subplan', 'class', 'subclass', 'sequence', 'rxbin', 'rxpcn', 'rxid', 'rxgroup']

// const processCoverageClasses = (coverageClass: { type: CodeableConcept, value: string, name?: string }[]) => {
//   const classes = [...coverageClass]

//   mandatoryTypes.forEach((type) => {
//     if (!classes.some((c) => c.type.coding?.[0]?.code === type)) {
//       classes.push({
//         type: { coding: [{ code: type }] },
//         value: 'N/A'
//       })
//     }
//   })

//   const sortedCoverageClass = classes.sort((a, b) => {
//     const aType = a.type.coding?.[0].code
//     const bType = b.type.coding?.[0]?.code
//     const aIndex = typeOrder.indexOf(aType ?? '')
//     const bIndex = typeOrder.indexOf(bType ?? '')

//     if (aIndex === -1 && bIndex === -1) return 0
//     if (aIndex === -1) return 1
//     if (bIndex === -1) return -1
//     return aIndex - bIndex
//   })

//   return sortedCoverageClass
// }

// const getTypeDisplay = (type: string) => {
//   return CoverageClassCodeEnum[type as keyof typeof CoverageClassCodeEnum] ?? humanizeString(type)
// }

const DigitalIdCard: FC<Props> = ({ coverageData, patientData, isFetching }: Props) => {
  // const patientCoverages = coverageData?.entry?.map((entry) => entry.resource) || []
  // const sortedPatientCoverages = sortCoveragesByStartDate(patientCoverages)

  // if (!coverageData || coverageData.entry.length === 0) {
  //   return <div>No data available</div>
  // }

  return (
    <>
      {/* {
        isFetching ? (
          <div className='d-flex'>
            <Spinner
              as='span'
              animation='border'
              role='status'
              aria-hidden='true'
            />
            <span style={{ marginLeft: '10px' }}>Loading Digital ID div...</span>
          </div>
        ) : sortedPatientCoverages.length > 0 ? (
          sortedPatientCoverages.map((resource: Coverage) => {
            const processedCoverageClass = processCoverageClasses(resource.class || [])
            return (
              <div key={resource.id} className='m-auto p-2 dataContainer'> */}
      <div className='m-auto p-2 dataContainer'>
        <div className='p-1'>
          <Row className='align-items-center justify-content-between'>
            <Col xs={12} sm={8} className='text-center text-sm-left'>
              <h3 className='mb-2 font-weight-bold'>
                {patientData?.name && <HumanName data={patientData?.name?.[0]} dataExtension={patientData?._name} />}
              </h3>
            </Col>
            <Col xs={12} sm={4} className='text-center text-sm-right'>
              <Image className='img-fluid px-2' src={client_logo} alt="Provider" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={9}>
              {/* {processedCoverageClass.length > 0 ? (
                        processedCoverageClass.map((c, index) => {
                          const typeCode = c.type.coding?.[0]?.code
                          const typeDisplay = getTypeDisplay(typeCode ?? '')
                          const typeLabel = typeCode === 'plan' ? 'Health Plan' : typeCode === 'policy number' ? 'ID' : typeDisplay

                          return (
                            <div key={`class-${index}`} className='type-pair'>
                              <div className='element-label'>
                                <strong>{typeLabel} </strong>
                              </div>
                              {c.value} {c.name ? c.name : ''}
                            </div>
                          )
                        })
                      ) : (
                        null
                      )} */}
              <div className='type-pair'>
                <div className='element-label'>
                  <strong>ID </strong>
                </div>
                308fead6cc51941
              </div>
              <div className='type-pair'>
                <div className='element-label'>
                  <strong>Group </strong>
                </div>
                FAMILYCARE
              </div>
              <div className='type-pair'>
                <div className='element-label'>
                  <strong>Health Plan </strong>
                </div>
                FC WAIVER - DUAL ELIGIBLE
              </div>
            </Col>
            <Col xs={12} sm={3}>
              <div className='element-label'>
                <strong>Effective </strong>
              </div>
              {/* {formatDateWithFormat(resource.period?.start ?? '', 'MM/dd/yyyy')} */}
              02/28/2018
            </Col>
          </Row>
          <div className='text-center text-sm-left footer'>
            <hr />
            <Image className='img-fluid qr-code' src={ConnectID_QR} alt="ConnectID QR Code" />
          </div>
        </div>
        308fead6cc51941
      </div>
      {/* </div>
            )
          })
        ) : (
          <div>No data available</div>
        )
      } */}
    </>
  )
}

export default DigitalIdCard