import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import Reference from '../../DataTypes/Reference'
import { Organization } from '../../../../types/FHIRTypes/Organization'
import ReferencePopover from '../../../ReferencePopover'
import { FieldConfig } from '../../../../types/FieldConfig'
import ShowField from '../ShowField'
import ResourceConfigProvider from '../../FhirResourceConfigProvider'
import { isReference } from '../../../../typeGuards/fhirTypes'
import Extension from '../../DataTypes/Extension'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import CodeableConceptDT from '../../DataTypes/CodeableConcept'
import { APIType } from '../../../../types/APIType'
import StringDisplay from '../../DataTypes/String'
import HumanName from '../../DataTypes/HumanName'

interface OrganizationReferenceProps {
    organization?: FHIRReference
    apiType: APIType
}

const config: FieldConfig = {
    value: { label: 'Value', visibility: 'always' },
    display: { label: 'Display', visibility: 'conditional' }
}

const OrganizationReference: React.FC<OrganizationReferenceProps> = ({ organization, apiType }) => {
    if (!organization) return null

    if (!isReference(organization)) {
        return <Extension nestedExtension={organization as CodeableConcept} />
    }
    const reference = organization?.reference?.split('/')
    const organizationId = reference?.[1] || ''

    const {
        data: organizationData,
        refetch: getOrganization,
        isFetching: fetchingOrganization,
        isLoading: loadingOrganization
    }: UseQueryResult<Organization, Error> = useFhirResourceById(apiType, organizationId, 'Organization')

    useEffect(() => {
        if (organization) {
            getOrganization()
        }
    }, [organization])

    if (fetchingOrganization || loadingOrganization) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    if (organizationData?.resourceType !== 'Organization') {
        return null
    }
    return (
        <ResourceConfigProvider config={config}resource={organization}>
            <div className="d-flex">
                {organizationData?.name ? (
                    <div className="d-flex align-items-center">
                        <StringDisplay data={organizationData.name} />
                        <ReferencePopover title="Reference">
                            <ShowField field="value">
                                <Reference data={organization} />
                            </ShowField>
                            <ShowField field="display">
                                <StringDisplay data={organization.display} />
                            </ShowField>
                        </ReferencePopover>
                    </div>
                ) : (
                    <span>
                        {organization?.reference && organization?.display
                            ? `${organization.reference} (${organization.display})`
                            : <Reference data={organization} />}
                    </span>
                )}
            </div>
        </ResourceConfigProvider>
    )
}

export default OrganizationReference