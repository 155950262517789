import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { buildPatientId } from '../../../../../../utils/fhirUtils/patient'
// import {formatDateInTimeZone, formatCarePlanName} from
import { formatCarePlanDataForTable } from '../../../../../../utils/fhirUtils/carePlan'
import PaginationButtons from '../../../../../PaginationButtons'
import Code from '../../../../DataTypes/Code'
import CodeableConcept from '../../../../DataTypes/CodeableConcept'
import StringDisplay from '../../../../DataTypes/String'

import { CarePlanBundle } from '../../../../../../types/FHIRTypes/Bundle'

interface Props {
    lakelandCarePlanData: CarePlanBundle;
    patientData: any;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
}

const LakelandCarePlanList: FC<Props> = ({ lakelandCarePlanData, handleChangePage, isFetching, patientData, totalPages, currentPage }: Props) => {
    const patientCarePlans = formatCarePlanDataForTable(lakelandCarePlanData)

    return (
        <>
            <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                {patientCarePlans.length > 0 &&
                    <div>
                        <PaginationButtons
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handleChangePage={handleChangePage}
                        />
                    </div>
                }
                {lakelandCarePlanData?.total > 0 ? `${lakelandCarePlanData.total.toLocaleString()} record(s) found` : ''}
            </div>
            <Table size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'>Care Plan ID</th>
                        <th className='d-table-cell'>Title</th>
                        <th className='d-none d-lg-table-cell'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {isFetching ? (
                        <tr>
                            <td colSpan={6} rowSpan={15} className='d-flex'>
                                <Spinner
                                    as='span'
                                    animation='border'
                                    role='status'
                                    aria-hidden='true'
                                />
                                <span style={{ marginLeft: '10px' }}>Loading Care Plan Data...</span>
                            </td>
                        </tr>
                    ) : patientCarePlans.length === 0 ? (
                        <tr>
                            <td colSpan={3} className='text-center'>No Care Plans Found For This Member</td>
                        </tr>
                    ) : (
                        patientCarePlans.map((carePlan: any) => (
                            <tr key={carePlan.carePlanId}>
                                <td className='d-table-cell'> <Link to={`/patient/CarePlan/${carePlan.carePlanId}`}> <CodeableConcept data={carePlan.category} dataExtension={carePlan._category} /></Link>   </td>
                                <td className='d-table-cell'> <StringDisplay data={carePlan?.title} /></td>
                                <td className='d-none d-lg-table-cell'><Link to={`/patient/CarePlan/${carePlan.carePlanId}`}>Detail</Link></td>
                            </tr>
                        ))
                    )
                    }
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {patientCarePlans.length > 0 &&
                            <tr>
                                <td scope="row" colSpan={3}>
                                    <PaginationButtons
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        handleChangePage={handleChangePage}
                                    />
                                </td>
                            </tr>
                        }
                    </div>
                </tfoot>
            </Table>
        </>
    )
}

export default LakelandCarePlanList