import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import { Location } from '../../../../types/FHIRTypes/Location'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import Reference from '../../DataTypes/Reference'
import ReferencePopover from '../../../ReferencePopover'
import Address from '../../DataTypes/Address'
import ShowField from '../ShowField'
import ResourceConfigProvider from '../../FhirResourceConfigProvider'
import { FieldConfig } from '../../../../types/FieldConfig'
import ContactPoint from '../../DataTypes/ContactPoint'
import { APIType } from '../../../../types/APIType'
import StringDisplay from '../../DataTypes/String'
import Extension from '../../DataTypes/Extension'
import { isReference } from '../../../../typeGuards/fhirTypes'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { HealthcareService } from '../../../../types/FHIRTypes/HealthcareService'

interface HealthcareServicesReferenceProps {
    healthCareService?: FHIRReference;
    apiType: APIType;
}

const config: FieldConfig = {
    value: { label: 'Value', visibility: 'always' },
    display: { label: 'Display', visibility: 'conditional' }
}

const HealthcareServiceReference = ({healthCareService, apiType }: HealthcareServicesReferenceProps) => {
    if (!healthCareService) {
        return null
    }
    if (!isReference(healthCareService)) {
        return <Extension nestedExtension={healthCareService as CodeableConcept} />
    }
    const reference = healthCareService?.reference?.split('/')
    const healtcareServiceId = reference?.[1] || ''
    const {
        data: healthcareServiceData,
        refetch: getHealthcareService,
        isFetching: fetchingHealthcareService,
        isLoading: loadingHealthcareService
    }: UseQueryResult<HealthcareService, Error> = useFhirResourceById(apiType, healtcareServiceId, 'HealthcareService')

    useEffect(() => {
        getHealthcareService()
    }, [healthCareService])

    if(fetchingHealthcareService || loadingHealthcareService){
       return <Spinner animation="border" role="status" size='sm' />
    }
    return (
        <ResourceConfigProvider config={config} resource={healthCareService}>
            <div className="d-flex">
                {healthcareServiceData?.name ? (
                    <div className="d-flex align-items-center">
                         <StringDisplay data={healthcareServiceData.name} />
                        <ReferencePopover title="Reference">
                            <ShowField field="value">
                                <Reference data={healthCareService}/>
                            </ShowField>
                            <ShowField field="display">
                                <StringDisplay data={healthCareService.display} />
                            </ShowField>
                        </ReferencePopover>
                    </div>
                ) : (
                    <span>
                    {healthCareService?.reference && healthCareService?.display
                        ? `${healthCareService.reference} (${healthCareService.display})`
                        : <Reference data={healthCareService} />}
                    </span>
                )}
            </div>
        </ResourceConfigProvider>
    )
}

export default HealthcareServiceReference

