import React, { FC } from 'react'
import AllergyList from './AllergyList'
import AllergyDetail from './AllergyDetail'

interface Props {
    allergyData: any;
    patientData: any;
    allergyDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
}


const Allergies: FC<Props> = ({ allergyData, patientData, allergyDetailId, page, isFetching, handleChangePage, totalPages, currentPage }: Props) => {
    return (
        <div>
            {
                allergyDetailId && page === 'AllergyIntolerance' ? (
                    <AllergyDetail
                        allergyData={allergyData}
                        isFetching={isFetching}
                        allergyDetailId={allergyDetailId} />
                ) :
                    <AllergyList
                        allergyData={allergyData}
                        patientData={patientData}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        isFetching={isFetching}
                    />
            }
        </div>
    )
}

export default Allergies