import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Patient } from '../../../../types/FHIRTypes/Patient'
import HumanName from '../../DataTypes/HumanName'

interface Props {
    patientData: Patient | undefined;
}

const IPS: FC<Props> = ({ patientData }: Props) => {
    return (
        <section>
            <dl className='dataContainer'>
                <h4 className="font-weight-bold">International Patient Summary</h4>
                <div className="ml-3">
                    <h4 className="font-weight-bold text-dark"><HumanName data={patientData?.name} /></h4>

                    <Row className="mb-2">
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-0 font-weight-bold d-inline">Allergy/Intolerance:</h5>
                                <span className="ml-2" style={{ fontSize: '1.25rem' }}>  Allergy to Amoxicillin</span>
                            </div>
                            <a href="#"><span className="text-muted">Detail</span></a>
                        </Col>
                        <Col sm={12} className="text-muted pl-3">SNOMED CT 447562003</Col>
                    </Row>

                    <Row className="mb-2">
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-0 font-weight-bold d-inline">Condition:</h5>
                                <span className="ml-2" style={{ fontSize: '1.25rem' }}> History of Atrial Fibrillation</span>
                            </div>
                            <a href="#"><span className="text-muted">Detail</span></a>
                        </Col>
                        <Col sm={12} className="text-muted pl-3">SNOMED CT 312442005</Col>
                    </Row>

                    <Row className="mb-2">
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-0 font-weight-bold d-inline">Current Medication:</h5>
                                <span className="ml-2" style={{ fontSize: '1.25rem' }}> Atorvastatin 40 mg oral tablet</span>
                            </div>
                            <a href="#"><span className="text-muted">Detail</span></a>
                        </Col>
                        <Col sm={12} className="text-muted pl-3">SNOMED CT 20448711000001108</Col>
                    </Row>

                    <Row className="mb-2">
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-0 font-weight-bold d-inline">Recent Immunization:</h5>
                                <span className="ml-2" style={{ fontSize: '1.25rem' }}> Influenza virus vaccine, trivalent (IIV3), split virus, 0.5 mL dosage, for intramuscular use, 2024-03-14</span>
                            </div>
                            <a href="#"><span className="text-muted">Detail</span></a>
                        </Col>
                        <Col sm={12} className="text-muted pl-3">SNOMED CT 86198006</Col>
                    </Row>

                    <Row className="mb-2">
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-0 font-weight-bold d-inline">Recent Immunization:</h5>
                                <span className="ml-2" style={{ fontSize: '1.25rem' }}> SARS-CoV-2 vaccination, 2024-03-14</span>
                            </div>
                            <a href="#"><span className="text-muted">Detail</span></a>
                        </Col>
                        <Col sm={12} className="text-muted pl-3">SNOMED CT 86155577700</Col>
                    </Row>

                    <Row className="mb-2">
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-0 font-weight-bold d-inline">Recent Observation Result:</h5>
                                <span className="ml-2" style={{ fontSize: '1.25rem' }}> None</span>
                            </div>
                            <a href="#"><span className="text-muted">Detail</span></a>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-0 font-weight-bold d-inline">Recent Procedure:</h5>
                                <span className="ml-2" style={{ fontSize: '1.25rem' }}> None</span>
                            </div>
                            <a href="#"><span className="text-muted">Detail</span></a>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col sm={12} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-0 font-weight-bold d-inline">Device:</h5>
                                <span className="ml-2" style={{ fontSize: '1.25rem' }}> None</span>
                            </div>
                            <a href="#"><span className="text-muted">Detail</span></a>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col sm={12} className='footer'>
                        <hr />
                        <Col sm={12}>
                            <p className="mb-1">
                                <span style={{ fontWeight: 600 }}>Subject:</span> <span style={{ display: 'inline-block', fontWeight: 'normal' }}><HumanName data={patientData?.name} /></span> &nbsp;&nbsp;
                                <span style={{ fontWeight: 600 }}>Author:</span> Joan Nelson, MD &nbsp;&nbsp;
                                <span style={{ fontWeight: 600 }}>Attestor:</span> NWSpruceHospital &nbsp;&nbsp;
                                <span style={{ fontWeight: 600 }}>Custodian:</span> Spruce Health
                            </p>

                            <p className="mb-0">
                                <strong>Most Recent: 2025-02-25</strong> &nbsp;&nbsp;
                                <a href="#" className="text-primary"><span className="text-muted">View IPS History</span></a>
                            </p>
                        </Col>
                    </Col>
                </Row>
            </dl>
        </section>
    )
}

export default IPS

