import React, { FC } from 'react'
import { CodeableConcept as FhirCodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Coding } from '../../../../types/FHIRTypes/Coding'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'
import Extension from '../Extension'

interface CodeableConceptProps {
  data?: FhirCodeableConcept | FhirCodeableConcept[]
  dataExtension?: FhirCodeableConcept | FhirCodeableConcept[] | null
}

const CodeableConcept: FC<CodeableConceptProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) return null

  if (!data && dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

  const dataArray = Array.isArray(data) ? data : data ? [data] : []

  const renderContent = (item: FhirCodeableConcept) => {
    if (!item.text && !item.coding) {
      return <Extension nestedExtension={item} />
    }

    // This is a preferred vs fallback approach to displaying the data. It matches what we currently have implemented.

    if (item.text) {
      return <span>{item.text}</span>
    }
    if (item.coding) {
      const codingValues = item.coding.map((coding: Coding) => coding.display || coding.code).filter(Boolean).join(', ')
      return <span>{codingValues}</span>
    }

    // This displays ALL the available data per spreadsheet reqs. Removing for now until confirmed that this is the desired behavior.
    // return (
    //   <div>
    //     {item.text && <div>{item.text}</div>}
    //     {item.coding && item.coding.map((coding: Coding, index) => (
    //       <div key={index}>
    //         {coding.display && <div>{coding.display}</div>}
    //         {coding.code && <div>{coding.display ? ` (${coding.code})` : coding.code}</div>}
    //         {coding.system && <div>{coding.display || coding.code ? ` - ${coding.system}` : coding.system}</div>}
    //       </div>
    //     ))}
    //   </div>
    // )
  }

  return (
    <div>
      {dataArray.map((item, index) => (
        <div key={index}>
          {renderContent(item)}
        </div>
      ))}
    </div>
  )
}

export default CodeableConcept