import React, { FC } from 'react'
import EobList from './EobList'
import EobDetail from './EobDetail'
import { ExplanationOfBenefitBundle } from '../../../../types/FHIRTypes/Bundle'

interface Props {
  patientData: any;
  eobData: ExplanationOfBenefitBundle;
  eobDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (page: number | string) => void
  totalPages: number;
  currentPage: number;
}

const Eobs: FC<Props> = ({ patientData, eobData, eobDetailId, page, isFetching, handleChangePage, totalPages, currentPage }) => {
  return (
    <div>
      <section>
        {/* <h3>{eobDetailId ? 'EOB Detail' : 'EOBs'}: {patientData ? buildPatientName(patientData.name) : ''} {buildPatientId(patientData)}</h3> */}
        {
          eobDetailId && page === 'ExplanationOfBenefit'
            ? (
              <EobDetail
                eobDetailId={eobDetailId}
                eobData={eobData}
                isFetching={isFetching}
                patientData={patientData}
              />
            )
            : (
              <EobList
                eobData={eobData}
                handleChangePage={handleChangePage}
                isFetching={isFetching}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            )
        }
      </section>
    </div>
  )
}
export default Eobs