import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Date as FHIRDate } from '../../../../types/FHIRTypes/PrimitiveFhirTypes'
import DataAbsentReason from '../../DisplayComponents/DataAbsentReason'

interface DateProps {
  data?: FHIRDate
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}


const Date: FC<DateProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (!data && dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

 const getDateDisplay = (): string => data ?? ''
  return (
    <div>{getDateDisplay()}</div>
  )
}

export default Date