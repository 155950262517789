import React, { FC } from 'react'
import { Row, Col } from 'react-bootstrap'
import Narrative from '../../../DataTypes/Narrative'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceType from '../../../DisplayComponents/ResourceType'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import ContactPoint from '../../../DataTypes/ContactPoint'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'
import Address from '../../../DataTypes/Address'
import Position from '../../../DisplayComponents/Position'
import Reference from '../../../DataTypes/Reference'
import { Location } from '../../../../../types/FHIRTypes/Location'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'
import LocationReference from '../../../DisplayComponents/LocationReference'


interface Props {
    resource: Location | null
}

const config: FieldConfig = {
    status: { label: 'Status', visibility: 'conditional' },
    operationalStatus: { label: 'Operational Status', visibility: 'conditional' },
    name: { label: 'Name', visibility: 'always' },
    alias: { label: 'Alias', visibility: 'conditional' },
    description: { label: 'Description', visibility: 'conditional' },
    mode: { label: 'Mode', visibility: 'conditional' },
    type: { label: 'Location Type', visibility: 'conditional' },
    telecom: { label: 'Contact Information', visibility: 'always' },
    address: { label: 'Address', visibility: 'always' },
    physicalType: { label: 'Physical Type', visibility: 'conditional' },
    position: { label: 'Latitude/Longitude', visibility: 'conditional' },
    managingOrganization: { label: 'Managing Organization', visibility: 'always' },
    partOf: { label: 'Part of', visibility: 'conditional' },
    hoursOfOperation: { label: 'Hours of Operation', visibility: 'conditional' },
    endpoint: { label: 'Endpoint', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    //metadata
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
}

const LocationDetail: FC<Props> = ({ resource }: Props) => {
    // resource not being set every time

    return (
        <section>
            <dl className='dataContainer'>
                {resource ? (
                    <ResourceConfigProvider config={config} resource={resource}>
                        <Row>
                            <ShowField field='status'>
                                <Col sm={3}>
                                    <dt>{config.status.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <Code data={resource?.status} />
                                </Col>
                            </ShowField>
                            <ShowField field='operationalStatus'>
                                <Col sm={3}>
                                    <dt>{config.operationalStatus.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dt>{resource?.operationalStatus}</dt>
                                </Col>
                            </ShowField>


                            <ShowField field='name'>
                                <Col sm={3}>
                                    <dt>{config.name.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <StringDisplay data={resource?.name} />
                                </Col>
                            </ShowField>

                            <ShowField field='alias'>
                                <Col sm={3}>
                                    <dt>{config.alias.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <StringDisplay data={resource?.alias} />
                                </Col>
                            </ShowField>

                            <ShowField field='description'>
                                <Col sm={3}>
                                    <dt>{config.description.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <StringDisplay data={resource?.description} />
                                </Col>
                            </ShowField>

                            <ShowField field='mode'>
                                <Col sm={3}>
                                    <dt>{config.mode.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <Code data={resource?.mode} />
                                </Col>
                            </ShowField>

                            <ShowField field='type'>
                                <Col sm={3}>
                                    <dt>{config.type.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <CodeableConcept data={resource?.type} />
                                </Col>
                            </ShowField>

                            <ShowField field='telecom'>
                                <Col sm={3}>
                                    <dt>{config.telecom.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {resource?.telecom?.map((contact: any, index: number) => (
                                        <dt key={index}><ContactPoint data={contact} /></dt>
                                    ))}
                                </Col>
                            </ShowField>

                            <ShowField field='address'>
                                <Col sm={3}>
                                    <dt>{config.address.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <Address data={resource.address} showUse={false} />
                                </Col>
                            </ShowField>

                            <ShowField field='physicalType'>
                                <Col sm={3}>
                                    <dt>{config.physicalType.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <CodeableConcept data={resource.physicalType} />
                                </Col>
                            </ShowField>

                            <ShowField field='position'>
                                <Col sm={3}>
                                    <dt>{config.position.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <Position data={resource?.position} />
                                </Col>
                            </ShowField>

                            <ShowField field='managingOrganization'>
                                <Col sm={3}>
                                    <dt>{config.managingOrganization.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><OrganizationReference organization={resource.managingOrganization} apiType='Patient Access' /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='partOf'>
                                <Col sm={3}>
                                    <dt>{config.partOf.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><LocationReference location={resource.partOf} apiType='Patient Access' /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='hoursOfOperation'>
                                <Col sm={3}>
                                    <dt>{config.hoursOfOperation.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dt>HoursOfOperation</dt>
                                </Col>
                            </ShowField>

                            <ShowField field='endpoint'>
                                <Col sm={3}>
                                    <dt>{config.endpoint.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <Reference data={resource.endpoint} />
                                </Col>
                            </ShowField>

                            <ShowField field='text'>
                                <Col sm={3}>
                                    <dt>{config.text.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <Narrative data={resource.text} />
                                </Col>
                            </ShowField>
                        </Row>

                        <div className='footer'>
                            <hr />
                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType'>
                                    <Col sm={3}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{<ResourceType resourceType={resource?.resourceType} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id'>
                                    <Col sm={3}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                                    </Col>
                                </ShowField>
                                {/* <ShowField field='meta' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><MetaLastUpdated meta={resource.meta} /></dd>
                                    </Col>
                                </ShowField> */}
                                <ShowField field='profile'>
                                    <Col sm={3}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{<Profile profile={resource.meta?.profile} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language'>
                                    <Col sm={3}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                    </Col >
                                </ShowField >
                                <ShowField field='implicitRules'>
                                    <Col sm={3}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='id'>
                                    <Col sm={3}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><StringDisplay data={resource?.id} dataExtension={resource?._id} /></dd>
                                    </Col>
                                </ShowField>
                            </Row >
                        </div >

                    </ResourceConfigProvider>
                ) : (
                    <div className='text-center'>
                        No Locations Found
                    </div>
                )
                }
            </dl>
        </section>

    )
}


export default LocationDetail


