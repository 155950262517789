import React, { FC } from 'react'
import { MedicationRequest } from '../../../../types/FHIRTypes/MedicationRequest'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Reference from '../../DataTypes/Reference'
interface MedicationProps {
    medication: MedicationRequest | undefined;
}

const Medication: FC<MedicationProps> = ({ medication }) => {
    if (!medication) return null

    if (medication.medicationCodeableConcept || medication._medicationCodeableConcept) {
        return <CodeableConcept data={medication.medicationCodeableConcept} dataExtension={medication._medicationCodeableConcept} />
    }

     if (medication.medicationReference || medication._medicationReference) {
        return <Reference data={medication.medicationReference} dataExtension={medication._medicationReference} />
    }

    return null
}

export default Medication