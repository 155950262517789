import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/ShowField'
import ResourceConfigProvider from '../../../FhirResourceConfigProvider'
import { InsurancePlan } from '../../../../../types/FHIRTypes/InsurancePlan'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Identifier from '../../../DataTypes/Identifier'
import Code from '../../../DataTypes/Code'
import Narrative from '../../../DataTypes/Narrative'
import Reference from '../../../DataTypes/Reference'
import ResourceType from '../../../DisplayComponents/ResourceType'
import StringDisplay from '../../../DataTypes/String'
import MetaLastUpdated from '../../../DisplayComponents/MetaLastUpdated'
import Profile from '../../../DisplayComponents/Profile'
import URI from '../../../DataTypes/URI'
import Period from '../../../DataTypes/Period'
import Simple from '../../../DisplayComponents/Simple'
import PlanCostShare from '../../../DisplayComponents/InsPlanCostShare'
import InsPlanContact from '../../../DisplayComponents/InsPlanContact'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'

interface InsurancePlanDetailProps {
  resource: InsurancePlan | null | undefined
}

const config: FieldConfig = {
  name: { label: 'Name', visibility: 'conditional' },
  type: { label: 'Type', visibility: 'always' },
  period: { label: 'Period', visibility: 'conditional' },
  status: { label: 'Status', visibility: 'always' },
  alias: { label: 'Alias', visibility: 'conditional' },
  ownedBy: { label: 'Owned By', visibility: 'conditional' },
  administeredBy: { label: 'Administered By', visibility: 'conditional' },
  coverageArea: { label: 'Coverage Area', visibility: 'conditional' },
  contact: { label: 'Contact', visibility: 'conditional' },
  endpoint: { label: 'Endpoint', visibility: 'conditional' },
  network: { label: 'Network', visibility: 'conditional' },
  plan: { label: 'Cost Sharing', visibility: 'conditional' },
  text: { label: 'Summary', visibility: 'conditional' },
  resourceType: { label: 'Resource Type', visibility: 'always' },
  data: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const InsurancePlanDetail: React.FC<InsurancePlanDetailProps> = ({ resource }) => {
  if (!resource) {
    return (
      <div className='text-center'>
        <dl className='dataContainer'>
          No Insurance Plans Found
        </dl>
      </div>
    )
  }

  return (
    <ResourceConfigProvider config={config} resource={resource}>
      <section>
        <h3>Insurance Plans</h3>
        <dl className='dataContainer'>
          <Row>
            <ShowField field='name'>
              <Col sm={4}>
                <dt>{config.name.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><StringDisplay data={resource.name} dataExtension={resource._name} /></dd>
              </Col>
            </ShowField>
            <ShowField field='type'>
              <Col sm={4}>
                <dt>{config.type.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><CodeableConcept data={resource.type} dataExtension={resource._type} /></dd>
              </Col>
            </ShowField>
            <ShowField field='status' >
              <Col sm={4}>
                <dt>{config.name.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
              </Col>
            </ShowField>
            <ShowField field='alias' >
              <Col sm={4}>
                <dt>{config.alias.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><StringDisplay data={resource.alias} dataExtension={resource._alias} /></dd>
              </Col>
            </ShowField>
            <ShowField field='period' >
              <Col sm={4}>
                <dt>{config.period.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><Period data={resource.period} dataExtension={resource._period} /></dd>
              </Col>
            </ShowField>
            <ShowField field='ownedBy' >
              <Col sm={4}>
                <dt>{config.ownedBy.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><OrganizationReference organization={resource.ownedBy} apiType='Provider Directory' /></dd>
              </Col>
            </ShowField>
            <ShowField field='administeredBy' >
              <Col sm={4}>
                <dt>{config.administeredBy.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><OrganizationReference organization={resource.administeredBy} apiType='Provider Directory' /></dd>
              </Col>
            </ShowField>
            <ShowField field='coverageArea' >
              <Col sm={4}>
                <dt>{config.coverageArea.label}</dt>
              </Col>
              <Col sm={8}>
                <dd className="d-flex flex-wrap comma-separated"><Reference data={resource.coverageArea} dataExtension={resource._coverageArea} /></dd>
              </Col>
            </ShowField>
            <ShowField field='contact' >
              <Col sm={4}>
                <dt>{config.contact.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><PlanCostShare data={resource.plan} dataExtension={resource._plan} /></dd>
              </Col>
            </ShowField>
            <ShowField field='endpoint' >
              <Col sm={4}>
                <dt>{config.endpoint.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><Reference data={resource.endpoint} dataExtension={resource._endpoint} /></dd>
              </Col>
            </ShowField>
            <ShowField field='network' >
              <Col sm={4}>
                <dt>{config.network.label}</dt>
              </Col>
              <Col sm={8}>
                <dd>

                  {resource.network?.map((networkRef, index) => (
                    <OrganizationReference key={index} organization={networkRef} apiType='Provider Directory' />
                  ))}

                </dd>
              </Col>
            </ShowField>
            <ShowField field='plan' >
              <Col sm={4}>
                <dt>{config.plan.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><InsPlanContact data={resource.plan} dataExtension={resource._plan} /></dd>
              </Col>
            </ShowField>
            <ShowField field='text' >
              <Col sm={4}>
                <dt>{config.text.label}</dt>
              </Col>
              <Col sm={8}>
                <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
              </Col>
            </ShowField>
            <Col sm={12} className='footer'>
              <hr />

              <h6>FHIR Resource Metadata</h6>
              <Row>
                <ShowField field='resourceType'>
                  <Col sm={4}>
                    <dt>{config.resourceType.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><ResourceType resourceType={resource.resourceType} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='id' >
                  <Col sm={4}>
                    <dt>{config.id.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Simple simple={resource.id} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='meta'>
                  <Col sm={4}>
                    <dt>{config.meta.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><MetaLastUpdated data={resource.meta} dataExtension={resource._meta} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='profile' >
                  <Col sm={4}>
                    <dt>{config.profile.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Profile profile={resource.meta?.profile} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='language' >
                  <Col sm={4}>
                    <dt>{config.language.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='implicitRules' >
                  <Col sm={4}>
                    <dt>{config.implicitRules.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='identifier' >
                  <Col sm={4}>
                    <dt>{config.identifier.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Identifier data={resource.identifier} dataExtension={resource._identifier} /></dd>
                  </Col>
                </ShowField>
              </Row >
            </Col >
          </Row >
        </dl >
      </section >
    </ResourceConfigProvider >
  )
}

export default InsurancePlanDetail